import { toast } from "react-toastify";
import { Logintoken, frontUrl } from "../../common";
import { cartAddItem } from "../../store/cart/cartActions";

import {
    LOGIN,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_FAILED,
    PROFILE,
    PROFILE_FAILED,
    PROFILE_SUCCESS,
    RESET,
    RESET_SUCCESS,
    RESET_FAILED,
    STATELIST,
    STATELIST_SUCCESS,
    STATELIST_FAILED,
    OTP_LOGIN,
    OTP_LOGIN_SUCCESS,
    OTP_LOGIN_FAILED,
    OTP_LOGIN_VERIFY_SUCCESS,
} from "./authActionTypes";
import { authPoint } from "./endpoint";

/****** Login customer Success Action   *****/
export function loginSuccess(login) {
    toast.success("You are Logged in successfully!");
    console.log("login data coming here", login);
    var prevCurrentPageUrl = localStorage.getItem("current_page");
    if (prevCurrentPageUrl) {
        localStorage.setItem("auth", JSON.stringify(login));
        setTimeout(() => {
            window.location.href = prevCurrentPageUrl;
        }, 500);
        localStorage.removeItem("current_page");
        localStorage.removeItem("configuration");
    } else {
        localStorage.setItem("auth", JSON.stringify(login));
        setTimeout(function () {
            window.location.reload();
        }, 1000);
    }
    return {
        type: LOGIN_SUCCESS,
        items: login.list,
    };
}

/****** Login customer Failed Action  *********/
export function loginFailed(error) {
    toast.error(error);
    return {
        type: LOGIN_FAILED,
        items: [],
        error: error,
    };
}

/********** Login customer Action ************/
export function login(data) {
    // sending request to server, timeout is used as a stub

    console.log("logindata=======", data);

    return (dispatch) => {
        dispatch({ type: LOGIN, items: [], loading: true });
        fetch(authPoint.login, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                if (data.error != undefined) {
                    dispatch(loginFailed(data.error));
                } else {
                    dispatch(loginSuccess(data));
                }
            })
            .catch(function (thrown) {
                console.log("thrown error", thrown);
                dispatch(loginFailed("Login Failed , please Try again with Valid data"));
            });
    };
}

/****** register customer Success Action   *****/
export function registerSuccess(register) {
    console.log("registerSuccess", register.message);
    // toast.success(" Verificaton Otp sent to you on  your submitted mobile number ! ,");

    {
        register.message.includes("already registered")
            ? toast.error(register.message)
            : toast.success(register.message);
    }

    // toast.success(register.message);
    return {
        type: REGISTER_SUCCESS,
        items: register.list,
    };
}

/****** register customer Failed Action  *********/
export function registerFailed(error) {
    console.log("registerFailed", error);
    return {
        type: REGISTER_FAILED,
        items: [],
        error: error,
    };
}

/********** register customer Action ************/
export function register(data) {
    // sending request to server, timeout is used as a stub
    console.log("regdata=======", data);

    return (dispatch) => {
        dispatch({ type: REGISTER, items: [], loading: true });
        fetch(authPoint.registerOtpLogin, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then(function (response) {
                return response.json();
            })
            .then((response) => {
                dispatch(registerSuccess(response));
                dispatch(OtploginSuccess(response));
                localStorage.setItem("signupdata", JSON.stringify(data));
            })
            .catch(function (thrown) {
                dispatch(registerFailed(thrown));
                toast.error("Some error occured!");
            });
    };
}

/****** register customer Success Action   *****/
export function profileEditSuccess(register) {
    toast.success("Profile Updated Successfully! ,");
    setTimeout(function () {
        window.location.reload();
    }, 1000);
    return {
        type: PROFILE_SUCCESS,
        items: register.list,
    };
}

/****** register customer Failed Action  *********/
export function profileEditFailed(error) {
    console.log("coming in profile edit fail");
    return {
        type: PROFILE_FAILED,
        items: [],
        error: error,
        loading: false,
    };
}

/********** Profile Edit customer Action ************/
export function profileEdit(data) {
    // sending request to server, timeout is used as a stub

    return (dispatch) => {
        dispatch({ type: PROFILE, items: [], loading: true });
        fetch(authPoint.updateprofile + "?token=" + Logintoken(), {
            method: "PUT", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then(function (response) {
                return response.json();
            })
            .then((response) => {
                let authdata = {
                    token: Logintoken(),
                    data: response.data,
                };
                localStorage.setItem("auth", JSON.stringify(authdata));
                //  setTimeout(function(){ window.location.href = frontUrl+'/account/dashboard'; }, 1000)
                dispatch(profileEditSuccess(response));
            })
            .catch(function (thrown) {
                dispatch(profileEditFailed(thrown));
            });
    };
}

/********** reset customer Action ************/
export function accountResetPassAction(data) {
    // sending request to server, timeout is used as a stub

    return (dispatch) => {
        dispatch({ type: RESET, items: [], loading: true });
        fetch(authPoint.reset, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                dispatch(resetSuccess(data));
            })
            .catch(function (thrown) {
                dispatch(resetFailed(thrown));
            });
    };
}

/****** profile customer Success Action   *****/
export function profileSuccess(profile) {
    return {
        type: PROFILE_SUCCESS,
        items: profile.list,
    };
}

/****** profile customer Failed Action  *********/
export function profileFailed(error) {
    return {
        type: PROFILE_FAILED,
        items: [],
        error: error,
    };
}

/********** profile customer Action ************/
export function profile(data) {
    // sending request to server, timeout is used as a stub

    return (dispatch) => {
        dispatch({ type: PROFILE, items: [], loading: true });
        fetch(authPoint.profile, data)
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                dispatch(profileSuccess(data));
            })
            .catch(function (thrown) {
                dispatch(profileFailed(thrown));
            });
    };
}

/****** reset customer Success Action   *****/
export function resetSuccess(reset) {
    return {
        type: RESET_SUCCESS,
        items: reset.list,
    };
}

/****** reset customer Failed Action  *********/
export function resetFailed(error) {
    return {
        type: RESET_FAILED,
        items: [],
        error: error,
    };
}

/********** reset customer Action ************/
export function resetAction(data) {
    // sending request to server, timeout is used as a stub

    return (dispatch) => {
        dispatch({ type: RESET, items: [], loading: true });
        fetch(authPoint.forgot_password, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                dispatch(resetSuccess(data));
            })
            .catch(function (thrown) {
                dispatch(resetFailed(thrown));
            });
    };
}

/****** reset states Success Action   *****/
export function statesSuccess(data) {
    return {
        type: STATELIST_SUCCESS,
        items: data.data,
    };
}

/****** reset states Failed Action  *********/
export function statesFailed(error) {
    return {
        type: STATELIST_FAILED,
        items: [],
        error: error,
    };
}

/********** reset customer Action ************/
export function stateslistAction() {
    // sending request to server, timeout is used as a stub

    return (dispatch) => {
        dispatch({ type: STATELIST, items: [], loading: true });
        fetch(authPoint.statelist)
            .then(function (response) {
                return response.json();
            })
            .then((response) => {
                dispatch(statesSuccess(response));
            })
            .catch(function (thrown) {
                dispatch(statesFailed(thrown));
            });
    };
}

/****** reset states Success Action   *****/
export function OtploginSuccess(data) {
    return {
        type: OTP_LOGIN_SUCCESS,
        items: data,
    };
}

/****** reset states Failed Action  *********/
export function OtploginFailed(error) {
    return {
        type: OTP_LOGIN_FAILED,
        items: [],
        error: error,
    };
}

/****** reset states Success Action   *****/
export function OtpVerifySuccess(data) {
    toast.success("You are Logged in successfully!");
    console.log("login data coming here", data.token);

    var prevCurrentPageUrl = localStorage.getItem("current_page");

    if (prevCurrentPageUrl) {
        localStorage.setItem("auth", JSON.stringify(data));
        setTimeout(() => {
            window.location.href = prevCurrentPageUrl;
        }, 500);
        localStorage.removeItem("current_page");
        localStorage.removeItem("configuration");
    } else {
        localStorage.setItem("auth", JSON.stringify(data));
        setTimeout(function () {
            window.location.reload();
        }, 1000);
    }
    return {
        type: OTP_LOGIN_SUCCESS,
        loading: false,
        items: data.token,
    };
}

/****** reset states Failed Action  *********/
export function OtpVerifyFailed(error) {
    return {
        type: OTP_LOGIN_FAILED,
        items: [],
        error: error,
    };
}
/******* Expport Verify Send  */
export function OtpVerifySend(data) {
    return (dispatch) => {
        dispatch({ type: LOGIN, items: [], loading: true });

        const url = localStorage.getItem("signupdata") != undefined ? authPoint.verifyRegOtp : authPoint.VerifyOtp;
        data =
            localStorage.getItem("signupdata") != undefined
                ? { ...data, ...JSON.parse(localStorage.getItem("signupdata")) }
                : data;
        fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                // alert("coming in otp verify send success");
                if (!data.status) {
                    toast.error(data.message);
                    const div = document.querySelector("div"); // Get element from DOM
                    div.classList.remove("info");
                    //dispatch({ type: OTP_LOGIN, items: [], loading: false });
                    //alert("data coming here failed")
                    dispatch(OtpVerifyFailed(data.error));
                    dispatch({ type: LOGIN_FAILED, items: [], loading: false });
                    // localStorage('authmobile',data.phone);
                } else {
                    localStorage.removeItem("authmobile");
                    dispatch({
                        type: OTP_LOGIN_SUCCESS,
                        loading: false,
                        items: [],
                    });
                    //  toast.error("Otp Verified Successfully!");
                    dispatch(OtpVerifySuccess(data));
                }
            })
            .catch(function (thrown) {
                //console.log("thrown error",OtpVerifyFailed)

                toast.error("OTP is Expired or Invalid , please try again!");
                //localStorage.removeItem("authmobile");

                // OtpVerifyFailed(thrown);
                //  dispatch({ type: OTP_LOGIN_FAILED, items: [], loading: false });
            });
    };
}

/********** Login customer Action ************/
export function OtploginSend(data) {
    // sending request to server, timeout is used as a stub

    return (dispatch) => {
        dispatch({ type: OTP_LOGIN, items: [], loading: true });
        fetch(authPoint.OtpLogin, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.

            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },

            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                // alert("coming in otp login verify success");
                if (!data.status) {
                    dispatch(OtploginFailed(data.status));
                    dispatch(OtpVerifyFailed(data.error));
                    toast.error(data.message);
                } else {
                    localStorage.setItem("authmobile", data.phone);
                    toast.success(data.message);
                    dispatch(OtploginSuccess(data));
                }
            })
            .catch(function (thrown) {
                console.log("thrown error", thrown);
                toast.error(thrown.message);
                dispatch({ type: LOGIN_FAILED, items: [], loading: false });
                dispatch(OtploginFailed(thrown));
                dispatch(OtpVerifyFailed(thrown.message));
            });
    };
}
