import { toast } from "react-toastify";
import {
    PRODUCT_LIST_SUCCESS,
    BRANDS_LIST_SUCCESS,
    PRODUCT_LIST_FAILED,
    NEW_PRODUCT_LIST_SUCCESS,
    NEW_PRODUCT_LIST_FAILED,
    PRODUCT_DETAIL_FAILED,
    PRODUCT_DETAIL_SUCCESS,
    PRODUCT_DETAIL,
    PRODUCT_ADDITIONALS,
    PRODUCT_ADDITIONALS_SUCCESS,
    PRODUCT_ADDITIONALS_FAILED,
    PRODUCT_LIST,
    PRODUCT_VARIATION_DETAIL_FAILED,
    PRODUCT_VARIATION_DETAIL_SUCCESS,
    PRODUCT_VARIATION_DETAIL,
    PRODUCT_FILTERS_SUCCESS,
    PRODUCT_FILTERS,
    PRODUCT_RECENT_VIEWED_ADD,
    PRODUCT_RECENT_VIEWED_LIST,
    PRODUCT_SEARCH_ADD,
    PRODUCT_SEARCH,
    PRODUCT_SEARCH_SUCCESS,
    PRODUCT_SEARCH_FAILED,
    RELATED_PRODUCTS,
    RELATED_PRODUCTS_FAILED,
    RELATED_PRODUCTS_SUCCESS,
    PRODUCTS_BANNERS,
    PRODUCTS_BANNERS_SUCCESS,
    PRODUCTS_BANNERS_FAILED,
    PRODUCTS_SIZES_SUCCESS,
} from "./productActionTypes";
import { brandPoint, productPoint ,sizePoint} from "./endpoint";
import { axios } from "axios";

export function productSuccess(products) {
    return {
        type: PRODUCT_LIST_SUCCESS,
        loading: false,
        items: products.data,
        itemmeta: products.meta,
    };
}

export function productFailed(error) {
    return {
        type: PRODUCT_LIST_FAILED,
        items: [],
        loading: false,
        error: error,
    };
}

export function getProduct(cat, slug = "") {
    // sending request to server, timeout is used as a stub

    return (dispatch) => {
        dispatch({ type: PRODUCT_LIST, loading: true });
        console.log("coming here to dispatch");
        // dispatch({ type: PRODUCT_LIST, loading: true });
        const url =
            productPoint.list +
            (slug != "" ? "?cat_slug=" + slug.replace("?", "&") : "?category_id=" + cat.replace("?", "&")).replace(
                "filter_",
                ""
            );
        fetch(url)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(productSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(productFailed(thrown));
            });
    };
}

/*********
 *
 * Product Detail Actions
 *
 */
export function productDetailSuccess(productDetail) {
    return {
        type: PRODUCT_DETAIL_SUCCESS,
        items: productDetail.data,
    };
}

export function productDetailFailed(error) {
    return {
        type: PRODUCT_DETAIL_FAILED,
        items: [],
        error: error,
    };
}

export function getProductDetail(sku) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => {
        dispatch({ type: PRODUCT_DETAIL, loading: true });
        fetch(productPoint.list + "?category_id=1&url_key=" + sku)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(productDetailSuccess(response));
                console.log("product data coming here ", response.data[0]);
                addRecentViewedSuccess(response.data[0]);
                dispatch(getproductDetailVariation(response.data[0].id));

                // getbannerData(response.data[0].id);

                //return response;
            })
            .catch(function (thrown) {
                dispatch(productDetailFailed(thrown));
            });
    };
}

/*********
 *
 * Product Variatons Actions
 *
 */
export function productDetailVariationSuccess(productDetail) {
    return {
        type: PRODUCT_VARIATION_DETAIL_SUCCESS,
        items: productDetail.data,
    };
}

export function productDetailVariationFailed(error) {
    return {
        type: PRODUCT_VARIATION_DETAIL_FAILED,
        items: [],
    };
}

export function getproductDetailVariation(id) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => {
        dispatch({ type: PRODUCT_VARIATION_DETAIL, items: [], loading: true });
        fetch(productPoint.variations + "/" + id)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(productDetailVariationSuccess(response));

                // dispatch(getbannerData(id));

                //return response;
            })
            .catch(function (thrown) {
                dispatch(productDetailVariationFailed(thrown));
            });
    };
}

/******
 * Get Product Details  Addtionals Actions
 */

export function productDetailAdditionalsSuccess(productDetail) {
    return {
        type: PRODUCT_ADDITIONALS_SUCCESS,
        items: productDetail.data,
    };
}

export function productDetailAdditionalsFailed(error) {
    return {
        type: PRODUCT_ADDITIONALS_FAILED,
        items: [],
        error: error,
    };
}

export function getProductDetailAdditionals(id) {
    return (dispatch) => {
        dispatch({ type: PRODUCT_ADDITIONALS, loading: true });
        fetch(productPoint.additions + "/" + id)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(productDetailAdditionalsSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(productDetailAdditionalsFailed(thrown));
            });
    };
}

/*****
 *
 * new Products List Actions
 *
 */
export function newProductSuccess(products) {
    return {
        type: NEW_PRODUCT_LIST_SUCCESS,
        items: products.data,
    };
}

export function newProductFailed(error) {
    return {
        type: NEW_PRODUCT_LIST_FAILED,
        items: [],
        error: error,
    };
}

export function getBrandsSuccess(brands) {
    return {
        type: BRANDS_LIST_SUCCESS,
        items: brands.data,
    };
}





export function getnewproduct({ limit, category }) {
    // sending request to server, timeout is used as a stub

    return (dispatch) =>
        fetch(productPoint.list + "?category_id=" + category + "&limit=" + limit + "&new=1")
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(newProductSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(newProductFailed(thrown));
            });
}

/****** Get Brands  ********/

export function getBrands() {
    return (dispatch) =>
        fetch(brandPoint)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(getBrandsSuccess(response));
                //return response;
            });
}

export function getSizesSuccess(brands) {
    return {
        type: PRODUCTS_SIZES_SUCCESS,
        items: brands.data,
    };
}


/****** Get Brands  ********/

export function getSizes() {
    return (dispatch) =>
        fetch(sizePoint)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(getSizesSuccess(response));
                //return response;
            });
}
export function getProductFiltersSuccess(brands) {
    return {
        type: PRODUCT_FILTERS_SUCCESS,
        items: brands.data,
        productspecific: brands.productspecific,
        maxprice: brands.maxprice,
        loading:false
    };
}

/****** Get Brands  ********/

export function getProductFilters(cat_slug) {
    console.log("Coming here to check filter deployed", productPoint.filters, cat_slug);
    return (dispatch) => {
        dispatch({ type: PRODUCT_FILTERS, loading: true, items: [] });
        const url =
            productPoint.filters +
            (cat_slug != ""
                ? "?cat_slug=" + cat_slug.replace("?", "&")
                : "?root=" + cat_slug.replace("?", "&")
            ).replace("filter_", "");
        fetch(url)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(getProductFiltersSuccess(response));
                //return response;
            });
    };
}

export function pageChangeAction(event) {
    return (dispatch) => {
        dispatch(getProduct());
        dispatch({
            type: "SET_OPTION_VALUE",
            options: "page",
            value: event,
        });
    };
}

export function addRecentViewedSuccess(data) {
    console.log("Entering in view success function ", data);
    var recent_viewed = localStorage.getItem("recent_viewed");
    var recentviewed = [];

    if (recent_viewed !== null) {
        console.log("Add Recent Viewed data ", recentviewed);
        var recentviewed = JSON.parse(recent_viewed);
        const found = recentviewed.some((el) => el.id === data.id);
        if (!found) {
            recentviewed.push(data);
        }
    } else {
        recentviewed.push(data);
    }
    console.log("Add Recent Viewed data ", recentviewed);
    localStorage.setItem("recent_viewed", JSON.stringify(recentviewed));
    return (dispatch) => dispatch(listRecentViewedSuccess(recentviewed));
}

/******** Add Recent Viewed ******/
export function addRecentViewed(data) {
    return (dispatch) => dispatch(listRecentViewedSuccess(data));
}

export function listRecentViewedSuccess(data) {
    console.log("event is dispatched here", localStorage.getItem("recent_viewed"));
    return {
        type: PRODUCT_RECENT_VIEWED_LIST,

        items: localStorage.getItem("recent_viewed"),
    };
}

/******** Add Recent Viewed ******/
export function listRecentViewed() {
    return (dispatch) => dispatch(addRecentViewedSuccess(localStorage.getItem("recent_viewed")));
}

/******** Search Product data Action  ******/

export function productSearchSuccess(productDetail) {
    return {
        type: PRODUCT_SEARCH_SUCCESS,
        items: productDetail.data,
    };
}

export function productSearchFailed(error) {
    return {
        type: PRODUCT_SEARCH_FAILED,
        items: [],
        error: error,
    };
}

export function getProductSearch(search, cat = "root") {
    return (dispatch) => {
        dispatch({ type: PRODUCT_SEARCH, loading: true });
        fetch(productPoint.list + "?cat_slug=" + cat + "&name=" + search)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(productSearchSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(productSearchFailed(thrown));
            });
    };
}

/***** Related Products *****/

/******** Search Product data Action  ******/

export function RelatedProductSuccess(productDetail) {
    return {
        type: RELATED_PRODUCTS_SUCCESS,
        items: productDetail.data,
    };
}

export function RelatedProductFailed(error) {
    return {
        type: RELATED_PRODUCTS_FAILED,
        items: [],
        error: error,
    };
}

export function getRelatedProduct(slug) {
    return (dispatch) => {
        dispatch({ type: RELATED_PRODUCTS, loading: true });
        fetch(productPoint.relatedpro + "/" + slug)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(RelatedProductSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(RelatedProductFailed(thrown));
            });
    };
}

/******** Search Product data Action  ******/

export function getbannerDataSuccess(productDetail) {
    return {
        type: PRODUCTS_BANNERS_SUCCESS,
        items: productDetail.data,
    };
}

export function getbannerDataFailed(error) {
    return {
        type: PRODUCTS_BANNERS_FAILED,
        items: [],
        error: error,
    };
}

export function getbannerData(productid) {
    console.log("Banner data coming here", productid);
    return (dispatch) => {
        dispatch({ type: PRODUCTS_BANNERS, loading: true, items: "" });
        fetch(productPoint.bannerdata + "/" + productid)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(getbannerDataSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(getbannerDataFailed(thrown));
            });
    };
}
