import { toast } from "react-toastify";
import {
    ORDERS_LIST_SUCCESS,
    ORDERS_LIST_FAILED,
    ORDERS_LIST,
    ORDERS_DETAIL,
    ORDERS_DETAIL_SUCCESS,
    ORDERS_DETAIL_FAILED,
    ORDER_RETURN_REQUEST,
    ORDER_RETURN_REQUEST_SUCCESS,
    ORDER_RETURN_REQUEST_FAILED,
    ORDER_RETURN_LIST,
    ORDER_RETURN_LIST_SUCCESS,
    ORDER_RETURN_LIST_FAILED
} from "./orderActionTypes";
import { ordersPoint } from "./endpoint";
import { axios } from "axios";
import { loginSuccess } from "../auth";
import { frontUrl, Logintoken } from "../../common";

export function ordersSuccess(products) {
    console.log("coming here to check the order list");
    return {
        type: ORDERS_LIST_SUCCESS,
        items: products,
    };
}

export function ordersFailed(error) {
    return {
        type: ORDERS_LIST_FAILED,
        items: [],
        error: error,
    };
}

export function getorders(page = 1) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => {
        fetch(ordersPoint.list + "?token=" + Logintoken() + "&limit=10&page=" + page)
            .then(function (response) {
                return response.json();
            })
            .then((response) => {
                dispatch(ordersSuccess(response));
            })
            .catch(function (thrown) {
                dispatch(ordersFailed(thrown));
            });
    };
}

/***** Order Detail Page  ********/

export function orderdetailSuccess(orderdata) {
    console.log("coming here to check the order list");
    return {
        type: ORDERS_DETAIL_SUCCESS,
        items: orderdata.data,
    };
}

export function orderdetailFailed(error) {
    return {
        type: ORDERS_DETAIL_FAILED,
        items: [],
        error: error,
    };
}

export function getorderdetail(orderid) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => {
        dispatch({ type: ORDERS_DETAIL, items: {}, loading: true });
        fetch(ordersPoint.list + "/" + orderid + "?token=" + Logintoken())
            .then(function (response) {
                return response.json();
            })
            .then((response) => {
                dispatch(orderdetailSuccess(response));
            })
            .catch(function (thrown) {
                dispatch(orderdetailFailed(thrown));
            });
    };
}




/***** Order Detail Page  ********/

export function orderReturnListSuccess(orderdata) {
    console.log("coming here to check the order list");
    return {
        type: ORDER_RETURN_LIST_SUCCESS,
        items: orderdata.data,
    };
}

export function orderReturnListFailed(error) {
    return {
        type: ORDER_RETURN_LIST_FAILED,
        items: [],
        error: error,
    };
}

export function getorderReturnList(orderid) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => {
        dispatch({ type: ORDER_RETURN_LIST, items: {}, loading: true });
        fetch(ordersPoint.rmslist    + "?token=" + Logintoken())
            .then(function (response) {
                return response.json();
            })
            .then((response) => {
                dispatch(orderReturnListSuccess(response));
            })
            .catch(function (thrown) {
                dispatch(orderReturnListFailed(thrown));
            });
    };
}

/***** Order Return Item Request   ********/

export function orderReturnRequestSuccess(orderdata) {
    console.log("coming here to check the order list");

    if (orderdata.status) {
        toast.success(orderdata.message);
        window.location.href = frontUrl + "/account/orders/";
    } else {
        toast.error(orderdata.message);
    }

    return {
        type: ORDER_RETURN_REQUEST_SUCCESS,
        items: orderdata,
    };
}

export function orderReturnRequestFailed(error) {
    return {
        type: ORDER_RETURN_REQUEST_FAILED,
        items: [],
        error: error,
    };
}

export function orderReturnRequest(data) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => {
        dispatch({ type: ORDER_RETURN_REQUEST, items: {}, loading: true });
        fetch(ordersPoint.returnrequest, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then(function (response) {
                return response.json();
            })
            .then((response) => {
                dispatch(orderReturnRequestSuccess(response));
            })
            .catch(function (thrown) {
                dispatch(orderReturnRequestFailed(thrown));
            });
    };
}
