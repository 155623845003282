import { makeIdGenerator } from '../utils';
import brandsData from './brands';
import { categoriesListData, prepareCategory } from './categories';

const getId = makeIdGenerator();

const attributesDef = [
    {
        name: 'Color',
        slug: 'color',
        values: [
            { name: 'White', slug: 'white' },
            { name: 'Silver', slug: 'silver' },
            { name: 'Light Gray', slug: 'light-gray' },
            { name: 'Gray', slug: 'gray' },
            { name: 'Dark Gray', slug: 'dark-gray' },
            { name: 'Coal', slug: 'coal' },
            { name: 'Black', slug: 'black' },
            { name: 'Red', slug: 'red' },
            { name: 'Orange', slug: 'orange' },
            { name: 'Yellow', slug: 'yellow' },
            { name: 'Pear Green', slug: 'pear-green' },
            { name: 'Green', slug: 'green' },
            { name: 'Emerald', slug: 'emerald' },
            { name: 'Shamrock', slug: 'shamrock' },
            { name: 'Shakespeare', slug: 'shakespeare' },
            { name: 'Blue', slug: 'blue' },
            { name: 'Dark Blue', slug: 'dark-blue' },
            { name: 'Violet', slug: 'violet' },
            { name: 'Purple', slug: 'purple' },
            { name: 'Cerise', slug: 'cerise' },
        ],
    },
    {
        name: 'Speed',
        slug: 'speed',
        values: [
            { name: '750 RPM', slug: '750-rpm' },
        ],
    },
    {
        name: 'Power Source',
        slug: 'power-source',
        values: [
            { name: 'Cordless-Electric', slug: 'cordless-electric' },
        ],
    },
    {
        name: 'Battery Cell Type',
        slug: 'battery-cell-type',
        values: [
            { name: 'Lithium', slug: 'lithium' },
        ],
    },
    {
        name: 'Voltage',
        slug: 'voltage',
        values: [
            { name: '20 Volts', slug: '20-volts' },
        ],
    },
    {
        name: 'Battery Capacity',
        slug: 'battery-capacity',
        values: [
            { name: '2 Ah', slug: '2-Ah' },
        ],
    },
];

const productsDef = [
    {
        slug: 'LG-1.5-Ton-3-Star-Inverter-Window-AC-Copper-JW-Q18WUXA1-White-Top-AirDischarge',
        name: 'LG 1.5 Ton 3 Star Inverter Window AC (Copper, JW-Q18WUXA1, White, Top Air Discharge)',
        price: 749,
        images: [
            'images/products/LG-1.5-Ton-3-Star-Inverter-Window-AC-Copper-JW-Q18WUXA1-White-Top-AirDischarge.png',
            'images/products/LG-1.5-Ton-3-Star-Inverter-Window-AC-Copper-JW-Q18WUXA1-White-Top-AirDischarge2.png',
        ],
        badges: 'new',
        rating: 4,
        reviews: 12,
        availability: 'in-stock',
        brand: 'brandix',
        categories: ['screwdrivers'],
        attributes: [
            { slug: 'color', values: 'yellow' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'Samsung-2m-16cm-85-Q950T-8K-Smart-QLED-TV',
        name: 'Samsung 2m 16cm (85") Q950T 8K Smart QLED TV',
        price: 1019,
        images: [
            'images/products/Samsung-2m-16cm-85-Q950T-8K-Smart-QLED-TV.png',
            'images/products/Samsung-2m-16cm-85-Q950T-8K-Smart-QLED-TV.png',
        ],
        badges: 'hot',
        rating: 5,
        reviews: 3,
        availability: 'in-stock',
        brand: 'zosch',
        categories: ['instruments'],
        attributes: [
            { slug: 'color', values: ['silver', 'cerise'] },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'LG-7-kg-Semi-Automatic-Top-Loading-Washing-Machine',
        name: 'LG 7 kg Semi-Automatic Top Loading Washing Machine',
        price: 850,
        images: [
            'images/products/LG-7-kg-Semi-Automatic-Top-Loading-Washing-Machine.png',
            'images/products/LG-7-kg-Semi-Automatic-Top-Loading-Washing-Machine.png',
        ],
        rating: 4,
        reviews: 8,
        availability: 'in-stock',
        brand: 'brandix',
        categories: ['power-tools'],
        attributes: [
            { slug: 'color', values: 'yellow' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'Daikin-Air-Conditioner',
        name: 'Daikin Air Conditioner',
        price: 949,
        compareAtPrice: 1189,
        images: [
            'images/products/Daikin-Air-Conditioner.png',
            'images/products/Daikin-Air-Conditioner.png',
        ],
        badges: 'sale',
        rating: 3,
        reviews: 15,
        availability: 'in-stock',
        brand: 'brandix',
        categories: [],
        attributes: [
            { slug: 'color', values: 'white' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'IFB-20-litres-Convection-Microwave-Oven-20SC2',
        name: 'IFB 20 litres Convection Microwave Oven, 20SC2',
        price: 1700,
        images: [
            'images/products/IFB-20-litres-Convection-Microwave-Oven-20SC2.png',
            'images/products/IFB-20-litres-Convection-Microwave-Oven-20SC2.png',
        ],
        rating: 4,
        reviews: 2,
        availability: 'in-stock',
        brand: 'wakita',
        categories: [],
        attributes: [
            { slug: 'color', values: 'dark-blue' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'IFB-6-Kg-Front-Loading-Fully-Automatic-Washing-Machine',
        name: 'IFB 6 Kg Front Loading Fully Automatic Washing Machine',
        price: 3199,
        images: [
            'images/products/IFB-6-Kg-Front-Loading-Fully-Automatic-Washing-Machine.png',
            'images/products/IFB-6-Kg-Front-Loading-Fully-Automatic-Washing-Machine.png',
        ],
        rating: 3,
        reviews: 21,
        availability: 'in-stock',
        brand: 'wakita',
        categories: [],
        attributes: [
            { slug: 'color', values: 'orange' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'One-Plus-8-Series-5G',
        name: 'One-Plus 8 Series 5G',
        price: 24,
        images: [
            'images/products/one-plus8.png',
            'images/products/one-plus8.png',
        ],
        rating: 2,
        reviews: 1,
        availability: 'in-stock',
        brand: 'wevalt',
        categories: [],
        attributes: [
            { slug: 'color', values: 'red' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'Samsung-138-cm-55-Inches-Q-Series-4K-UHD-QLED-Smart-TV-QA55Q6FN',
        name: 'Samsung 138 cm (55 Inches) Q Series 4K UHD QLED Smart TV QA55Q6FN (Black) (2018 model)',
        price: 15,
        images: [
            'images/products/Samsung-138-cm-55-Inches-Q-Series-4K-UHD-QLED-Smart-TV-QA55Q6FN.png',
            'images/products/Samsung-138-cm-55-Inches-Q-Series-4K-UHD-QLED-Smart-TV-QA55Q6FN.png',
        ],
        rating: 2,
        reviews: 5,
        availability: 'in-stock',
        brand: 'hammer',
        categories: [],
        attributes: [
            { slug: 'color', values: ['pear-green', 'blue'] },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'Redmi-Note-9-Pro-Max',
        name: 'Redmi Note 9 Pro Max',
        price: 19,
        images: [
            'images/products/Redmi-Note-9-Pro-Max.png',
            'images/products/Redmi-Note-9-Pro-Max.png',
        ],
        rating: 4,
        reviews: 34,
        availability: 'in-stock',
        brand: 'hammer',
        categories: [],
        attributes: [
            { slug: 'color', values: 'green' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'Samsung-192-L-4-Star-Inverter-Direct-Cool-Single-Door-Refrigerator',
        name: 'Samsung 192 L 4 Star Inverter Direct Cool Single Door Refrigerator',
        price: 15,
        images: [
            'images/products/Samsung-192-L-4-Star-Inverter-Direct-Cool-Single-Door-Refrigerator.png',
            'images/products/Samsung-192-L-4-Star-Inverter-Direct-Cool-Single-Door-Refrigerator.png',
        ],
        rating: 5,
        reviews: 3,
        availability: 'in-stock',
        brand: 'hammer',
        categories: [],
        attributes: [
            { slug: 'color', values: 'gray' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'Ambrane-10000mAh-Li-Polymer-Powerbank',
        name: 'Ambrane 10000mAh Li-Polymer Powerbank',
        price: 149,
        images: [
            'images/products/Ambrane-10000mAh-Li-Polymer-Powerbank.png',
            'images/products/Ambrane-10000mAh-Li-Polymer-Powerbank',
        ],
        rating: 4,
        reviews: 7,
        availability: 'in-stock',
        brand: 'hammer',
        categories: [],
        attributes: [
            { slug: 'color', values: 'black' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'LG-6.0-Kg-5-Star-Inverter-Fully-Automatic-Front-Loading-Washing-Machine',
        name: 'LG 6.0 Kg 5 Star Inverter Fully-Automatic Front Loading Washing Machine',
        price: 666.99,
        images: [
            'images/products/LG-6.0-Kg-5-Star-Inverter-Fully-Automatic-Front-Loading-Washing-Machine.png',
            'images/products/LG-6.0-Kg-5-Star-Inverter-Fully-Automatic-Front-Loading-Washing-Machine.png',
        ],
        rating: 5,
        reviews: 17,
        availability: 'in-stock',
        brand: 'mitasia',
        categories: [],
        attributes: [
            { slug: 'color', values: 'violet' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'boAt-BassHeads-100-in-Ear-Wired-Earphones',
        name: 'boAt BassHeads 100 in-Ear Wired Earphones',
        price: 649,
        images: [
            'images/products/boAt-BassHeads-100-in-Ear-Wired-Earphones.png',
            'images/products/boAt-BassHeads-100-in-Ear-Wired-Earphones.png',
        ],
        rating: 2,
        reviews: 8,
        availability: 'in-stock',
        brand: 'mitasia',
        categories: [],
        attributes: [
            { slug: 'color', values: 'purple' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'IFB-30-L-Convection-Microwave-Oven-30FRC2-Floral-Pattern',
        name: 'IFB 30 L Convection Microwave Oven (30FRC2, Floral Pattern)',
        price: 1800,
        images: [
            'images/products/IFB-30-L-Convection-Microwave-Oven-30FRC2-Floral-Pattern.png',
            'images/products/IFB-30-L-Convection-Microwave-Oven-30FRC2-Floral-Pattern.png',
        ],
        rating: 3,
        reviews: 14,
        availability: 'in-stock',
        brand: 'brandix',
        categories: [],
        attributes: [
            { slug: 'color', values: ['light-gray', 'emerald'] },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'Apple-iPhone-11-Pro-Max-(256GB)-Midnight-Green',
        name: 'Apple iPhone 11 Pro Max (256GB) - Midnight Green',
        price: 290,
        images: [
            'images/products/Apple-iPhone-11-Pro-Max-(256GB)-Midnight-Green.png',
            'images/products/Apple-iPhone-11-Pro-Max-(256GB)-Midnight-Green.png',
        ],
        rating: 2,
        reviews: 1,
        availability: 'in-stock',
        brand: 'brandix',
        categories: [],
        attributes: [
            { slug: 'color', values: ['coal', 'shamrock'] },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'Carrier-1-Ton-3-Star-Inverter-Split-AC',
        name: 'Carrier 1 Ton 3 Star Inverter Split AC ',
        price: 1499,
        images: [
            'images/products/Carrier-1-Ton-3-Star-Inverter-Split-AC.png',
        ],
        rating: 5,
        reviews: 3,
        availability: 'in-stock',
        brand: 'metaggo',
        categories: [],
        attributes: [
            { slug: 'color', values: ['dark-gray', 'shakespeare'] },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
];

const productsData = productsDef.map((productDef) => {
    let badges = [];

    if (productDef.badges) {
        badges = typeof productDef.badges === 'string' ? [productDef.badges] : productDef.badges;
    }

    const categories = categoriesListData
        .filter((category) => productDef.categories.includes(category.slug))
        .map((category) => prepareCategory(category));

    const attributes = (productDef.attributes || []).map((productAttributeDef) => {
        const attributeDef = attributesDef.find((x) => x.slug === productAttributeDef.slug);

        if (!attributeDef) {
            return null;
        }

        let valuesDef = [];

        if (typeof productAttributeDef.values === 'string') {
            valuesDef = [productAttributeDef.values];
        } else if (productAttributeDef.values) {
            valuesDef = productAttributeDef.values;
        }

        const values = valuesDef.map((valueSlug) => {
            const valueDef = attributeDef.values.find((x) => x.slug === valueSlug);

            if (!valueDef) {
                return null;
            }

            return {
                ...valueDef,
                customFields: {},
            };
        }).filter((x) => x !== null);

        if (!values.length) {
            return null;
        }

        return {
            name: attributeDef.name,
            slug: attributeDef.slug,
            featured: !!productAttributeDef.featured,
            values,
            customFields: {},
        };
    }).filter((x) => x !== null);

    return {
        id: getId(),
        name: productDef.name,
        sku: '83690/32',
        slug: productDef.slug,
        price: productDef.price,
        compareAtPrice: productDef.compareAtPrice || null,
        images: productDef.images.slice(),
        badges: badges.slice(),
        rating: productDef.rating,
        reviews: productDef.reviews,
        availability: productDef.availability,
        brand: brandsData.find((x) => x.slug === productDef.brand) || null,
        categories,
        attributes,
        customFields: {},
    };
});

export default productsData;
