import { ApiEndpointUrl } from "../../common";
export const cartPoint = {
    list: ApiEndpointUrl + "checkout/cart",
    add: ApiEndpointUrl + "checkout/cart/add/",
    update: ApiEndpointUrl + "checkout/cart/update",
    empty:ApiEndpointUrl + "checkout/cart/empty",
    delete:ApiEndpointUrl + "checkout/cart/remove-item/",
    couponAdd:ApiEndpointUrl + "checkout/cart/coupon",
    
};

