export const domainUrl = "https://ralsonshop.com/backend/public";
export const ApiEndpointUrl = domainUrl + "/index.php/api/";
export const storageUrl = domainUrl + "/storage/";
export const clientLogoUrl = domainUrl + "/cache/large/";
export const frontUrl = "https://ralsonshop.com";
// export const frontUrl = "http://localhost:3000";
export const RazorPayKey = "rzp_test_krkPTPYLqNXYsZ";
export const Logintoken = () => {
    if (localStorage.getItem("auth") != undefined) {
        return JSON.parse(localStorage.getItem("auth")).token;
    }
    return "";
};
export default {};
