// react
import React, { Component, Fragment } from "react";

// third-party
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link, Redirect } from "react-router-dom";

// application
import Collapse from "../shared/Collapse";
import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";
import { Check9x7Svg } from "../../svg";

import { stateslistAction } from "../../store/auth";

import {
    checkoutSaveAddress,
    checkoutSavePayment,
    checkoutSaveShipping,
    getPaymentMethodsList,
    getShippingMethodsList,
} from "../../store/checkout";
import { getAddresslist, SaveAddress } from "../../store/address";
import classNames from "classnames";

// data stubs
import payments from "../../data/shopPayments";
import theme from "../../data/theme";
import { authCheckNull } from "../../common/AuthCheck";
import Moment from "react-moment";
import BlockLoader from "../blocks/BlockLoader";
import { toast } from "react-toastify";
import { frontUrl, Logintoken } from "../../common";
import { ApiEndpointUrl, RazorPayKey } from "../../common";

import { postcodeValidator, postcodeValidatorExistsForCountry } from "postcode-validator";

class ShopPageCheckout extends Component {
    payments = payments;

    constructor(props) {
        super(props);

        this.state = {
            payment: "bank",
            defaultpaymentmethod: "payumoney",
            first_name: "",
            last_name: "",
            company_name: "",
            appartment_name: "",
            city: "",
            state: "",
            zipcode: "",
            email: "",
            phone: "",
            vat_id: "",
            shipping_first_name: "",
            shipping_last_name: "",
            shipping_company_name: "",
            shipping_appartment_name: "",
            shipping_city: "",
            shipping_state: "",
            shipping_zipcode: "",
            shipping_email: "",
            shipping_phone: "",
            use_for_shipping: false,
            minRateShipping: "",
            minRateAmongMinDays: "",
            finalShippingPrice: "",
            checkout_term_error: false,
            address_id: 0,
            address_list: [],
            shipping_rates: [],
            cart_items: [],
            save_address_response: [],
            selectedaddress: "",
            selectedFormdisplay: 0,
            postbillingdata: "",
            postshippingdata: "",
            shippingdata: "",
            shippingmethod: "",
            shippingmethod_name: "",
            zipcode: "",
            validation: {
                first_name_error: false,
                last_name_error: false,
                street_address_error: false,
                city_error: false,
                state_error: false,
                postcode_error: false,
                email_error: false,
                phonenumber_error: false,
                shipping_first_name_error: false,
                shipping_last_name_error: false,
                shipping_street_address_error: false,
                shipping_city_error: false,
                shipping_state_error: false,
                shipping_postcode_error: false,
                shipping_email_error: false,
                shipping_phonenumber_error: false,
            },
        };
        this.handleAddressSubmit = this.handleAddressSubmit.bind(this);
        this.handlechange = this.handlechange.bind(this);
        this.saveOrder = this.saveOrder.bind(this);
        this.addressSelected = this.addressSelected.bind(this);
        this.addnewaddress = this.addnewaddress.bind(this);
        this.backToAddressList = this.backToAddressList.bind(this);
        this.SaveAddressOnLogin = this.SaveAddressOnLogin.bind(this);
        this.state.save_address_response =
            localStorage.getItem("save_address_response") != undefined
                ? JSON.parse(localStorage.getItem("save_address_response"))
                : "";
        this.handlechangeshippingcheck = this.handlechangeshippingcheck.bind(this);
        this.myRef = React.createRef();
    }

    handlePaymentChange = (event) => {
        if (event.target.checked) {
            this.setState({ defaultpaymentmethod: event.target.value });
        }
    };

    handlechange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    validateEmail = (emailField) => {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(emailField) == false) {
            return false;
        }
        return true;
    };

    /****** Zipcode Changes  */
    zipCodeChange = (zipcode) => {
        // console.log(" zip code changes ", event);
        this.props.getShippingMethodsList(zipcode);
        this.setState({ zipcode: zipcode });
    };

    /******* save Shipping data  */
    ApplyShipping = (event) => {
        let getAddressResponse = JSON.parse(localStorage.getItem("save_address_response"));
        const { checkoutSaveShipping } = this.props;
        const { cart, address } = this.props;
        document.getElementById('pri1').style.display = "contents";
        document.getElementById('pri').innerHTML = '₹'+event.target.getAttribute("data2");
        document.getElementById('tot').style.display = "contents";
        document.getElementById('tttpri').innerHTML = '₹'+ (parseFloat(getAddressResponse.data.cart.grand_total) + parseFloat(event.target.getAttribute("data2")));
        // document.getElementById("tttpri").innerHTML =
        //     "₹" + (parseFloat(cart.cartlist.grandtotal) + parseFloat(0)).toFixed(2);
            checkoutSaveShipping({
                shipping_method: "shiprocket",
                shipping_method_name: event.target.getAttribute("data1"),
                shippingdata: event.target.getAttribute("data"),
            });
            this.setState({
                shippingmethod: "shiprocket",
                shippingmethod_name: event.target.getAttribute("data1"),
                shippingdata: JSON.parse(event.target.getAttribute("data")).courier_company_id,
            });
    };

    handleValidation() {
        var failed = false;

        if (this.state.first_name == "") {
            this.setState({ validation: { first_name_error: true } });
            failed = true;
            return false;
        }
        if (this.state.last_name == "") {
            this.setState({ validation: { last_name_error: true } });

            failed = true;
            return false;
        }
        if (this.state.street_address == "") {
            this.setState({ validation: { street_address_error: true } });

            failed = true;
            return false;
        }
        if (this.state.city == "") {
            this.setState({ validation: { city_error: true } });

            failed = true;
            return false;
        }

        if (this.state.postcode == "" || !postcodeValidator(this.state.postcode, "IN")) {
            this.setState({ validation: { postcode_error: true } });

            failed = true;
            return false;
        }

        if (this.state.email == "" || !this.validateEmail(this.state.email)) {
            this.setState({ validation: { email_error: true } });

            failed = true;
            return false;
        }

        if (this.state.use_for_shipping) {
            if (this.state.shipping_first_name == "") {
                this.setState({ validation: { shipping_first_name_error: true } });
                failed = true;
                return false;
            }
            if (this.state.shipping_last_name == "") {
                this.setState({ validation: { shipping_last_name_error: true } });

                failed = true;
                return false;
            }
            if (this.state.shipping_street_address == "") {
                this.setState({ validation: { shipping_street_address_error: true } });

                failed = true;
                return false;
            }
            if (this.state.shipping_city == "") {
                this.setState({ validation: { shipping_city_error: true } });

                failed = true;
                return false;
            }

            if (this.state.shipping_postcode == "") {
                this.setState({ validation: { shipping_postcode_error: true } });

                failed = true;
                return false;
            }

            if (this.state.shipping_email == "" || !this.validateEmail(this.state.shipping_email)) {
                this.setState({ validation: { shipping_email_error: true } });

                failed = true;
                return false;
            }
        }

        if (failed) {
            console.log("data submission coming here", this.state);
            return false;
        }

        this.setState({
            validation: {
                first_name_error: false,
                last_name_error: false,
                street_address_error: false,
                city_error: false,
                state_error: false,
                postcode_error: false,
                email_error: false,
                phonenumber_error: false,
                shipping_first_name_error: false,
                shipping_last_name_error: false,
                shipping_street_address_error: false,
                shipping_city_error: false,
                shipping_state_error: false,
                shipping_postcode_error: false,
                shipping_email_error: false,
                shipping_phonenumber_error: false,
            },
        });
        return true;
    }

    /****** Handle Submit  ******/
    handleAddressSubmit(event) {
        if (this.handleValidation()) {
            let shippingaddress = {
                address1: {
                    0: this.state.street_address,
                },
                use_for_shipping: this.state.use_for_shipping,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                company_name: this.state.company_name,
                email: this.state.email,
                city: this.state.city,
                state: this.state.state,
                postcode: this.state.postcode,
                country: "IN",
                phone: this.state.phone,
                vat_id: this.state.vat_id,
            };
            if (this.state.use_for_shipping) {
                shippingaddress = {
                    address1: {
                        0: this.state.shipping_street_address,
                    },
                    first_name: this.state.shipping_first_name,
                    last_name: this.state.shipping_last_name,
                    email: this.state.shipping_email,
                    company_name: this.state.shipping_company_name,
                    city: this.state.shipping_city,
                    state: this.state.shipping_state,
                    postcode: this.state.shipping_postcode,
                    country: "IN",
                    phone: this.state.shipping_phone,
                    vat_id: this.state.vat_id,
                };
            }

            var postdata = {
                billing: {
                    address1: {
                        0: this.state.street_address,
                    },
                    use_for_shipping: this.state.use_for_shipping,
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                    company_name: this.state.company_name,
                    email: this.state.email,
                    city: this.state.city,
                    state: this.state.state,
                    postcode: this.state.postcode,
                    country: "IN",
                    phone: this.state.phone,
                    vat_id: this.state.vat_id,
                },
                shipping: shippingaddress,
            };
            if (!authCheckNull()) {
                this.props.SaveAddress({
                    address1: {
                        0: this.state.street_address,
                    },
                    use_for_shipping: this.state.use_for_shipping,
                    first_name: this.state.first_name,
                    company_name: this.state.company_name,
                    last_name: this.state.last_name,
                    email: this.state.email,
                    city: this.state.city,
                    state: this.state.state,
                    postcode: this.state.postcode,
                    country: "IN",
                    phone: this.state.phone,
                    vat_id: this.state.vat_id,
                });
            } else {
                this.props.checkoutSaveAddress(postdata);
            }
        }
        if (Logintoken() == "") {
            this.zipCodeChange(postdata.shipping.postcode);
        }

        window.scrollTo({ top: 20, left: 0, behavior: "smooth" });
        //  this.myRef.current.scrollIntoView();
        console.log("handle Address submit ", this.state);
    }

    setAddress(addressid) {
        this.setState({ selectedaddress: addressid });
    }

    setFormDisplay(display) {
        this.setState({ selectedFormdisplay: display });
    }

    /**** Handle changes Shipping check */
    handlechangeshippingcheck(event) {
        var checkBox = document.getElementById("checkout-different-address");
        if (checkBox.checked == true) {
            this.setState({ use_for_shipping: true });
        } else {
            this.setState({ use_for_shipping: false });
        }
    }

    /****** Address Selected  */
    addressSelected(event) {
        console.log("value target data", event.target.value);

        var addressid = event.target.value;

        var values = JSON.parse(event.target.getAttribute("data-source"));
        this.setState({ selectedaddress: addressid });

        if (event.target.name == "address") {
            this.setState({
                postbillingdata: {
                    address1: {
                        0: values.address1.length > 0 ? values.address1[0] : "",
                    },
                    use_for_shipping: "true",
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    zipcode: values.postcode,
                    address_id: addressid,
                },
            });
        } else {
            this.setState({
                postshippingdata: {
                    address1: {
                        0: values.address1.length > 0 ? values.address1[0] : "",
                    },
                    use_for_shipping: "true",
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    address_id: addressid,
                    zipcode: values.postcode,
                },
            });
        }

        localStorage.removeItem("save_address_response");
    }

    /******* Save Address On Login  ****/
    SaveAddressOnLogin() {
        if (this.state.postbillingdata == "") {
            //alert("Please select Billing Address ");
            toast.error("Please select Billing Address");
            return false;
        }

        if (this.state.use_for_shipping && this.state.postshippingdata == "") {
            toast.error("Please select one Shipping Address");
            return false;
        }
        var postdata = {
            billing: this.state.postbillingdata,
            shipping: this.state.use_for_shipping ? this.state.postshippingdata : this.state.postbillingdata,
        };

        this.zipCodeChange(
            this.state.use_for_shipping ? this.state.postshippingdata.zipcode : this.state.postbillingdata.zipcode
        );

        this.props.checkoutSaveAddress(postdata);
        this.setFormDisplay(0);
        this.myRef.current.scrollIntoView();
    }

    componentDidMount() {
        const { stateslistAction, getAddresslist, getPaymentMethodsList } = this.props;

        console.log("shop page check ");
        stateslistAction();
        getAddresslist();
        getPaymentMethodsList();
        if (authCheckNull()) {
            this.setFormDisplay(1);
        }
        if (this.state.zipcode) {
            this.props.getShippingMethodsList(this.state.zipcode);
        }
    }

    /*** Add New Address ****/
    addnewaddress() {
        this.setState({ selectedFormdisplay: 1 });
    }

    backToAddressList() {
        this.setState({ selectedFormdisplay: 0 });
    }

    backtoaddress() {
        localStorage.removeItem("save_address_response");
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    }

    makeid() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijkl0123456789";

        for (var i = 0; i < 14; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    razorpayorder() {
        const { cart, address } = this.props;
        //alert("order id coming here ", this.makeid());
        console.log("grandtotal", cart.cartlist.grandtotal.split(".")[0]);
        let options = {
            key: RazorPayKey,
            amount: Math.round(cart.cartlist.grandtotal * 100), // 2000 paise = INR 20, amount in paisa
            name: "Ralson Payment Checkout",
            description: "Ralson Checkout ",
            image: "/your_logo.png",

            handler: function (response) {
                console.log("response", response);
                const logintoken = !authCheckNull() ? "&token=" + Logintoken() : "";
                fetch(ApiEndpointUrl + "razorpaypayverify?payid=" + response.razorpay_payment_id + logintoken)
                    .then(function (response) {
                        return response.json();
                    })
                    .then((data) => {
                        console.log("coming in response here", data);
                        window.location.href = frontUrl + "/ordersuccess/" + data.data;
                    })
                    .catch(function (thrown) {
                        console.log("coming in response on Failed", thrown);
                    });
            },
            theme: {
                color: "#F37254",
            },
        };

        let rzp = new window.Razorpay(options);
        rzp.open();
    }
    saveOrder() {
        var checkout_term = document.getElementById("checkout-terms");

        if (!checkout_term.checked) {
            this.setState({ checkout_term_error: true });
            return false;
        } else {
            this.setState({ checkout_term_error: false });
        }


        const { save_address_response, defaultpaymentmethod } = this.state;
        const { checkoutSavePayment } = this.props;
        checkoutSavePayment({
            payment: {
                method: defaultpaymentmethod,
            },
        });

        if (defaultpaymentmethod == "razorpay") {
            this.razorpayorder();
            return false;
        }
    }

    renderTotals() {
        const { cart } = this.props;
        const { save_address_response } = this.state;
        console.log("cart data ", cart);
        console.log("cartdata111111111===", save_address_response);

        let getAddressResponse = JSON.parse(localStorage.getItem("save_address_response"));

        if (cart.extraLines.length <= 0) {
            return null;
        }

        /* const extraLines = cart.extraLines.map((extraLine, index) => (
            <tr key={index}>
                <th>{extraLine.title}</th>
                <td><Currency value={extraLine.price} /></td>
            </tr>
        ));
      */
        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    <tr>
                        <th>Subtotal</th>
                        <td>
                            {!this.isEmpty(cart.cartlist.cartdata) ? (
                                <Currency value={cart.cartlist.cartdata.sub_total} />
                            ) : (
                                ""
                            )}
                        </td>
                    </tr>

                    {!this.isEmpty(cart.cartlist.cartdata) ? (
                        <tr>
                            <th>Discount</th>
                            <td>
                                <Currency value={cart.cartlist.cartdata.discount} />
                            </td>
                        </tr>
                    ) : (
                        ""
                    )}
                        <tr id="pri1" style={{display: "none"}}>
                            <th>Shipment Cost</th>
                            <td id="pri">
                            </td>
                        </tr>
                        
                        {getAddressResponse!==null && getAddressResponse.data.cart !== undefined ? (
                        <>
                            {getAddressResponse.data.cart.billing_address.state === "PB" || getAddressResponse.data.cart.billing_address.state === "Punjab" ? (
                                <>
                                    <tr>
                                        <th>CGST</th>
                                        <td>
                                            <Currency value={(getAddressResponse.data.cart.tax_total / 2).toFixed(2)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>SGST</th>
                                        <td>
                                            <Currency value={(getAddressResponse.data.cart.tax_total / 2).toFixed(2)} />
                                        </td>
                                    </tr>
                                </>
                                ) : (
                                <tr>
                                    <th>IGST</th>
                                    <td>
                                        <Currency value={(getAddressResponse.data.cart.tax_total)} />
                                    </td>
                                </tr>
                                )
                            }
                        </>
                    ):("")
                    }
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart, address } = this.props;

        const items = cart.cartlist.items.map((item) => (
            <tr key={item.id}>
                <td>{`${item.product.name} × ${item.quantity}`}</td>
                <td>
                    <Currency
                        value={item.product.special_price > 0 ? item.product.special_price : item.product.price}
                    />
                </td>
            </tr>
        ));

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th>Product</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">{items}</tbody>
                {this.renderTotals()}
                <tfoot className="checkout__totals-footer">
                    <tr id="tot" style={{display: "none"}}>
                        <th>Total</th>
                        <th id="tttpri"></th>
                    </tr>
                </tfoot>
            </table>
        );
    }

    renderPaymentsList() {
        const { payment: currentPayment, save_address_response } = this.state;
        const { paymentmethods } = this.props;
        console.log("props coming here ", paymentmethods);

        const payments = paymentmethods.items.map((payment) => {
            const renderPayment = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_payment_method"
                                    value={payment.method}
                                    checked={this.state.defaultpaymentmethod === payment.method}
                                    onChange={this.handlePaymentChange}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">{payment.method_title}</span>
                    </label>
                    <div className="payment-methods__item-container" ref={setContentRef}>
                        <div className="payment-methods__item-description text-muted">{payment.description}</div>
                    </div>
                </li>
            );

            return (
                <Collapse
                    key={payment.key}
                    open={currentPayment === payment.key}
                    toggleClass="payment-methods__item--active"
                    render={renderPayment}
                />
            );
        });

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">{payments}</ul>
            </div>
        );
    }

    render() {
        const { cart, saveaddress, statelist, saveorder, address, loadingsaveOrder, shiplist } = this.props;
        console.log(shiplist, "sdsd");
        const { validation, selectedFormdisplay, use_for_shipping } = this.state;
        const { loading } = this.props.saveaddress;
        let { shipping_rates, cart_items, save_address_response, checkout_term_error } = this.state;
        //console.log("savingaddress",save_address_response.data.cart.billing_address)
        save_address_response =
            localStorage.getItem("save_address_response") != undefined
                ? JSON.parse(localStorage.getItem("save_address_response"))
                : "";
        if (cart.cartlist.items.length < 1) {
            return <Redirect to="cart" />;
        }
        console.log("shipping data coming here", shiplist.items);
        console.log("shipping data minRateShipping", shiplist.minRateShipping);
        console.log("shipping data minRateAmongMinDays", shiplist.minRateAmongMinDays);
        if (shiplist.items) {
            shiplist.items.sort((a, b) => (a.rate > b.rate ? 1 : -1));
        }

        const breadcrumb = [
            { title: "Home", url: "" },
            { title: "Shopping Cart", url: "/shop/cart" },
            { title: "Checkout", url: "" },
        ];
        console.log("shipping data current ", this.state.shippingdata);
        console.log(shiplist.items, "=======dfdfdfd");

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Checkout — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Checkout" breadcrumb={breadcrumb} />

                <div className="checkout block">
                    <div className="container">
                        <div className="row">
                            {/* {authCheckNull() ? (
                                <div className="col-12 mb-3">
                                    <div className="alert alert-primary alert-lg">
                                        Returning customer? <Link to="/account/login">Click here to login</Link>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )} */}

                            <div className="col-12 col-lg-6 col-xl-7">
                                <div className="card mb-lg-0">
                                    {save_address_response != "" && save_address_response.data.cart ? (
                                        <React.Fragment>
                                            {" "}
                                            <div className="row no-gutters mx-n2">
                                                {save_address_response.data.cart.shipping_address.first_name ? (
                                                    <div className="col-sm-6 col-12">
                                                        <div className="card address-card address-card--featured my-3 mx-4">
                                                            <div className="address-card__body">
                                                                <div className="address-card__badge address-card__badge--muted">
                                                                    Shipping Address
                                                                </div>
                                                                <div className="address-card__name">
                                                                    {
                                                                        save_address_response.data.cart.shipping_address
                                                                            .first_name
                                                                    }{" "}
                                                                    {
                                                                        save_address_response.data.cart.shipping_address
                                                                            .last_name
                                                                    }
                                                                </div>
                                                                <div className="address-card__row">
                                                                    {
                                                                        save_address_response.data.cart.shipping_address
                                                                            .address1[0]
                                                                    }
                                                                    <br />
                                                                    {
                                                                        save_address_response.data.cart.shipping_address
                                                                            .city
                                                                    }
                                                                    ,{" "}
                                                                    {
                                                                        save_address_response.data.cart.shipping_address
                                                                            .state
                                                                    }
                                                                    , <br />(
                                                                    {
                                                                        save_address_response.data.cart.shipping_address
                                                                            .postcode
                                                                    }
                                                                    ) ,{" "}
                                                                    {
                                                                        save_address_response.data.cart.shipping_address
                                                                            .country
                                                                    }
                                                                </div>
                                                                <div className="address-card__row">
                                                                    <div className="address-card__row-title">
                                                                        Phone Number
                                                                    </div>
                                                                    <div className="address-card__row-content">
                                                                        {" "}
                                                                        {
                                                                            save_address_response.data.cart
                                                                                .shipping_address.phone
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="address-card__row">
                                                                    <div className="address-card__row-title">
                                                                        Email Address
                                                                    </div>
                                                                    <div className="address-card__row-content">
                                                                        {" "}
                                                                        {
                                                                            save_address_response.data.cart
                                                                                .shipping_address.email
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                <div className="col-sm-6 col-12">
                                                    <div className="card address-card address-card--featured my-3 mx-4">
                                                        <div className="address-card__body">
                                                            <div className="address-card__badge address-card__badge--muted">
                                                                Billing Address
                                                            </div>
                                                            <div className="address-card__name">
                                                                {
                                                                    save_address_response.data.cart.billing_address
                                                                        .first_name
                                                                }{" "}
                                                                {
                                                                    save_address_response.data.cart.billing_address
                                                                        .last_name
                                                                }
                                                            </div>
                                                            <div className="address-card__row">
                                                                {
                                                                    save_address_response.data.cart.billing_address
                                                                        .address1[0]
                                                                }
                                                                <br />
                                                                {
                                                                    save_address_response.data.cart.billing_address.city
                                                                },{" "}
                                                                {save_address_response.data.cart.billing_address.state},{" "}
                                                                <br />(
                                                                {
                                                                    save_address_response.data.cart.billing_address
                                                                        .postcode
                                                                }
                                                                ) ,{" "}
                                                                {
                                                                    save_address_response.data.cart.billing_address
                                                                        .country
                                                                }
                                                            </div>
                                                            <div className="address-card__row">
                                                                <div className="address-card__row-title">
                                                                    Phone Number
                                                                </div>
                                                                <div className="address-card__row-content">
                                                                    {
                                                                        save_address_response.data.cart.billing_address
                                                                            .phone
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="address-card__row">
                                                                <div className="address-card__row-title">
                                                                    Email Address
                                                                </div>
                                                                <div className="address-card__row-content">
                                                                    {
                                                                        save_address_response.data.cart.billing_address
                                                                            .email
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-12 ">
                                                    <div className="card address-card address-card--featured mx-4 ">
                                                        <div className="card-body">
                                                            <div className="address-card__row  card-title mb-0">
                                                                <div className="card-title font-weight-bold">
                                                                    Select Shipping Method
                                                                </div>
                                                            </div>
                                                            <div className="address-card__row ">
                                                                {shiplist.loading ? (
                                                                    <div className="col-lg-12 mt-4 mt-lg-0">
                                                                        <div className="d-block">
                                                                            <div className="mt-4 card-input card blockload">
                                                                                {" "}
                                                                                <div className="col-lg-12 w-100">
                                                                                    <BlockLoader />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    // ""
                                                                    // )}

                                                                    <div className="scrollDiv1 shipping_area">
                                                                        <div className="row">
                                                                            {shiplist.minRateShipping
                                                                                .courier_company_id ===
                                                                            shiplist.minRateAmongMinDays
                                                                                .courier_company_id ? (
                                                                                <div className="col-md-6">
                                                                                    {" "}
                                                                                    <div className="shippingoption  my-2">
                                                                                        {/* <h5>Free Shipping</h5> */}
                                                                                        <input
                                                                                            type="radio"
                                                                                            id={
                                                                                                shiplist.minRateShipping
                                                                                                    .courier_company_id
                                                                                            }
                                                                                            className="btn-check d-none "
                                                                                            name="shippingoption"
                                                                                            checked={
                                                                                                this.state
                                                                                                    .shippingdata !=
                                                                                                    "" &&
                                                                                                this.state
                                                                                                    .shippingdata ==
                                                                                                    shiplist
                                                                                                        .minRateShipping
                                                                                                        .courier_company_id
                                                                                            }
                                                                                            data={JSON.stringify(
                                                                                                shiplist.minRateShipping
                                                                                            )}
                                                                                            data1="Free Shipping"
                                                                                            data2= {0}
                                                                                            onChange={
                                                                                                this.ApplyShipping
                                                                                            }
                                                                                        />{" "}
                                                                                        <label
                                                                                            className="btn btn-secondary w-100"
                                                                                            for={
                                                                                                shiplist.minRateShipping
                                                                                                    .courier_company_id
                                                                                            }
                                                                                        >
                                                                                            <i className="fa fa-truck mt-2 mb-3 ship-icon"></i>
                                                                                            <p className="text-center ship-title">
                                                                                                Free Shipping
                                                                                            </p>
                                                                                            <p className="text-center">
                                                                                                Estimated delivery days
                                                                                                :{" "}
                                                                                                {
                                                                                                    shiplist
                                                                                                        .minRateShipping
                                                                                                        .estimated_delivery_days
                                                                                                }
                                                                                            </p>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <Fragment>
                                                                                    <div className="col-md-6">
                                                                                        {" "}
                                                                                        <div className="shippingoption  my-2">
                                                                                            {/* <h5>Free Shipping</h5> */}
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={
                                                                                                    shiplist
                                                                                                        .minRateShipping
                                                                                                        .courier_company_id
                                                                                                }
                                                                                                className="btn-check d-none "
                                                                                                name="shippingoption"
                                                                                                checked={
                                                                                                    this.state
                                                                                                        .shippingdata !=
                                                                                                        "" &&
                                                                                                    this.state
                                                                                                        .shippingdata ==
                                                                                                        shiplist
                                                                                                            .minRateShipping
                                                                                                            .courier_company_id
                                                                                                }
                                                                                                data={JSON.stringify(
                                                                                                    shiplist.minRateShipping
                                                                                                )}
                                                                                                data1="Free Shipping"
                                                                                                data2= {0}
                                                                                                onChange={
                                                                                                    this.ApplyShipping
                                                                                                }
                                                                                            />{" "}
                                                                                            <label
                                                                                                className="btn btn-secondary w-100"
                                                                                                for={
                                                                                                    shiplist
                                                                                                        .minRateShipping
                                                                                                        .courier_company_id
                                                                                                }
                                                                                            >
                                                                                                <i className="fa fa-truck mt-2 mb-3 ship-icon"></i>
                                                                                                <p className="text-center ship-title">
                                                                                                    Free Shipping
                                                                                                </p>
                                                                                                <p className="text-center">
                                                                                                    Estimated delivery
                                                                                                    days :{" "}
                                                                                                    {
                                                                                                        shiplist
                                                                                                            .minRateShipping
                                                                                                            .estimated_delivery_days
                                                                                                    }
                                                                                                </p>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        {" "}
                                                                                        <div className="shippingoption  my-2">
                                                                                            {/* <h5>Express Shipping</h5> */}
                                                                                            <input
                                                                                                type="radio"
                                                                                                id={
                                                                                                    shiplist
                                                                                                        .minRateAmongMinDays
                                                                                                        .courier_company_id
                                                                                                }
                                                                                                className="btn-check d-none "
                                                                                                name="shippingoption"
                                                                                                checked={
                                                                                                    this.state
                                                                                                        .shippingdata !=
                                                                                                        "" &&
                                                                                                    this.state
                                                                                                        .shippingdata ==
                                                                                                        shiplist
                                                                                                            .minRateAmongMinDays
                                                                                                            .courier_company_id
                                                                                                }
                                                                                                data={JSON.stringify(
                                                                                                    shiplist.minRateAmongMinDays
                                                                                                )}
                                                                                                data1="Express Shipping"
                                                                                                data2= {
                                                                                                    shiplist
                                                                                                        .minRateAmongMinDays
                                                                                                        .rate
                                                                                                }
                                                                                                onChange={
                                                                                                    this.ApplyShipping
                                                                                                }
                                                                                            />{" "}
                                                                                            <label
                                                                                                className="btn btn-secondary w-100"
                                                                                                for={
                                                                                                    shiplist
                                                                                                        .minRateAmongMinDays
                                                                                                        .courier_company_id
                                                                                                }
                                                                                            >
                                                                                                <i className="fa fa-truck mt-2 mb-3 ship-icon"></i>
                                                                                                <p className="text-center ship-title">
                                                                                                    Express Shipping
                                                                                                </p>
                                                                                                <p className="text-center">
                                                                                                    Estimated delivery
                                                                                                    days :{" "}
                                                                                                    {
                                                                                                        shiplist
                                                                                                            .minRateAmongMinDays
                                                                                                            .estimated_delivery_days
                                                                                                    }
                                                                                                </p>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </Fragment>
                                                                            )}

                                                                            {/* {shiplist.items.sort((a, b) => a.value > b.value ? 1 : -1).map((value, item) => {
                                                                            // return item === 0 ? '' : ( // 1st element
                                                                            // return item !== (shiplist.items.length - 1) ? '' : ( // last element

                                                                            return ( // all element
                                                                                <div className="col-md-4"> <div className="text-center shippingoption  my-2">
                                                                                    <input
                                                                                        type="radio"
                                                                                        id={item}
                                                                                        class="btn-check d-none "
                                                                                        name="shippingoption"
                                                                                        checked={
                                                                                            this.state.shippingdata != "" &&
                                                                                            this.state.shippingdata ==
                                                                                            value.courier_company_id
                                                                                        }
                                                                                        data={JSON.stringify(value)}
                                                                                        onChange={this.ApplyShipping}
                                                                                    />{" "}
                                                                                    <label class="btn btn-secondary" for={item}>
                                                                                        <p className="text-align-left">
                                                                                            {value.courier_name}
                                                                                        </p>
                                                                                        <p className="text-align-left">
                                                                                            Delivery Cost : &#8377;
                                                                                            {value.rate}
                                                                                        </p>
                                                                                        <p className="text-align-left">
                                                                                            Estimated delivery days :{" "}
                                                                                            {value.estimated_delivery_days}
                                                                                        </p>
                                                                                    </label>
                                                                                </div></div>
                                                                            );
                                                                        })} */}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <Link to="#" onClick={this.backtoaddress}>
                                                    {authCheckNull() ? "Change Address" : "Back to Address List"}
                                                </Link>
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className="card-body">
                                                {address.list.items.length > 0 ? (
                                                    <h3 className="card-title mt-3 mb-0">Select Billing Address</h3>
                                                ) : (
                                                    <h3 className="card-title">Add Address</h3>
                                                )}

                                                <div className="form-row">
                                                    {/* {address.list.loading ? (
                                                        <div className="col-lg-12 mt-4 mt-lg-0">
                                                            <div className="addresses-list justify-content-between d-block">
                                                                <div className="mt-4 card-input card blockload address-card--feature">
                                                                    {" "}
                                                                    <div className="col-lg-12 address-card__body w-100">
                                                                        <BlockLoader />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                    {address.list.items.length > 0 ? (
                                                        <React.Fragment>
                                                            <div className="col-12 col-lg-12 mt-4 mt-lg-0">
                                                                <div className="addresses-list justify-content-between">
                                                                    {!selectedFormdisplay ? (
                                                                        <Link
                                                                            to="#"
                                                                            onClick={this.addnewaddress}
                                                                            className="mt-4 addresses-list__item addresses-list__item--new"
                                                                        >
                                                                            <div className="addresses-list__plus" />
                                                                            <div className="btn btn-secondary btn-sm">
                                                                                Add New
                                                                            </div>
                                                                        </Link>
                                                                    ) : (
                                                                        ""
                                                                    )}

                                                                    {!selectedFormdisplay
                                                                        ? address.list.items.map((value, index) => (
                                                                              <React.Fragment>
                                                                                  <label className="dashboard__address m-0">
                                                                                      <input
                                                                                          type="radio"
                                                                                          id={index}
                                                                                          value={value.id}
                                                                                          name="address"
                                                                                          className="card-input-element address-input "
                                                                                          data-source={JSON.stringify(
                                                                                              value
                                                                                          )}
                                                                                          onChange={
                                                                                              this.addressSelected
                                                                                          }
                                                                                      />
                                                                                      <div
                                                                                          className="mt-4 card-input card address-card address-card--feature"
                                                                                          for={index}
                                                                                      >
                                                                                          <div className="address-card__body">
                                                                                              <div className="address-card__name">
                                                                                                  {value.first_name}{" "}
                                                                                                  {value.last_name}
                                                                                              </div>
                                                                                              <div className="address-card__row">
                                                                                                  {value.address1[0]}{" "}
                                                                                                  <br />
                                                                                                  {value.city} (
                                                                                                  {value.postcode}){" "}
                                                                                                  <br />
                                                                                                  {value.state},{" "}
                                                                                                  {value.country}
                                                                                                  <br />
                                                                                              </div>
                                                                                              <div className="address-card__row">
                                                                                                  <div className="address-card__row-title">
                                                                                                      Phone Number
                                                                                                  </div>
                                                                                                  <div className="address-card__row-content">
                                                                                                      {value.phone}
                                                                                                  </div>
                                                                                              </div>
                                                                                          </div>
                                                                                      </div>{" "}
                                                                                  </label>
                                                                              </React.Fragment>
                                                                          ))
                                                                        : ""}
                                                                </div>
                                                            </div>

                                                            {/* </div>   */}
                                                        </React.Fragment>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                {address.list.items.length > 0 ? (
                                                    <div>
                                                        {" "}
                                                        <h3 className="card-title">
                                                            {use_for_shipping ? "Select Shipping Address" : ""}
                                                        </h3>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                {!selectedFormdisplay && address.list.items.length > 0 ? (
                                                    <div className="form-row">
                                                        <div className="form-group mb-0">
                                                            <div className="form-check ml-2 mt-3">
                                                                <span className="form-check-input input-check">
                                                                    <span className="input-check__body">
                                                                        <input
                                                                            className="input-check__input"
                                                                            name="use_for_shipping"
                                                                            type="checkbox"
                                                                            onChange={this.handlechangeshippingcheck}
                                                                            id="checkout-different-address"
                                                                        />
                                                                        <span className="input-check__box" />
                                                                        <Check9x7Svg className="input-check__icon" />
                                                                    </span>
                                                                </span>
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="checkout-different-address"
                                                                >
                                                                    Ship to a different address?
                                                                </label>
                                                            </div>
                                                        </div>

                                                        {address.list.items.length > 0 && use_for_shipping ? (
                                                            <React.Fragment>
                                                                <div className="col-12 col-lg-12 mt-4 mt-lg-0">
                                                                    <div className="addresses-list justify-content-between">
                                                                        {!selectedFormdisplay
                                                                            ? address.list.items.map((value, index) => (
                                                                                  <React.Fragment>
                                                                                      <label className="dashboard__address m-0">
                                                                                          <input
                                                                                              type="radio"
                                                                                              id={index}
                                                                                              value={value.id}
                                                                                              name="shipping_address"
                                                                                              className="card-input-element address-input "
                                                                                              data-source={JSON.stringify(
                                                                                                  value
                                                                                              )}
                                                                                              onChange={
                                                                                                  this.addressSelected
                                                                                              }
                                                                                          />
                                                                                          <div
                                                                                              className="mt-4 card-input card address-card address-card--feature"
                                                                                              for={index}
                                                                                          >
                                                                                              <div className="address-card__body">
                                                                                                  <div className="address-card__name">
                                                                                                      {value.first_name}{" "}
                                                                                                      {value.last_name}
                                                                                                  </div>
                                                                                                  <div className="address-card__row">
                                                                                                      {
                                                                                                          value
                                                                                                              .address1[0]
                                                                                                      }{" "}
                                                                                                      <br />
                                                                                                      {value.city} (
                                                                                                      {value.postcode}){" "}
                                                                                                      <br />
                                                                                                      {
                                                                                                          value.state
                                                                                                      }, {value.country}
                                                                                                      <br />
                                                                                                  </div>
                                                                                                  <div className="address-card__row">
                                                                                                      <div className="address-card__row-title">
                                                                                                          Phone Number
                                                                                                      </div>
                                                                                                      <div className="address-card__row-content">
                                                                                                          {value.phone}
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </div>
                                                                                          </div>{" "}
                                                                                      </label>
                                                                                      {/* <div className="addresses-list__divider" /> */}
                                                                                  </React.Fragment>
                                                                              ))
                                                                            : ""}
                                                                    </div>
                                                                </div>

                                                                {/* </div>   */}
                                                            </React.Fragment>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <br />
                                                        <div className="form-group col-md-12 m-0 pt-2 saveaddressbtn">
                                                            {save_address_response == "" &&
                                                            address.list.items.length > 0 ? (
                                                                <button
                                                                    onClick={this.SaveAddressOnLogin}
                                                                    className={classNames(
                                                                        "btn btn-primary btn-xl mt-4 btn-block",
                                                                        {
                                                                            "btn-loading": loading,
                                                                        }
                                                                    )}
                                                                >
                                                                    Save Address
                                                                </button>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}

                                                {selectedFormdisplay ||
                                                (address.list.items.length < 1 ) ? (
                                                    <React.Fragment>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="checkout-first-name">First Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="checkout-first-name"
                                                                    placeholder="First Name"
                                                                    name="first_name"
                                                                    onKeyUp={this.handlechange}
                                                                />
                                                                {validation.first_name_error ? (
                                                                    <p className="  alert-danger">
                                                                        Please Enter First Name
                                                                    </p>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="checkout-last-name">Last Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="checkout-last-name"
                                                                    placeholder="Last Name"
                                                                    name="last_name"
                                                                    onKeyUp={this.handlechange}
                                                                />
                                                                {validation.last_name_error ? (
                                                                    <p className="  alert-danger">
                                                                        Please Enter Last Name
                                                                    </p>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="checkout-company-name">
                                                                Company Name{" "}
                                                                <span className="text-muted">(Optional)</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="checkout-company-name"
                                                                placeholder="Company Name"
                                                                name="company"
                                                                onKeyUp={this.handlechange}
                                                            />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="checkout-street-address">
                                                                Street Address
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="checkout-street-address"
                                                                placeholder="Street Address"
                                                                name="street_address"
                                                                onKeyUp={this.handlechange}
                                                            />
                                                            {validation.street_address_error ? (
                                                                <p className="  alert-danger">
                                                                    Please Enter Street Address
                                                                </p>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="checkout-address">
                                                                Apartment, suite, unit etc.{" "}
                                                                <span className="text-muted">(Optional)</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                onKeyUp={this.handlechange}
                                                                name="apartment_address"
                                                                placeholder="Enter Apartment, suite, unit etc"
                                                                className="form-control"
                                                                id="checkout-address"
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="checkout-city">Town / City</label>
                                                            <input
                                                                type="text"
                                                                name="city"
                                                                onKeyUp={this.handlechange}
                                                                placeholder="Enter City"
                                                                className="form-control"
                                                                id="checkout-city"
                                                            />
                                                            {validation.city_error ? (
                                                                <p className="  alert-danger">Please Enter City </p>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="checkout-state">State </label>

                                                            <select
                                                                name="states"
                                                                name="state"
                                                                onChange={this.handlechange}
                                                                className="form-control"
                                                                id="states"
                                                            >
                                                                <option value="">Select State</option>
                                                                {statelist.map((value) => (
                                                                    <option value={value.code}>
                                                                        {value.default_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="checkout-postcode">Postcode / ZIP</label>
                                                            <input
                                                                type="text"
                                                                name="postcode"
                                                                onKeyUp={this.handlechange}
                                                                placeholder="Enter Postcode / ZIP"
                                                                className="form-control"
                                                                id="checkout-postcode"
                                                            />
                                                            {validation.postcode_error ? (
                                                                <p className="  alert-danger">
                                                                    Please Enter Valid Zipcode{" "}
                                                                </p>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>

                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="checkout-email">Email address</label>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    id="checkout-email"
                                                                    name="email"
                                                                    placeholder="Enter Email address"
                                                                    onKeyUp={this.handlechange}
                                                                />
                                                                {validation.email_error ? (
                                                                    <p className="  alert-danger">
                                                                        Please Enter Valid Email{" "}
                                                                    </p>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="checkout-phone">Phone</label>
                                                                <input
                                                                    type="text"
                                                                    onKeyUp={this.handlechange}
                                                                    name="phone"
                                                                    className="form-control"
                                                                    id="checkout-phone"
                                                                    name="phone"
                                                                    placeholder="Enter Phone"
                                                                />
                                                                {validation.phonenumber_error ? (
                                                                    <p className="  alert-danger">
                                                                        Please Enter Valid Phone number{" "}
                                                                    </p>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>

                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="checkout-phone">
                                                                    GST No (Optional)
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    onKeyUp={this.handlechange}
                                                                    name="phone"
                                                                    className="form-control"
                                                                    id="checkout-phone"
                                                                    name="vat_id"
                                                                    placeholder="Enter GST No."
                                                                />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="card-divider" />
                                            {selectedFormdisplay ||
                                            (address.list.items.length < 1 && !address.list.loading) ? (
                                                <div className="card-body">
                                                    <h3 className="card-title">Shipping Details</h3>

                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <span className="form-check-input input-check">
                                                                <span className="input-check__body">
                                                                    <input
                                                                        className="input-check__input"
                                                                        name="use_for_shipping"
                                                                        type="checkbox"
                                                                        onChange={this.handlechangeshippingcheck}
                                                                        id="checkout-different-address"
                                                                    />
                                                                    <span className="input-check__box" />
                                                                    <Check9x7Svg className="input-check__icon" />
                                                                </span>
                                                            </span>
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="checkout-different-address"
                                                            >
                                                                Ship to a different address?
                                                            </label>
                                                        </div>
                                                    </div>

                                                    {use_for_shipping ? (
                                                        <React.Fragment>
                                                            <div className="form-row">
                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="checkout-shipping-first-name">
                                                                        First Name
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="checkout-shipping-first-name"
                                                                        placeholder="First Name"
                                                                        name="shipping_first_name"
                                                                        onKeyUp={this.handlechange}
                                                                    />
                                                                    {validation.shipping_first_name_error ? (
                                                                        <p className="  alert-danger">
                                                                            Please Enter First Name
                                                                        </p>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="checkout-shipping-last-name">
                                                                        Last Name
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="checkout-shipping-last-name"
                                                                        placeholder="Last Name"
                                                                        name="shipping_last_name"
                                                                        onKeyUp={this.handlechange}
                                                                    />
                                                                    {validation.shipping_last_name_error ? (
                                                                        <p className="  alert-danger">
                                                                            Please Enter Last Name
                                                                        </p>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="checkout-shipping-company-name">
                                                                    Company Name{" "}
                                                                    <span className="text-muted">(Optional)</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="checkout-shipping-company-name"
                                                                    placeholder="Company Name"
                                                                    name="shipping_company"
                                                                    onKeyUp={this.handlechange}
                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="checkout-shipping-street-address">
                                                                    Street Address
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="checkout-street-address"
                                                                    placeholder="Street Address"
                                                                    name="shipping_street_address"
                                                                    onKeyUp={this.handlechange}
                                                                />
                                                                {validation.shipping_street_address_error ? (
                                                                    <p className="  alert-danger">
                                                                        Please Enter Street Address
                                                                    </p>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="checkout-shipping-address">
                                                                    Apartment, suite, unit etc.{" "}
                                                                    <span className="text-muted">(Optional)</span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    onKeyUp={this.handlechange}
                                                                    name="shipping_apartment_address"
                                                                    placeholder="Enter Apartment, suite, unit etc"
                                                                    className="form-control"
                                                                    id="checkout-shipping-address"
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="checkout-shipping-city">
                                                                    Town / City
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="shipping_city"
                                                                    placeholder="Enter City"
                                                                    onKeyUp={this.handlechange}
                                                                    className="form-control"
                                                                    id="checkout-shipping-city"
                                                                />
                                                                {validation.shipping_city_error ? (
                                                                    <p className="  alert-danger">Please Enter City </p>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="checkout-shipping-state">State </label>

                                                                <select
                                                                    name="states"
                                                                    name="shipping_state"
                                                                    onChange={this.handlechange}
                                                                    className="form-control"
                                                                    id="checkout-shipping-states"
                                                                >
                                                                    <option value="">Select State</option>
                                                                    {statelist.map((value) => (
                                                                        <option value={value.default_name}>
                                                                            {value.default_name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="checkout-shipping-postcode">
                                                                    Postcode / ZIP
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="shipping_postcode"
                                                                    onKeyUp={this.handlechange}
                                                                    placeholder="Enter Zipcode"
                                                                    className="form-control"
                                                                    id="checkout-shipping-postcode"
                                                                />
                                                                {validation.shipping_postcode_error ? (
                                                                    <p className="  alert-danger">
                                                                        Please Enter Valid Zipcode{" "}
                                                                    </p>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>

                                                            <div className="form-row">
                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="checkout-shipping-email">
                                                                        Email address
                                                                    </label>
                                                                    <input
                                                                        type="email"
                                                                        className="form-control"
                                                                        id="checkout-shipping-email"
                                                                        name="shipping_email"
                                                                        placeholder="Enter Email address"
                                                                        onKeyUp={this.handlechange}
                                                                    />
                                                                    {validation.shipping_email_error ? (
                                                                        <p className="  alert-danger">
                                                                            Please Enter Valid Email{" "}
                                                                        </p>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="checkout-shipping-phone">
                                                                        Phone
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        onKeyUp={this.handlechange}
                                                                        name="shipping_phone"
                                                                        className="form-control"
                                                                        id="checkout-shipping-phone"
                                                                        placeholder="Enter Phone Number"
                                                                    />
                                                                    {validation.shipping_phonenumber_error ? (
                                                                        <p className="  alert-danger">
                                                                            Please Enter Valid Phone number{" "}
                                                                        </p>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    ) : (
                                                        ""
                                                    )}

                                                    <div className="form-group">
                                                        <label htmlFor="checkout-comment">
                                                            Order notes <span className="text-muted">(Optional)</span>
                                                        </label>
                                                        <textarea
                                                            id="checkout-comment"
                                                            className="form-control"
                                                            rows="4"
                                                            placeholder="Enter Order Note"
                                                        />
                                                    </div>

                                                    <div className="form-group col-md-12 m-0 p-0">
                                                        <button
                                                            onClick={this.handleAddressSubmit}
                                                            className={classNames("btn btn-primary btn-xl btn-block", {
                                                                "btn-loading": loading,
                                                            })}
                                                        >
                                                            Save Address
                                                        </button>
                                                    </div>

                                                    <div className="form-group col-md-12 pl-0 pt-3">
                                                        {!authCheckNull() ? (
                                                            <Link to="#" onClick={this.backToAddressList}>
                                                                Back to Address List
                                                            </Link>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>

                            <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0" ref={this.myRef}>
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <h3 className="card-title">Your Order</h3>

                                        {this.renderCart()}

                                        {saveaddress.addressSaved > 0 && this.state.shippingmethod != ""
                                            ? this.renderPaymentsList()
                                            : ""}

                                        {this.state.shippingmethod != "" ? (
                                            <div className="checkout__agree form-group">
                                                <div className="form-check">
                                                    <span className="form-check-input input-check">
                                                        <span className="input-check__body">
                                                            <input
                                                                className="input-check__input"
                                                                type="checkbox"
                                                                id="checkout-terms"
                                                            />
                                                            <span className="input-check__box" />
                                                            <Check9x7Svg className="input-check__icon" />
                                                        </span>
                                                    </span>
                                                    <label className="form-check-label" htmlFor="checkout-terms">
                                                        I have read and agree to the
                                                        <Link to="/page/terms-conditions"> terms and conditions</Link>
                                                    </label>
                                                    {checkout_term_error ? (
                                                        <p className="alert-danger bg-white pl-2">
                                                            Select term and conditions*
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {saveaddress.addressSaved > 0 && this.state.shippingmethod != "" ? (
                                            <button
                                                id="main__btn"
                                                type="submit"
                                                onClick={this.saveOrder}
                                                className={classNames("btn btn-primary btn-xl btn-block", {
                                                    "btn-loading": saveorder.loading,
                                                })}
                                            >
                                                Place Order
                                            </button>
                                        ) : (
                                            ""
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    statelist: state.states.items,
    saveaddress: state.saveaddress,
    saveorder: state.saveorder,
    loadingsaveOrder: state.saveorder.loading,
    address: state.address,
    paymentmethods: state.paymentmethods,
    cart: state.cart,
    shiplist: state.shippinglist,
});

const mapDispatchToProps = {
    checkoutSaveAddress,
    stateslistAction,
    getAddresslist,
    checkoutSaveShipping,
    SaveAddress,
    getPaymentMethodsList,
    getShippingMethodsList,
    checkoutSavePayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
