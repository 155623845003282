// react
import React, { useEffect, useState } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

// application
import PageHeader from "../shared/PageHeader";
import Product from "../shared/Product";
import ProductTabs from "./ProductTabs";
import shopApi from "../../api/shop";
import { url } from "../../services/utils";
import ContentLoader from "../shared/ProductDetailsLoader";

// blocks
import BlockLoader from "../blocks/BlockLoader";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";

// widgets
import WidgetCategories from "../widgets/WidgetCategories";
import WidgetProducts from "../widgets/WidgetProducts";

// data stubs
import categories from "../../data/shopWidgetCategories";
import theme from "../../data/theme";
import { getProductDetail, getproductDetailVariation, getRelatedProduct } from "../../store/product";
import { connect } from "react-redux";
import BlockSlideShow from "../blocks/BlockSlideShow";
import ReactPlayer from "react-player";

function ShopPageProduct(props) {
    const { productSlug, layout, sidebarPosition, productspec, match, related, banner } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [latestProducts, setLatestProducts] = useState([]);

    const sliderSettings = {
        autoplay: true,
        pauseOnHover: false,
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    /**
     * Slider Images.
     */
    const sliderImages = [
        {
            image_classic: {
                ltr: "images/banners/oneplus1.jpg",
                rtl: "images/banners/oneplus1.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus1.jpg",
                rtl: "images/banners/oneplus1.jpg",
            },
            image_mobile: {
                ltr: "images/banners/product-banner1.jpg",
                rtl: "images/banners/product-banner1.jpg",
            },
        },
    ];
    const sliderImages3 = [
        {
            image_classic: {
                ltr: "images/banners/oneplus2.jpg",
                rtl: "images/banners/oneplus2.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus2.jpg",
                rtl: "images/banners/oneplus2.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus2.jpg",
                rtl: "images/banners/oneplus2.jpg",
            },
        },
    ];
    const sliderImages4 = [
        {
            image_classic: {
                ltr: "images/banners/oneplus3.jpg",
                rtl: "images/banners/oneplus3.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus3.jpg",
                rtl: "images/banners/oneplus3.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus3.jpg",
                rtl: "images/banners/oneplus3.jpg",
            },
        },
    ];
    const sliderImages5 = [
        {
            image_classic: {
                ltr: "images/banners/oneplus4.jpg",
                rtl: "images/banners/oneplus4.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus4.jpg",
                rtl: "images/banners/oneplus4.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus4.jpg",
                rtl: "images/banners/oneplus4.jpg",
            },
        },
    ];
    const sliderImages6 = [
        {
            image_classic: {
                ltr: "images/banners/oneplus11.jpg",
                rtl: "images/banners/oneplus11.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus11.jpg",
                rtl: "images/banners/oneplus11.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus11.jpg",
                rtl: "images/banners/oneplus11.jpg",
            },
        },
        {
            image_classic: {
                ltr: "images/banners/oneplus12.jpg",
                rtl: "images/banners/oneplus12.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus12.jpg",
                rtl: "images/banners/oneplus12.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus12.jpg",
                rtl: "images/banners/oneplus12.jpg",
            },
        },
        {
            image_classic: {
                ltr: "images/banners/oneplus13.jpg",
                rtl: "images/banners/oneplus13.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus13.jpg",
                rtl: "images/banners/oneplus13.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus13.jpg",
                rtl: "images/banners/oneplus13.jpg",
            },
        },
        {
            image_classic: {
                ltr: "images/banners/oneplus14.jpg",
                rtl: "images/banners/oneplus14.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus14.jpg",
                rtl: "images/banners/oneplus14.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus14.jpg",
                rtl: "images/banners/oneplus14.jpg",
            },
        },
        {
            image_classic: {
                ltr: "images/banners/oneplus15.jpg",
                rtl: "images/banners/oneplus15.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus15.jpg",
                rtl: "images/banners/oneplus15.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus15.jpg",
                rtl: "images/banners/oneplus15.jpg",
            },
        },
    ];
    const sliderImages7 = [
        {
            image_classic: {
                ltr: "images/banners/oneplus16.jpg",
                rtl: "images/banners/oneplus16.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus16.jpg",
                rtl: "images/banners/oneplus16.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus16.jpg",
                rtl: "images/banners/oneplus16.jpg",
            },
        },
        {
            image_classic: {
                ltr: "images/banners/oneplus17.jpg",
                rtl: "images/banners/oneplus17.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus17.jpg",
                rtl: "images/banners/oneplus17.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus17.jpg",
                rtl: "images/banners/oneplus17.jpg",
            },
        },
        {
            image_classic: {
                ltr: "images/banners/oneplus18.jpg",
                rtl: "images/banners/oneplus18.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus18.jpg",
                rtl: "images/banners/oneplus18.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus18.jpg",
                rtl: "images/banners/oneplus18.jpg",
            },
        },
        {
            image_classic: {
                ltr: "images/banners/oneplus19.jpg",
                rtl: "images/banners/oneplus19.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus19.jpg",
                rtl: "images/banners/oneplus19.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus19.jpg",
                rtl: "images/banners/oneplus19.jpg",
            },
        },
    ];
    const sliderImages2 = [
        {
            // title: 'MegaProcessor',
            // text: 'With powerful Samsung Exynos 9611 SoC.',
            image_classic: {
                ltr: "images/banners/oneplus5.jpg",
                rtl: "images/banners/oneplus5.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus5.jpg",
                rtl: "images/banners/oneplus5.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus5.jpg",
                rtl: "images/banners/oneplus5.jpg",
            },
        },
        {
            image_classic: {
                ltr: "images/banners/oneplus6.jpg",
                rtl: "images/banners/oneplus6.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus6.jpg",
                rtl: "images/banners/oneplus6.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus6.jpg",
                rtl: "images/banners/oneplus6.jpg",
            },
        },
        {
            image_classic: {
                ltr: "images/banners/oneplus7.jpg",
                rtl: "images/banners/oneplus7.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus7.jpg",
                rtl: "images/banners/oneplus7.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus7.jpg",
                rtl: "images/banners/oneplus7.jpg",
            },
        },
        {
            image_classic: {
                ltr: "images/banners/oneplus8.jpg",
                rtl: "images/banners/oneplus8.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus8.jpg",
                rtl: "images/banners/oneplus8.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus8.jpg",
                rtl: "images/banners/oneplus8.jpg",
            },
        },
        {
            image_classic: {
                ltr: "images/banners/oneplus9.jpg",
                rtl: "images/banners/oneplus9.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus9.jpg",
                rtl: "images/banners/oneplus9.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus9.jpg",
                rtl: "images/banners/oneplus9.jpg",
            },
        },
        {
            image_classic: {
                ltr: "images/banners/oneplus10.jpg",
                rtl: "images/banners/oneplus10.jpg",
            },
            image_full: {
                ltr: "images/banners/oneplus10.jpg",
                rtl: "images/banners/oneplus10.jpg",
            },
            image_mobile: {
                ltr: "images/banners/oneplus10.jpg",
                rtl: "images/banners/oneplus10.jpg",
            },
        },
    ];
    console.log("blog data coming here", banner);
    // Load product.
    useEffect(() => {
        let canceled = false;

        setIsLoading(true);

        /****** Getting product details  ***/
        props.getProductDetail(productSlug);
        props.getRelatedProduct(productSlug);

        return () => {
            canceled = true;
        };
    }, [productSlug, setIsLoading, match.params]);

    // Load related products.
    useEffect(() => {
        let canceled = false;

        shopApi.getRelatedProducts(productSlug, { limit: 8 }).then((products) => {
            if (canceled) {
                return;
            }

            setRelatedProducts(products);
        });

        return () => {
            canceled = true;
        };
    }, [productSlug, setRelatedProducts]);

    // Load latest products.
    useEffect(() => {
        let canceled = false;

        if (layout !== "sidebar") {
            setLatestProducts([]);
        } else {
            shopApi.getLatestProducts({ limit: 5 }).then((result) => {
                if (canceled) {
                    return;
                }

                setLatestProducts(result);
            });
        }

        return () => {
            canceled = true;
        };
    }, [layout]);
    console.log("product Detail", props.productdetail.loading, related.items);

    if (props.productdetail.loading) {
        return (
            <div className="container">
                <ContentLoader />
            </div>
        )
    }
    let productdetaildata = {
        id: 0,
        name: "",
        sku: "",
        slug: "",
        price: "00",
        compareAtPrice: null,
        badges: "salsddde",
        images: [],
        rating: [],
        availability: "in-stock",
        brand: null,
        categories: [],
        attributes: [],
        customFields: {},
    };
    if (props.productdetail.items.length > 0) {
        props.productdetail.items.forEach((element) => {
            productdetaildata = {
                id: element.id,
                name: element.name,
                sku: element.sku,
                slug: element.url_key,
                price: parseFloat(element.price),
                special_price: parseFloat(element.special_price),
                regular_price: parseFloat(element.regular_price),
                compareAtPrice: null,
                short_description: element.short_description,
                description: element.description,
                badges: "salsddde",
                images: element.images,
                rating: element.reviews.average_rating,
                reviews: element.reviews,
                availability: element.in_stock ? "In Stock" : "Out of Stock",
                brand: null,
                in_stock: element.in_stock,
                variants: element.variants,
                categories: [],
                attributes: [],
                customFields: {},
            };
        });
    }
    const breadcrumb = [
        { title: "Home", url: url.home() },
        { title: "Shop", url: url.catalog() },
        { title: productdetaildata.name, url: url.product(productdetaildata) },
    ];

    let content;
    console.log("load data from database", productdetaildata.description);
    if (layout === "sidebar") {
        const sidebar = (
            <div className="shop-layout__sidebar">
                <div className="block block-sidebar">
                    <div className="block-sidebar__item">
                        <WidgetCategories categories={categories} location="shop" />
                    </div>
                    <div className="block-sidebar__item d-none d-lg-block">
                        <WidgetProducts title="Latest Products" products={latestProducts} />
                    </div>
                </div>
            </div>
        );

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === "start" && sidebar}
                    <div className=" shop-layout__content">
                        <div className=" block">
                            <Product product={productdetaildata} productspec={productspec.items} layout={layout} />
                            <ProductTabs
                                withSidebar
                                product={productdetaildata}
                                content={productdetaildata.description}
                                productid={productdetaildata.id}
                            />
                        </div>

                        {!related.loading ? (
                            <BlockProductsCarousel
                                title="Related Products"
                                layout="grid-4-sm"
                                products={related.items}
                                withSidebar
                            />
                        ) : (
                            <BlockLoader />
                        )}
                    </div>
                    {sidebarPosition === "end" && sidebar}
                </div>
            </div>
        );
    } else {
        content = (
            <React.Fragment>
                <div className="block">
                    <div className="container">
                        <Product product={productdetaildata} productspec={productspec.items} layout={layout} />
                        <ProductTabs
                            product={productdetaildata}
                            content={productdetaildata.description}
                            productid={productdetaildata.id}
                        />
                    </div>
                    <div className="container productBannerContainer">
                        {banner.items != "" && banner.items.single_banner != undefined
                            ? Object.entries(banner.items.single_banner).map((value, key) => {
                                  console.log("value data", value);
                                  const sliderImages2 = [
                                      {
                                          // title: 'MegaProcessor',
                                          // text: 'With powerful Samsung Exynos 9611 SoC.',

                                          image_classic: {
                                              ltr: "http://ecom2.cyberframe.in/public/banners/" + value[1],
                                              rtl: "http://ecom2.cyberframe.in/public/banners/" + value[1],
                                          },
                                          image_full: {
                                              ltr: "http://ecom2.cyberframe.in/public/banners/" + value[1],
                                              rtl: "http://ecom2.cyberframe.in/public/banners/" + value[1],
                                          },
                                          image_mobile: {
                                              ltr: "http://ecom2.cyberframe.in/public/banners/" + value[1],
                                              rtl: "http://ecom2.cyberframe.in/public/banners/" + value[1],
                                          },
                                      },
                                  ];

                                  console.log("Single banner coming here to check ", sliderImages2);

                                  return (
                                      <BlockSlideShow
                                          layout="full"
                                          sliderImages={sliderImages2}
                                          slickSettings={sliderSettings}
                                          no_margin
                                      />
                                  );
                              })
                            : ""}

                        {banner.items != "" && banner.items.banner_images != undefined
                            ? Object.entries(banner.items.banner_images).map((value, key) => {
                                  console.log("value data coming here ", value[1], key);
                                  const sliderImages2 = [];

                                  value[1].forEach((val, key) => {
                                      sliderImages2.push({
                                          image_classic: {
                                              ltr: "http://ecom2.cyberframe.in/public/banners/" + val,
                                              rtl: "http://ecom2.cyberframe.in/public/banners/" + val,
                                          },
                                          image_full: {
                                              ltr: "http://ecom2.cyberframe.in/public/banners/" + val,
                                              rtl: "http://ecom2.cyberframe.in/public/banners/" + val,
                                          },
                                          image_mobile: {
                                              ltr: "http://ecom2.cyberframe.in/public/banners/" + val,
                                              rtl: "http://ecom2.cyberframe.in/public/banners/" + val,
                                          },
                                      });
                                  });

                                  console.log("banners List coming here ", sliderImages2);

                                  return (
                                      <BlockSlideShow
                                          layout="full"
                                          sliderImages={sliderImages2}
                                          slickSettings={sliderSettings}
                                          no_margin
                                      />
                                  );
                              })
                            : ""}
                        {banner.items != "" && banner.items.video != undefined
                            ? Object.entries(banner.items.video).map((value, key) => {
                                  console.log("video url data ", value);
                                  return <ReactPlayer url={value[1]} controls={true} width="100%" height="518px" />;
                              })
                            : ""}

                        {/*<BlockSlideShow 
                            layout="full"
                            sliderImages={sliderImages}
                            slickSettings= {sliderSettings}
                            no_margin
                        />
                        {/* <ReactPlayer
                            url='https://m.media-amazon.com/images/S/aplus-media/vc/ff5e65f6-8d11-49e5-9771-ab6ca1139299.mp4' 
                            controls 
                            width="100%"
                            height="518px"
                            style={{background:'black'}}
                            light="https://m.media-amazon.com/images/S/aplus-media/vc/d2ab62d9-d383-4dc9-8130-29df0149b22a.__CR0,0,1464,600_PT0_SX1464_V1___.jpg"
                            playIcon
                        /> */}
                        {/* <BlockSlideShow 
                            layout="full"
                            sliderImages={sliderImages3}
                            slickSettings= {sliderSettings}
                            no_margin
                        />
                        <BlockSlideShow 
                            layout="full"
                            sliderImages={sliderImages4}
                            slickSettings= {sliderSettings}
                            no_margin
                        />
                        <BlockSlideShow 
                            layout="full"
                            sliderImages={sliderImages5}
                            slickSettings= {sliderSettings}
                            no_margin
                        />
                        <BlockSlideShow 
                            layout="full"
                            sliderImages={sliderImages2}
                            slickSettings= {sliderSettings}
                            no_margin
                        />
                        <BlockSlideShow 
                            layout="full"
                            sliderImages={sliderImages6}
                            slickSettings= {sliderSettings}
                            no_margin
                        />
                        <BlockSlideShow 
                            layout="full"
                            sliderImages={sliderImages7}
                            slickSettings= {sliderSettings}
                            no_margin
                        /> */}
                    </div>
                </div>
                {!related.loading ? (
                    related.items.length > 0 ? (
                        <BlockProductsCarousel title="Related Products" layout="grid-5" products={related.items} />
                    ) : (
                        ""
                    )
                ) : (
                    <BlockLoader />
                )}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${productdetaildata.name} — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageProduct.propTypes = {
    /** Product slug. */
    productSlug: PropTypes.string,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageProduct.defaultProps = {
    layout: "standard",
    sidebarPosition: "start",
};

const mapDispatchToProps = {
    getProductDetail,
    getproductDetailVariation,
    getRelatedProduct,
};

const mapStateToProps = (state) => {
    console.log("checking related product state", state.related);
    return {
        productdetail: state.productdetail,
        productspec: state.productadditions,
        related: state.related,
        banner: state.probanner,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ShopPageProduct);
