// react
import React, { useEffect } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// data stubs
import dataAddresses from '../../data/accountAddresses';
import theme from '../../data/theme';
import { getAddresslist, DeleteAddress } from "../../store/address/addressActions"
import BlockLoader from '../blocks/BlockLoader';

 function AccountPageAddresses({getAddresslist,address,DeleteAddress}) {
    useEffect(() => {
        getAddresslist();
     }, []);

   console.log("Address  List ",address.list.items);

   address.list.items  = Array.isArray(address.list.items)?address.list.items:[];
    const addresses = address.list.items.map((address) => (
        <React.Fragment key={address.id}>
            <div className="addresses-list__item card address-card">
                {address.default && <div className="address-card__badge">Default</div>}

                <div className="address-card__body">
                    <div className="address-card__name">{`${address.first_name} ${address.last_name}`}</div>
                    <div className="address-card__row">
                        {address.address1}
                        <br />
                        {address.city}
                        &nbsp;
                        (
                        {address.postcode}
                        )
                        <br />
                        {address.state}
                        ,
                        &nbsp;
                        {address.country}
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Phone Number</div>
                        <div className="address-card__row-content">{address.phone}</div>
                    </div>

                    <div className="address-card__footer">
                        <Link to={"/account/addresses/"+address.id}>Edit </Link>
                        <span onClick={() => {
                            DeleteAddress(address.id)

                        }} style={{'cursor':'pointer'} }>Remove</span>
                    </div>
                </div>
            </div>
            <div className="addresses-list__divider" />
        </React.Fragment>
    ));

    return (
        <div className="addresses-list">
            <Helmet>
                <title>{`Address List — ${theme.name}`}</title>
            </Helmet>
             {address.list.loading?  <div className="addresses-list__item card address-card"> <div className="address-card__body"><BlockLoader/></div></div>:''}
            {!address.list.loading ? <React.Fragment><Link to="/account/addresses/add" className="addresses-list__item addresses-list__item--new">
                <div className="addresses-list__plus" />
                <div className="btn btn-secondary btn-sm">Add New</div>
            </Link>
                <div className="addresses-list__divider" />
                {addresses} </React.Fragment> : ''}
        </div>
    );
}
const mapDispatchToProps = {

    getAddresslist,
    DeleteAddress
};
const mapStateToProps =(state) => {

    return {
    productdetailvariation:state.productvariations,
    address:state.address
  }
}
export default connect(
      mapStateToProps,
      mapDispatchToProps,

 )(AccountPageAddresses);

