import {
    REVIEW_LIST,
    REVIEW_LIST_SUCCESS,
    REVIEW_LIST_FAILED,
    CREATE_REVIEW,
    CREATE_REVIEW_SUCCESS,
    CREATE_REVIEW_FAILED
} from "./reviewActionTypes";





/******Review Reducer ****/
const initReviewstate = {
    loading: 0,
    list:{items:[],meta:{current_page:1,total:1,last_page:1}},
    create: {items:[]},
};
export default function reviewReducer(state = initReviewstate, action) {

    console.log("Review List reducer ",action.type)
    switch (action.type) {
        case REVIEW_LIST:
            
            return { ...state, ...{ loading: true, list:{items: []} } };  

        case REVIEW_LIST_SUCCESS:
           
            return { ...state, ...{ list:{items: action.items.data,meta:action.items.meta}, loading: false  } };

        case REVIEW_LIST_FAILED:
            return { ...state, ...{ list:{items: action.items}, loading: false } };

        case CREATE_REVIEW:
            return { ...state, ...{ loading: true, create:{items: [],loading:true} } };

        case CREATE_REVIEW_SUCCESS:
            return { ...state, ...{ create:{items: action.items}, loading: false  } };

        case CREATE_REVIEW_FAILED:
            return { ...state, ...{ create:{items: action.items}, loading: false } };    

        default:
            return state;
    }
}



