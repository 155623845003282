import { toast } from 'react-toastify';
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES,
    CART_LIST_SUCCESS,
    CART_LIST_FAILED,
    COUPON_ADDED,
    COUPON_ADDED_SUCCESS,
    COUPON_ADDED_FAILED,
    COUPON_DELETE,
    COUPON_DELETE_SUCCESS,
    COUPON_DELETE_FAILED,
    CART_LIST_REQUEST} from './cartActionTypes';
import {cartPoint} from './endpoint'

import { frontUrl,Logintoken } from "../../common";
import {authCheckNull} from "../../common/AuthCheck"

export function cartAddItemSuccess(product, options = [], quantity = 1) {
    toast.success(`Product "${product.name}" added to cart!`);
    return {
        type: CART_ADD_ITEM,
        product,
        options,
        quantity,
    };
}

export function cartRemoveItemSuccess(itemId) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}




export function cartAddItem(product, options = [], quantity = 1) {
    const logintoken = (!authCheckNull() ? '?token=' + Logintoken() : '');
    if(logintoken == '' || logintoken == null){

        var configuration =[];
        configuration= [{
            quantity: quantity,
            is_configurable:false,
            productid: product.id
        }]
        localStorage.setItem("configuration", JSON.stringify(configuration));

        localStorage.setItem("current_page", window.location.href);
        setTimeout(() => {
        window.location.href=frontUrl+"/account/login";
        }, 1000)
    }
    else{
        return (dispatch) => (
            new Promise((resolve) => {
                var configurable = product.variants?.length>0 ? true:false;
                var split_options = product.sku.split('-variant-');
                var configuration ={};
                console.log("Product inserted data coming here ",product);

                if(localStorage.getItem("configuration")){
                    var getconfiguration = localStorage.getItem("configuration");
                    var configurationJson = JSON.parse(getconfiguration)
                    configuration={
                        quantity: configurationJson.quantity, 
                        is_configurable:false,
                        product_id: configurationJson.product_id
                    }
                }

                else{
                    if(product.variants && product.variants?.length > 0)
                    {
                        configuration= {product_id: product.id, quantity,is_configurable:true, "selected_configurable_option": product.selectedvariations,
                                                  'super_attribute':{...product.variations}}
                    }
                    else
                    {
                       configuration= {
                            quantity: quantity, 
                            is_configurable:false,
                            product_id: product.id
                        }
                    }
                }

                setTimeout(() => {
                    console.log("Product inserted",product);
                    fetch(cartPoint.add+product.id+logintoken,{
                        method: "POST",
                        mode: "cors",
                        cache: "no-cache",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        redirect: "follow",
                        referrerPolicy: "no-referrer",
                        body: JSON.stringify(configuration)
                    })
                    
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        if (response.error != undefined) {
                            console.log("Qunatity check error coming here");
                            toast.error(response.error.message);
                        }
                        else
                        {
                            dispatch(cartAddItemSuccess(product, options, quantity));
                            dispatch(cartItemListGet());
                        }
                        resolve();
                    })
                    .catch(function (thrown) {
                       toast.error(thrown.message);
                    });
                }, 500);
            })
        );
    }
}



export function cartRemoveItem(productid) {
    // sending request to server, timeout is used as a stub

    const logintoken = (!authCheckNull()?'?token='+Logintoken():'');
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {

                fetch(cartPoint.delete+productid+logintoken)
                .then(function (response) {
                    return response.json();
                })
                .then(function (response) {

                    console.log("response coming here",response)
                    dispatch(cartRemoveItemSuccess(productid));
                        localStorage.removeItem("state");  
                        localStorage.removeItem("save_address_response");
                    dispatch(cartItemListGet())
                    resolve();
                    //return response;
                })
                .catch(function (thrown) {
                   toast.error(thrown.message);
                });

            }, 500);
        })
    );
}

export function cartUpdateQuantities(data) {


    console.log("cart update data coming here",data);

     let cartupdate = {"qty":{}};
    data.forEach((value)=>{

        cartupdate.qty={ ...cartupdate.qty,...{[value.itemId]:value.value}}

    })

    // sending request to server, timeout is used as a stub
    const logintoken = (!authCheckNull()?'?token='+Logintoken():'');
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {

                dispatch({ type: CART_LIST_REQUEST});
                fetch(cartPoint.update+logintoken,{

                    method: "PUT", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    // include, *same-origin, omit
                    headers: {
                        "Content-Type": "application/json",
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(cartupdate)
                  })
                .then(function (response) {
                    return response.json();
                })
                .then(function (response) {

                    console.log("response coming here",response)
                    toast.success(response.message);

                    dispatch(cartItemListGet())
                    resolve();

                    //return response;
                })
                .catch(function (thrown) {
                    toast.error("product Quantity exceeds the available stock.");
                    dispatch(cartItemListGet());
                });


            }, 500);
        })
    );
}


export function cartListItemSuccess(items) {
    return {
        type: CART_LIST_SUCCESS,
        items: items.data.items != undefined ? items.data.items : [],
        subtotal: items.data.base_sub_total,
        subtotal: items.data.base_sub_total,
        grandtotal: items.data.grand_total,
        coupon_code: items.data.coupon_code,
        discount: items.data.base_discount,
        cartdata:items.data
    };
}

export function cartListItemFailed(error) {
    return {
        type: CART_LIST_FAILED,
        error,
        subtotal:0.0,
        grandtotal:0.0,
        coupon_code:null
    };
}

export function cartItemListGet()
{
    const logintoken = (!authCheckNull()?'?token='+Logintoken():'');
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {

                fetch(cartPoint.list+logintoken)
                .then(function (response) {
                    return response.json();
                })
                .then(function (response) {

                    console.log("response coming here",response)
                    dispatch(cartListItemSuccess(response));
                    resolve();
                    //return response;
                })
                .catch(function (thrown) {
                   ///toast.error(thrown.message);
                   dispatch(cartListItemFailed(thrown));
                });

            }, 500);
        })
    );

}


/****** Cart Coupon Check  ******/

export function couponAddedSuccess(items) {
    localStorage.setItem('coupon',this.state.couponcode)
    return {
        type: COUPON_ADDED_SUCCESS,
        items
    };
}

export function couponAddedFailed(error) {
    return {
        type: COUPON_ADDED_FAILED,
        error,
        loading:false
    };
}

export function addCoupon(data)
{
    const logintoken = (!authCheckNull()?'?token='+Logintoken():'');
    return (dispatch) => (
        new Promise((resolve) => {
            dispatch({ type: COUPON_ADDED,
                items:[],loading:true})
            setTimeout(() => {

                fetch(cartPoint.couponAdd+logintoken,{

                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    // include, *same-origin, omit
                    headers: {
                        "Content-Type": "application/json",
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(data)
                  })
                .then(function (response) {
                    return response.json();
                })
                .then(function (response) {

                    console.log("response coming here",response)

                    if(response.success==false){
                        toast.error(response.message);
                        dispatch(couponAddedFailed(response));
                        dispatch(cartItemListGet());
                    }else
                    {
                        toast.success("Coupon is added successfully!");
                        console.log("coupon data added  here");
                        dispatch(cartItemListGet());
                        dispatch(couponAddedSuccess(response));


                    }





                    resolve();
                    //return response;
                })
                .catch(function (thrown) {
                   ///toast.error(thrown.message);
                   dispatch({type: COUPON_ADDED_FAILED,
                    error:"coupon failed to add",
                    loading:false  })
                });

            }, 500);
        })
    );

}

    /****** remove coupon detail Coming here ****/
    export function RemoveCoupon(coponcode)
    {


        const logintoken = (!authCheckNull()?'?token='+Logintoken():'');
        return (dispatch) => (
            new Promise((resolve) => {
                dispatch({ type: COUPON_ADDED,
                    items:[],loading:true})
                setTimeout(() => {

                    fetch(cartPoint.couponAdd+logintoken,{

                        method: "DELETE", // *GET, POST, PUT, DELETE, etc.
                        mode: "cors", // cors, *cors, same-origin
                        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                        // include, *same-origin, omit
                        headers: {
                            "Content-Type": "application/json",
                            // 'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        redirect: "follow", // manual, *follow, error
                        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url

                    })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {

                        console.log("response coming here",response)

                        if(!response.status){
                            toast.success(response.message);
                            dispatch(couponAddedFailed(response));
                        }else
                        {
                            toast.success("Coupon is Deleted successfully!");
                            dispatch(couponAddedSuccess(response));
                        }
                        dispatch(cartItemListGet());

                        resolve();
                        //return response;
                    })
                    .catch(function (thrown) {
                    ///toast.error(thrown.message);
                    toast.error('Some server error occures');
                    dispatch({type: COUPON_ADDED_FAILED,
                        error:"coupon failed to add",
                        loading:false  })
                    });

                }, 500);
            })
        );
    }
