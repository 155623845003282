import {
    CHECKOUT_SAVE_ADDRESS,
    CHECKOUT_SAVE_ADDRESS_SUCCESS,
    CHECKOUT_SAVE_SHIPPING_METHOD,
    CHECKOUT_SAVE_ADDRESS_FAILED,
    CHECKOUT_SAVE_SHIPPING_METHOD_FAILED,
    CHECKOUT_SAVE_SHIPPING_METHOD_SUCCESS,
    CHECKOUT_SAVE_ORDER_METHOD_FAILED,
    CHECKOUT_SAVE_ORDER_METHOD_SUCCESS,
    CHECKOUT_SAVE_PAYMENT_METHOD_SUCCESS,
    CHECKOUT_SAVE_PAYMENT_METHOD_FAILED,
    CHECKOUT_SAVE_ORDER_METHOD,
    CHECKOUT_SAVE_PAYMENT_METHOD,
    PAYMENT_METHOD_LIST_GET,
    PAYMENT_METHOD_LIST_GET_SUCCESS,
    PAYMENT_METHOD_LIST_GET_FAILED,
    SHIPPING_LIST_GET,
    SHIPPING_LIST_GET_SUCCESS,
    SHIPPING_LIST_GET_FAILED,
} from "./checkoutActionTypes";

const initialState = {
    lastItemId: 0,
    quantity: 0,
    items: [],
    minRateAmongMinDays: [],
    minRateShipping: [],
    subtotal: 0,
    extraLines: [
        // shipping, taxes, fees, .etc
        {
            type: "shipping",
            title: "Shipping",
            price: 25,
        },
        {
            type: "tax",
            title: "Tax",
            price: 0,
        },
    ],
    total: 0,
};

export default function checkoutReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}

/******Save Address Reducer checkout ****/
const initaddresstate = {
    loading: 0,
    addressSaved: false,
    items: [],
};
export function SaveAddressReducer(state = initaddresstate, action) {
    switch (action.type) {
        case CHECKOUT_SAVE_ADDRESS:
            return { ...state, ...{ loading: true, items: [], addressSaved: false } };

        case CHECKOUT_SAVE_ADDRESS_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false, addressSaved: true } };

        case CHECKOUT_SAVE_ADDRESS_FAILED:
            return { ...state, ...{ items: action.items, loading: false, addressSaved: false } };

        default:
            return state;
    }
}

/******Save shipping Reducer checkout ****/
const initshipstate = {
    loading: 0,

    items: [],
};
export function SaveShippingReducer(state = initshipstate, action) {
    switch (action.type) {
        case CHECKOUT_SAVE_SHIPPING_METHOD:
            return { ...state, ...{ loading: true, items: [] } };

        case CHECKOUT_SAVE_SHIPPING_METHOD_SUCCESS:
            return { ...state, ...{ items: action.list, loading: false } };

        case CHECKOUT_SAVE_SHIPPING_METHOD_FAILED:
            return { ...state, ...{ items: action.list, loading: false } };

        default:
            return state;
    }
}

/******Save Payment Reducer checkout ****/
const initpaymentstate = {
    loading: 0,

    items: [],
};
export function SavePaymentMethodReducer(state = initpaymentstate, action) {
    switch (action.type) {
        case CHECKOUT_SAVE_PAYMENT_METHOD:
            return { ...state, ...{ loading: true, items: [] } };

        case CHECKOUT_SAVE_PAYMENT_METHOD_SUCCESS:
            return { ...state, ...{ items: action.list, loading: false } };

        case CHECKOUT_SAVE_PAYMENT_METHOD_FAILED:
            return { ...state, ...{ items: action.list, loading: false } };

        default:
            return state;
    }
}

/******Save Payment Reducer checkout ****/
const initOrderstate = {
    loading: 0,

    items: [],
};
export function SaveOrderMethodReducer(state = initOrderstate, action) {
    switch (action.type) {
        case CHECKOUT_SAVE_ORDER_METHOD:
            return { ...state, ...{ loading: true, items: [] } };

        case CHECKOUT_SAVE_ORDER_METHOD_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false } };

        case CHECKOUT_SAVE_ORDER_METHOD_FAILED:
            return { ...state, ...{ items: action.items, loading: false } };

        default:
            return state;
    }
}

/******Payment Methods List Reducer ****/
const initpaymentmethodstate = {
    loading: 0,
    items: [],
};
export function PaymentMethodListReducer(state = initpaymentmethodstate, action) {
    switch (action.type) {
        case PAYMENT_METHOD_LIST_GET:
            return { ...state, ...{ loading: true, items: [] } };

        case PAYMENT_METHOD_LIST_GET_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false } };

        case PAYMENT_METHOD_LIST_GET_FAILED:
            return { ...state, ...{ items: [], loading: false } };

        default:
            return state;
    }
}

/******Payment Methods List Reducer ****/
const initShippingListstate = {
    loading: 0,
    items: [],
    minRateShipping: [],
    minRateAmongMinDays: [],
};
export function shippingListReducer(state = initShippingListstate, action) {
    switch (action.type) {
        case SHIPPING_LIST_GET:
            return { ...state, ...{ loading: true, items: [] } };

        case SHIPPING_LIST_GET_SUCCESS:
            return {
                ...state,
                ...{
                    items: action.items,
                    minRateShipping: action.minRateShipping,
                    minRateAmongMinDays: action.minRateAmongMinDays,
                    loading: false,
                },
            };

        case SHIPPING_LIST_GET_FAILED:
            return { ...state, ...{ items: [], loading: false } };

        default:
            return state;
    }
}
