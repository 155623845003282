// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {url} from '../../services/utils'

// application
import BlockHeader from '../shared/BlockHeader';
import { connect } from 'react-redux';

function BlockCategories(props) {
    const { title, layout, categories,categorylist, submenucat } = props;

  let categorylistdata = [];
      submenucat.items.forEach(element => {
        if( avoidCategory(element.slug) ){
        categorylistdata.push({
             
        title: element.name,
        // url: '/shop',
        products: element.id,
        slug:element.slug,
        // image: 'images/categories/category-1.jpg',
        image: element.image_url,
        subcategories: [
            // { title: 'Screwdrivers', url: '/shop' },
            // { title: 'Milling Cutters', url: '/shop' },
            // { title: 'Sanding Machines', url: '/shop' },
            // { title: 'Wrenches', url: '/shop' },
            // { title: 'Drills', url: '/shop' },
        ],
    })
    }
    });


    console.log("Catagories list",categories);
    const categoriesList = categorylistdata.map((category, index) => {
        const classes = `block-categories__item category-card category-card--layout--${layout}`;

        const subcategories = category.subcategories.map((sub, subIndex) => (
            <li key={subIndex}>
                <Link to={sub.slug}>{sub.title}</Link>
            </li>
        ));

        return (
            <div key={index} className={classes}>
                <div className=" category-card__body">
                    <div className=" category-card__image">
                        <Link to={url.category(category)}><img src={category.image} alt="" /></Link>
                    </div>
                    { layout!=='images' && <div className=" category-card__content">
                        <div className=" category-card__name">
                            <Link to={url.category(category)}>{category.title}</Link>
                        </div>
                        <ul className="category-card__links">
                            {subcategories}
                        </ul>
                        <div className="category-card__all">
                            <Link to={url.category(category)}>Show All</Link>
                        </div>
                        <div className="category-card__products">
                            {`${category.products} Products`}
                        </div>
                    </div> }
                </div>
            </div>
        );
    });

    return (
        <div className={`block block--highlighted block-categories block-categories--layout--${layout}`}>
            <div className="container">
                <BlockHeader title={title} />

                <div className="block-categories__list">
                    {categoriesList}
                </div>
            </div>
        </div>
    );
}

function avoidCategory(catSlug){
    if (catSlug === 'root')
        return false;
    // if (catSlug === 'tube')
    //     return false;
    return true;
}
BlockCategories.propTypes = {
    title: PropTypes.string.isRequired,
    categories: PropTypes.array,
    layout: PropTypes.oneOf(['classic', 'compact']),
};

BlockCategories.defaultProps = {
    categories: [],
    layout: 'classic',
};


const mapStateToProps =(state) => {
    console.log("state banner",state);
    return {
    categorylist:state.categories, 
    submenucat: state.submenucat  
  }
}
export default connect(mapStateToProps)(BlockCategories);