export default [
    {
        id: 1,
        title: 'Unique Note 20 Ultra specs and features, from Victus drop test to wireless DeX',
        image: 'images/posts/post1.png',
        categories: ['Special Offers'],
        date: 'April 19, 2020',
    },
    {
        id: 2,
        title: 'Samsung Galaxy Buds Live review',
        image: 'images/posts/post2.png',
        categories: ['Latest News'],
        date: 'August 5, 2020',
    },
    {
        id: 3,
        title: 'Samsung Galaxy Note 20 5G and Galaxy Note 20 Ultra 5G release date',
        image: 'images/posts/post3.png',
        categories: ['New Arrivals'],
        date: 'August 12, 2020',
    },
    {
        id: 4,
        title: 'The LG Velvet focuses on what matters to be the best phone for you',
        image: 'images/posts/post4.png',
        categories: ['Special Offers'],
        date: 'July 30, 2020',
    },
    // {
    //     id: 5,
    //     title: 'Germany Was The First Country To Professionalize Philosophy',
    //     image: 'images/posts/post-5.jpg',
    //     categories: ['New Arrivals'],
    //     date: 'June 12, 2019',
    // },
    // {
    //     id: 6,
    //     title: 'Logic Is The Study Of Reasoning And Argument Part 1',
    //     image: 'images/posts/post-6.jpg',
    //     categories: ['Special Offers'],
    //     date: 'May 21, 2019',
    // },
    // {
    //     id: 7,
    //     title: 'Many Inquiries Outside Of Academia Are Philosophical In The Broad Sense',
    //     image: 'images/posts/post-7.jpg',
    //     categories: ['Special Offers'],
    //     date: 'April 3, 2019',
    // },
    // {
    //     id: 8,
    //     title: 'An Advantage Of Digital Circuits When Compared To Analog Circuits',
    //     image: 'images/posts/post-8.jpg',
    //     categories: ['Latest News'],
    //     date: 'Mart 29, 2019',
    // },
    // {
    //     id: 9,
    //     title: 'A Digital Circuit Is Typically Constructed From Small Electronic Circuits',
    //     image: 'images/posts/post-9.jpg',
    //     categories: ['New Arrivals'],
    //     date: 'February 10, 2019',
    // },
    // {
    //     id: 10,
    //     title: 'Engineers Use Many Methods To Minimize Logic Functions',
    //     image: 'images/posts/post-10.jpg',
    //     categories: ['Special Offers'],
    //     date: 'January 1, 2019',
    // },
];
