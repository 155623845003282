import React, { lazy, Suspense } from 'react';

const LazyAdvanceSearch = lazy(() => import('./AdvanceSearch'));

const AdvanceSearch = props => (
  <Suspense fallback={null}>
    <LazyAdvanceSearch {...props} />
  </Suspense>
);

export default AdvanceSearch;
