import { ApiEndpointUrl } from "../../common";
export const productPoint = {
    list: ApiEndpointUrl + "products",
    additions: ApiEndpointUrl + "product-additional-information",
    variations: ApiEndpointUrl + "product-configurable-config",
    filters: ApiEndpointUrl + "getattributes",
    relatedpro: ApiEndpointUrl + "relatedProducts",
    bannerdata: ApiEndpointUrl + "bannerdata",
};
export const brandPoint = ApiEndpointUrl + "brands";
export const sizePoint = ApiEndpointUrl + "sizes";
