import {
    CATEGORY_LIST,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_LIST_FAILED,
    SUB_CATEGORY_LIST_SUCCESS,
    SUB_CATEGORY_LIST_FAILED,
    SUB_CATEGORY_LIST,
    SUB_MENU_CATEGORY_LIST_SUCCESS,
    SUB_MENU_CATEGORY_LIST_FAILED,
    SUB_MENU_CATEGORY_LIST,
    PARENTS_SUB_MENU_CATEGORY_LIST,
    PARENTS_SUB_MENU_CATEGORY_LIST_SUCCESS,
    PARENTS_SUB_MENU_CATEGORY_LIST_FAILED,
} from "./categoryActionTypes";
import queryString from "query-string";
/*
 * item example:
 * {
 *   id: 1,
 *   product: {...}
 *   options: [
 *     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
 *   ],
 *   price: 250,
 *   quantity: 2,
 *   total: 500
 * }
 * extraLine example:
 * {
 *   type: 'shipping',
 *   title: 'Shipping',
 *   price: 25
 * }
 */
const initialState = {
    loading: 0,
    items: [],
};

export default function categoryReducer(state = initialState, action) {
    switch (action.type) {
        case CATEGORY_LIST:
            return { ...state, action };
        case CATEGORY_LIST_SUCCESS:
            return { ...state, ...{ items: action.items } };
        case CATEGORY_LIST_FAILED:
            return { ...state, ...{ items: action.items } };

        default:
            return state;
    }
}

const initialSubState = {
    loading: 0,
    items: [],
};

export function categorySubReducer(state = initialSubState, action) {
    switch (action.type) {
        case SUB_CATEGORY_LIST:
            return { ...state, action };
        case SUB_CATEGORY_LIST_SUCCESS:
            return { ...state, ...{ items: action.items } };
        case SUB_CATEGORY_LIST_FAILED:
            return { ...state, ...{ items: action.items } };

        default:
            return state;
    }
}


export function categorySubMenuReducer(state = initialSubState, action) {
    switch (action.type) {
        case SUB_MENU_CATEGORY_LIST:
            return { ...state, action };
        case SUB_MENU_CATEGORY_LIST_SUCCESS:
            return { ...state, ...{ items: action.items } };
        case SUB_MENU_CATEGORY_LIST_FAILED:
            return { ...state, ...{ items: action.items } };

        default:
            return state;
    }
}

export function categoryParentSubMenuReducer(state = initialSubState, action) {
    switch (action.type) {
        case PARENTS_SUB_MENU_CATEGORY_LIST:
            return { ...state, action };
        case PARENTS_SUB_MENU_CATEGORY_LIST_SUCCESS:
            return { ...state, ...{ items: action.items } };
        case PARENTS_SUB_MENU_CATEGORY_LIST_FAILED:
            return { ...state, ...{ items: action.items } };

        default:
            return state;
    }
}


export const initialFilterState = {
    init: false,
    /**
     * Indicates that the category is loading.
     */
    categoryIsLoading: true,
    /**
     * Category object.
     */
    category: null,
    /**
     * Indicates that the products list is loading.
     */
    productsListIsLoading: true,
    /**
     * Products list.
     */
    productsList: null,
    /**
     * Products list options.
     *
     * options.page:  number - Current page.
     * options.limit: number - Items per page.
     * options.sort:  string - Sort algorithm.
     */
    options: {},
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},
};

export function FilterReducer(state = initialFilterState, action) {
    switch (action.type) {
        case "FETCH_CATEGORY_SUCCESS":
            return {
                ...state,
                init: true,
                categoryIsLoading: false,
                category: action.category,
            };
        case "FETCH_PRODUCTS_LIST":
            return { ...state, productsListIsLoading: true };
        case "FETCH_PRODUCTS_LIST_SUCCESS":
            return { ...state, productsListIsLoading: false, productsList: action.productsList };
        case "SET_OPTION_VALUE":
            return {
                ...state,
                options: { ...state.options, page: 1, [action.option]: action.value },
            };
        case "SET_FILTER_VALUE":
            return {
                ...state,
                options: { ...state.options, page: 1 },
                filters: { ...state.filters, [action.filter]: action.value },
            };
        case "RESET_FILTERS":
            return { ...state, options: { ...state.options, page: 1 }, filters: {} };
        case "RESET":
            return state.init ? initialState : state;
        default:
            return state;
    }
}

function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};

    if (typeof query.page === "string") {
        optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === "string") {
        optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === "string") {
        optionValues.sort = query.sort;
    }

    return optionValues;
}

function parseQueryFilters(location) {
    const query = queryString.parse(location);
    const filterValues = {};

    Object.keys(query).forEach((param) => {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            return;
        }

        const filterSlug = mr[1];

        filterValues[filterSlug] = query[param];
    });

    return filterValues;
}

function parseQuery(location) {
    return [parseQueryOptions(location), parseQueryFilters(location)];
}

export function init(state) {
    const [options, filters] = parseQuery(window.location.search);

    return { ...state, options, filters };
}
