// react
import React, { Fragment, useEffect, useState } from "react";

// application
import SocialLinks from "../shared/SocialLinks";
import { contactSubscribeRequest } from "../../store/page";
import { connect } from "react-redux";
import classNames from "classnames";
import { toast } from "react-toastify";

export function FooterNewsletter({ contactrequest, contact }) {
    const [contactform, setContactForm] = useState({
        email: "",
    });

    const keyUpEvent = (event) => {
        setContactForm({
            ...contactform,
            ...{ [event.target.name]: event.target.value },
        });
    };
    /******* contact Action Request */
    var contactrequestAction = (event) => {
        event.preventDefault();
        var error = false;
        if (document.getElementById("mc-form-email").value == "") {
            toast.error("Please Enter Email");
            return false;
        }
        contactrequest(contactform);
        document.getElementById("mc-form-email").value = "";
    };
    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title">Newsletter</h5>
            {/* <div className="footer-newsletter__text">
                Praesent pellentesque volutpat ex, vitae auctor lorem pulvinar mollis felis at lacinia.
            </div> */}

            <form action="" className="footer-newsletter__form">
                <label className="sr-only" htmlFor="footer-newsletter-address">
                    Email Address
                </label>
                <input
                    type="text"
                    className="footer-newsletter__form-input form-control"
                    id="mc-form-email"
                    placeholder="Email Address..."
                    name="email"
                    onKeyUp={keyUpEvent}
                    placeholder="Enter your email address..."
                />
                <button
                    type="submit"
                    className={classNames("footer-newsletter__form-button btn btn-primary", {
                        "btn-loading": contact.loading,
                    })}
                    onClick={contactrequestAction}
                >
                    Subscribe
                </button>
            </form>

            <div className="footer-newsletter__text footer-newsletter__text--social">Follow us on social networks</div>

            <SocialLinks className="footer-newsletter__social-links" shape="circle" />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        contact: state.contact,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        contactrequest: (data) => {
            dispatch(contactSubscribeRequest(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterNewsletter);
