export default [
    {
        title: 'Samsung',
         url: "",
        submenu: {
            type: 'megamenu',
            menu: {
                size: 'xl',
                image: {
                    ltr: 'images/megamenu/megamenu-1-ltr.jpg',
                    rtl: 'images/megamenu/megamenu-1-rtl.jpg',
                },
                columns: [
                    {
                        size: 3,
                        links: [
                            {
                                title: 'Mobile & Tablets',
                                 url: "",
                                links: [
                                    { title: 'Samsung Galaxy M21',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
                                    { title: 'Samsung Galaxy Note10',  url: "/shop/products-listing?product=Samsung Galaxy Note10" },
                                    { title: 'Samsung Galaxy Note 20',  url: "/shop/products-listing?product=Samsung Galaxy Note 20" },
                                    { title: 'Samsung Galaxy S10',  url: "/shop/products-listing?product=Samsung Galaxy S10" },
                                ],
                            },
                            { title: 'Tab 10',  url: "/shop/products-listing?product=Tab 10", links: [] },
                            { title: 'Samsung Notebook',  url: "/shop/products-listing?product=Samsung Notebook", links: [] },
                            { title: 'Samsung Watch',  url: "/shop/products-listing?product=Samsung Watch", links: [] },
                            // { title: 'Riveters',  url: "/shop/products-listing?product=Samsung Galaxy M21", links: [] },
                        ],
                    },
                    {
                        size: 3,
                        links: [
                            {
                                title: 'Washing Machines',
                                 url: "",
                                links: [
                                    { title: 'Front Lead 1190',  url: "/shop/products-listing?product=Front Lead 1190" },
                                    { title: 'Top Loading 3984',  url: "/shop/products-listing?product=Top Loading 3984" },
                                    { title: 'Top Loading 27x82',  url: "/shop/products-listing?product=Top Loading 27x82" },
                                    { title: 'Fully Automatic',  url: "/shop/products-listing?product=Fully Automatic" },
                                    { title: 'Semi-Automatic',  url: "/shop/products-listing?product=Semi-Automatic" },
                                    { title: 'Front Loading with EcoBubble',  url: "/shop/products-listing?product=Front Loading with EcoBubble" },
                                ],
                            },
                            {
                                title: 'Air Conditioner',
                                 url: "",
                                links: [
                                    { title: 'Split AC',  url: "/shop/products-listing?product=Split AC" },
                                    { title: 'Window AC',  url: "/shop/products-listing?product=Window AC" },
                                    { title: 'Inverter Split AC',  url: "/shop/products-listing?product=Inverter Split AC" },
                                    // { title: 'Brush Cutters',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
                                ],
                            },
                        ],
                    },
                    {
                        size: 3,
                        links: [
                            {
                                title: 'LEDs / TVs',
                                 url: "",
                                links: [
                                    { title: 'Smart HD TV',  url: "/shop/products-listing?product=Smart HD TV" },
                                    { title: '4k Smart Crystal UHD TV',  url: "/shop/products-listing?product=4k Smart Crystal UHD TV" },
                                    { title: '8k Smart QLED TV',  url: "/shop/products-listing?product=8k Smart QLED TV" },
                                    { title: '4k Smart QLED TV',  url: "/shop/products-listing?product=4k Smart QLED TV" },
                                    { title: 'Curved Smart QLED TV',  url: "/shop/products-listing?product=Curved Smart QLED TV" },
                                ],
                            },
                        ],
                    },
                    {
                        size: 3,
                        links: [
                            {
                                title: 'Micro Wave',
                                 url: "/shop/products-listing?product=Samsung Galaxy M21",
                                links: [
                                    { title: 'Convection MWO',  url: "/shop/products-listing?product=Convection MWO" },
                                    { title: 'MC28M6036CC Convection',  url: "/shop/products-listing?product=MC28M6036CC Convection" },
                                    { title: 'Convection Slim Fry',  url: "/shop/products-listing?product=Convection Slim Fry" },
                                    { title: 'Sun Dry',  url: "/shop/products-listing?product=Sun Dry" },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        title: 'MI (Xiaomi)',
         url: "",
        // submenu: {
        //     type: 'megamenu',
        //     menu: {
        //         size: 'lg',
        //         image: {
        //             ltr: 'images/megamenu/megamenu-2-ltr.jpg',
        //             rtl: 'images/megamenu/megamenu-2-rtl.jpg',
        //         },
        //         columns: [
        //             {
        //                 size: 4,
        //                 links: [
        //                     {
        //                         title: 'Hand Tools',
        //                          url: "/shop/products-listing?product=Samsung Galaxy M21",
        //                         links: [
        //                             { title: 'Screwdrivers',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Handsaws',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Knives',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Axes',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Multitools',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Paint Tools',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                         ],
        //                     },
        //                     {
        //                         title: 'Garden Equipment',
        //                          url: "/shop/products-listing?product=Samsung Galaxy M21",
        //                         links: [
        //                             { title: 'Motor Pumps',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Chainsaws',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Electric Saws',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Brush Cutters',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                         ],
        //                     },
        //                 ],
        //             },
        //             {
        //                 size: 4,
        //                 links: [
        //                     {
        //                         title: 'Machine Tools',
        //                          url: "/shop/products-listing?product=Samsung Galaxy M21",
        //                         links: [
        //                             { title: 'Thread Cutting',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Chip Blowers',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Sharpening Machines',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Pipe Cutters',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Slotting machines',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Lathes',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                         ],
        //                     },
        //                 ],
        //             },
        //             {
        //                 size: 4,
        //                 links: [
        //                     {
        //                         title: 'Instruments',
        //                          url: "/shop/products-listing?product=Samsung Galaxy M21",
        //                         links: [
        //                             { title: 'Welding Equipment',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Power Tools',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Hand Tools',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Measuring Tool',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                         ],
        //                     },
        //                 ],
        //             },
        //         ],
        //     },
        // },
    },
    {
        title: 'OPPO',
         url: "",
        // submenu: {
        //     type: 'megamenu',
        //     menu: {
        //         size: 'nl',
        //         image: {
        //             ltr: 'images/megamenu/megamenu-3-ltr.jpg',
        //             rtl: 'images/megamenu/megamenu-3-rtl.jpg',
        //         },
        //         columns: [
        //             {
        //                 size: 6,
        //                 links: [
        //                     {
        //                         title: 'Hand Tools',
        //                          url: "/shop/products-listing?product=Samsung Galaxy M21",
        //                         links: [
        //                             { title: 'Screwdrivers',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Handsaws',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Knives',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Axes',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Multitools',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Paint Tools',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                         ],
        //                     },
        //                     {
        //                         title: 'Garden Equipment',
        //                          url: "/shop/products-listing?product=Samsung Galaxy M21",
        //                         links: [
        //                             { title: 'Motor Pumps',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Chainsaws',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Electric Saws',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Brush Cutters',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                         ],
        //                     },
        //                 ],
        //             },
        //             {
        //                 size: 6,
        //                 links: [
        //                     {
        //                         title: 'Instruments',
        //                          url: "/shop/products-listing?product=Samsung Galaxy M21",
        //                         links: [
        //                             { title: 'Welding Equipment',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Power Tools',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Hand Tools',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Measuring Tool',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                         ],
        //                     },
        //                 ],
        //             },
        //         ],
        //     },
        // },
    },
    {
        title: 'Sony',
         url: "",
        // submenu: {
        //     type: 'megamenu',
        //     menu: {
        //         size: 'sm',
        //         columns: [
        //             {
        //                 size: 12,
        //                 links: [
        //                     {
        //                         title: 'Hand Tools',
        //                          url: "/shop/products-listing?product=Samsung Galaxy M21",
        //                         links: [
        //                             { title: 'Screwdrivers',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Handsaws',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Knives',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Axes',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Multitools',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Paint Tools',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                         ],
        //                     },
        //                     {
        //                         title: 'Garden Equipment',
        //                          url: "/shop/products-listing?product=Samsung Galaxy M21",
        //                         links: [
        //                             { title: 'Motor Pumps',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Chainsaws',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Electric Saws',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                             { title: 'Brush Cutters',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                         ],
        //                     },
        //                 ],
        //             },
        //         ],
        //     },
        // },
    },
    {
        title: 'Whirlpool',
         url: "",
        // submenu: {
        //     type: 'menu',
        //     menu: [
        //         {
        //             title: 'Soldering Equipment',
        //              url: "/shop/products-listing?product=Samsung Galaxy M21",
        //             submenu: [
        //                 { title: 'Soldering Station',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                 { title: 'Soldering Dryers',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                 { title: 'Gas Soldering Iron',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //                 { title: 'Electric Soldering Iron',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //             ],
        //         },
        //         { title: 'Light Bulbs',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //         { title: 'Batteries',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //         { title: 'Light Fixtures',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //         { title: 'Warm Floor',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //         { title: 'Generators',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //         { title: 'UPS',  url: "/shop/products-listing?product=Samsung Galaxy M21" },
        //     ],
        // },
    },
    { title: 'LG',  url: "/shop/products-listing?product_brand=LG", submenu: null },
    { title: 'Realme',  url: "/shop/products-listing?product_brand=Realme", submenu: null },
    { title: 'Carrier',  url: "/shop/products-listing?product_brand=Carrier", submenu: null },
    { title: 'Hitachi',  url: "/shop/products-listing?product_brand=Hitachi", submenu: null },
    { title: 'Daikin',  url: "/shop/products-listing?product_brand=Daikin", submenu: null },
    { title: 'Bosch',  url: "/shop/products-listing?product_brand=Bosch", submenu: null },
];
