// react
import React from 'react';
import { connect } from 'react-redux';
// data stubs
import specification from '../../data/shopProductSpec';

function ProductTabSpecification({productadditions}) {

    console.log("Product addtionals",productadditions.items)
    const specification = [{name:'Technical Details',features:productadditions.items}];
    let skipSpecKeys = ['short_description','description','url_key','name','sku',
                        'status','visible_individually','tax_category_id','price',
                    'cost','special_price','special_price_from','special_price_to',
                  'meta_title','meta_keywords','meta_description','guest_checkout',
                'Item_weight','Item_dimensions_lxWxH','Capacity','included_components',
                 'Product']
    let sections ='';
    if(productadditions.items.length>0){
            sections = specification.map((section, index) => {
                console.log("Feature products",section.features[0]['admin_name'])
              
                const features = section.features.map((feature, index) => {
                    let val = feature.value;
                    console.log("key value comes here ",feature['admin_name'],val);
                     if(skipSpecKeys.includes(feature['code']) || feature['value'] ==='' || feature['value'] ===null)
                     {
                         return(<span></span>)
                     }
                    return(   <div key={index} className={"spec__row "+feature}>
                        <div className="spec__name ">{feature['admin_name']}</div>
                        <div className="spec__name " dangerouslySetInnerHTML={{__html: val}}></div>
                    </div>)  
                });

                return (
                    <div key={index} className="spec__section">
                        <h4 className="spec__section-title">{section.name}</h4>
                   {features}
                    </div>
                );
            });
}


    return (
        <div className="spec">
            <h3 className="spec__header">Specification</h3>
            {sections}
            <div className="spec__disclaimer">
                Information on technical characteristics, the delivery set, the country of
                manufacture and the appearance of the goods is for reference only and is based on
                the latest information available at the time of publication.
            </div>
        </div>
    );
}



const mapStateToProps =(state) => {
  
    return {
    productadditions:state.productadditions,
    
  }
}
export default connect(mapStateToProps)(ProductTabSpecification);

