// react
import React, { useState, useEffect, useMemo } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { withRouter } from "react-router";
// application
import shopApi from "../../api/shop";
import { useDeferredData, useProductColumns, useProductTabs } from "../../services/hooks";

// blocks
import BlockBanner from "../blocks/BlockBanner";
import BlockBrands from "../blocks/BlockBrands";
import BlockCategories from "../blocks/BlockCategories";
import BlockFeatures from "../blocks/BlockFeatures";
import BlockPosts from "../blocks/BlockPosts";
import BlockProductColumns from "../blocks/BlockProductColumns";
import BlockProducts from "../blocks/BlockProducts";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";
import BlockSlideShow from "../blocks/BlockSlideShow";
import posts from "../../data/blogPosts";
import theme from "../../data/theme";
import { connect } from "react-redux";
import { getbanner } from "../../store/banners";
import { getTopDeals } from "../../store/topdeals";
import { getnewproduct, addRecentViewed, getSizes } from "../../store/product";
import { storageUrl } from "../../common";
import { useDispatch } from "react-redux";
import Select from 'react-select'
import AdvanceSearch from "../AdvanceSearch/AdvanceSearch.lazy";


const options = [


]
const time = [


]
function HomePageTwo({
    getbanner,
    getnewproduct,
    getTopDeals,
    banners,
    categorylist,
    topdeals,
    newarrivals,
    addRecentViewed,
    recent,
    getSizes,
    sizes,
    location,
    history,
}) {
    useEffect(() => {
        getbanner();
        addRecentViewed();
        getTopDeals();
        getSizes();
    }, []);

    const [searchval, setSearchVal] = useState({ cat: "", size: "" });

    console.log("recent views data coming here", recent.items);
    let recentviewed;
    if (Array.isArray(recent.items)) {
        recentviewed = recent.items;
    } else {
        recentviewed = recent.items != null ? JSON.parse(recent.items) : [];
    }

    /****** Assign tabs in  */
    var recentviewcat = [];
    console.log("recent viewed data", recentviewed);
    if (recentviewed.length > 0) {
        recentviewed.forEach(function (element) {
            if (element.cat != undefined) {
                element.cat.forEach(function (elementcat) {
                    const found = recentviewcat.some((el) => el.categorySlug === elementcat.url_path);
                    if (!found)
                        recentviewcat.push({
                            id: elementcat.id,
                            name: elementcat.name,
                            categorySlug: elementcat.url_path,
                        });
                });
            }
        });
    }

    recentviewed =
        localStorage.getItem("recent_viewed_filter") != null
            ? JSON.parse(localStorage.getItem("recent_viewed_filter"))
            : recentviewed;
    let bannerArray = [];
    banners.items.forEach((element) => {
        bannerArray.push({
            image_classic: {
                ltr: storageUrl + element.path,
                rtl: storageUrl + element.path,
            },
            image_full: {
                ltr: storageUrl + element.path,
                rtl: storageUrl + element.path,
            },
            image_mobile: {
                ltr: storageUrl + element.path,
                rtl: storageUrl + element.pathv,
            },
        });
    });

    /**
     * Bestsellers.
     */
    const bestsellers = useDeferredData(() => shopApi.getPopularProducts({ limit: 7 }), []);

    let categorylistdata = [];

    categorylist.items.forEach((element) => {
        if (element.slug != "root") {
            categorylistdata.push({
                title: element.name,
                // url: '/shop',
                products: element.id,
                slug: element.slug,
                // image: 'images/categories/category-1.jpg',
                image: element.image_url,
                subcategories: [
                    // { title: 'Screwdrivers', url: '/shop' },
                    // { title: 'Milling Cutters', url: '/shop' },
                    // { title: 'Sanding Machines', url: '/shop' },
                    // { title: 'Wrenches', url: '/shop' },
                    // { title: 'Drills', url: '/shop' },
                ],
            });

            //tabcat.push({ id:  element.id, name: element.name, categorySlug: element.id } )
        }
    });

    /* Handle Recent Tab Change *****/
    const handleRecentTabChange = (event) => {
        var filterRecentViewed = [];
        var recentviewed = JSON.parse(localStorage.getItem("recent_viewed"));
        recentviewed.forEach(function (element) {
            const found = element.cat.some((el) => el.url_path === event.categorySlug);
            if (found) filterRecentViewed.push(element);
        });

        localStorage.setItem("recent_viewed_filter", JSON.stringify(filterRecentViewed));
        addRecentViewed(filterRecentViewed);
    };

    /**
     * Latest products.
     */
    const latestProducts = useProductTabs(
        useMemo(
            () => [
                { id: 1, name: "All", categorySlug: undefined },
                { id: 2, name: "Mobile & Tablets", categorySlug: "power-tools" },
                { id: 3, name: "LEDs / TVs", categorySlug: "hand-tools" },
                { id: 4, name: "Refrigerators", categorySlug: "plumbing" },
            ],
            []
        ),
        (tab) => shopApi.getLatestProducts({ limit: 8, category: tab.categorySlug })
    );

    let tabcat = [{ id: 1, name: "All", categorySlug: undefined }];
    let newarrivalsprod = [];
    if (newarrivals.items.length > 0) {
        newarrivals.items.forEach((element) => {
            newarrivalsprod.push({
                id: element.id,
                name: element.name,
                sku: element.sku,
                slug: element.url_key,
                price: parseFloat(element.price),
                special_price: element.special_price ? parseFloat(element.special_price) : null,
                regular_price: element.regular_price ? parseFloat(element.regular_price) : null,
                compareAtPrice: null,
                badges: "salsddde",
                images: element.images,
                rating: element.reviews.average_rating,
                reviews: element.reviews,
                availability: "in-stock",
                in_stock: element.in_stock,
                url_key: element.url_key,
                type: element.type,
                brand: null,
                categories: [],
                variants: element.variants,
                attributes: [],
                customFields: {},
            });
            if (element.cat != null) {
                element.cat.forEach(function (elementcat) {
                    const found = tabcat.some((el) => el.categorySlug === elementcat.url_path);
                    if (!found)
                        tabcat.push({
                            id: elementcat.category_id,
                            name: elementcat.name,
                            categorySlug: elementcat.url_path,
                        });
                });
            }
        });
    }

    let topdealdata = [];
    if (topdeals.items.length > 0) {
        topdeals.items.forEach((element) => {
            topdealdata.push({
                id: element.id,
                name: element.name,
                sku: element.sku,
                slug: element.url_key,
                price: parseFloat(element.price),
                special_price: element.special_price ? parseFloat(element.special_price) : null,
                regular_price: element.regular_price ? parseFloat(element.regular_price) : null,
                compareAtPrice: null,
                badges: "salsddde",
                images: element.images,
                rating: element.reviews.average_rating,
                availability: "in-stock",
                brand: null,
                in_stock: element.in_stock,
                url_key: element.url_key,
                type: element.type,
                variants: element.variants,
                categories: [],
                attributes: [],
                customFields: {},
            });
        });
    }

    /****** category option  *********/
    categorylist.items.forEach((cat) => {
        if (cat.name == "Root") {
        } else {
            options.push({ value: cat.slug, label: cat.name });
        }
    });

    /****** category option  *********/
    sizes.items.forEach((cat) => {
        time.push({ value: cat.id, label: cat.admin_name });
    });

    console.log("size coming here", time);

    /**
     * Featured products.
     */
    const featuredProducts = useProductTabs(
        useMemo(() => tabcat, []),
        (tab) => getnewproduct({ limit: 20, category: tab.id, new: 1 })
    );

    const setSearchValCattext = (element) => {
        console.log("element data ", element);
        setSearchVal({ ...searchval, ...{ cat: element.value } });
    };
    const setSearchValtext = (element) => {
        console.log("element data ", element);
        setSearchVal({ ...searchval, ...{ size: element.value } });
    };

    const submitSearch = () => {
        history.push(`/shop/catalog/${searchval.cat}?filter_size=${searchval.size}`);
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Welcome To — ${theme.name}`}</title>
            </Helmet>

            {useMemo(
                () => (
                    <BlockSlideShow
                        layout="full"
                        sliderImages={bannerArray}
                        slickSettings={{
                            autoplay: true,
                            pauseOnHover: false,
                            dots: true,
                            arrows: false,
                            infinite: true,
                            speed: 1200,
                            fade: true,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }}
                    />
                ),
                [bannerArray]
            )}



            {/* NEW SEARCH BOX */}
            <div class="s013">
                <AdvanceSearch />
            </div>

            {/* <section class="container-fluid pull">
                <div class="container">
                    <form action="#" method="post" novalidate="novalidate">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 col-sm-12 p-0 mt-1">
                                        <Select options={options} label="cat" onChange={setSearchValCattext} placeholder="Select Your Tyre..." />
                                    </div>

                                    <div class="col-lg-4 col-md-3 col-sm-12 p-0 mt-1">
                                        <Select options={time} label="size" onChange={setSearchValtext} placeholder="Select Size..." />
                                    </div>
                                    <div class="col-lg-4 col-md-3 col-sm-12 p-0 mt-1">
                                        <button type="button" id="select-tyre" onClick={submitSearch} class="btn btn-danger wrn-btn">
                                            Apply Now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section> */}

            {useMemo(
                () => (
                    <BlockProductsCarousel
                        title="New Arrivals"
                        layout="grid-4"
                        rows={2}
                        products={newarrivalsprod}
                        loading={featuredProducts.isLoading}
                        groups={featuredProducts.tabs}
                        onGroupClick={featuredProducts.handleTabChange}
                    />
                ),
                [newarrivalsprod]
            )}

            {useMemo(
                () => (
                    <BlockCategories title="Categories" layout="images" categories={[]} />
                ),
                []
            )}

            {/* <BlockProductsCarousel
                title="New Arrivals"
                layout="grid-5"
                rows={2}
                products={newarrivalsprod}
                loading={featuredProducts.isLoading}
                groups={featuredProducts.tabs}
                onGroupClick={featuredProducts.handleTabChange}
            /> */}

            {/* {useMemo(
                () => (
                    <BlockBanner />
                ),
                []
            )} */}

            {/* {useMemo(() => (
                <BlockProductsCarousel
                    title="Featured Products"
                    layout="grid-4"
                    products={bestsellers.data.data}
                    loading={bestsellers.data.isLoading}
                    groups={bestsellers.tabs}
                    onGroupClick={bestsellers.handleTabChange}
                />
            ), [bestsellers.data])} */}

            {useMemo(
                () => (
                    <BlockProductsCarousel
                        title="Top Deals"
                        layout="grid-4"
                        rows={2}
                        products={topdealdata}
                        loading={topdeals.isLoading}
                        groups={[]}
                        onGroupClick={featuredProducts.handleTabChange}
                    />
                ),
                [topdealdata]
            )}


            {recentviewed.length > 0 ? (
                <BlockProductsCarousel
                    title="Recently Viewed"
                    layout="grid-4"
                    products={recentviewed}
                    loading={recent.loading}
                    groups={recentviewcat}
                    onGroupClick={handleRecentTabChange}
                />
            ) : (
                ""
            )}

            {useMemo(
                () => (
                    <BlockFeatures />
                ),
                []
            )}

            {/* {useMemo(() => <BlockProductColumns columns={columns} />, [columns])} */}
        </React.Fragment>
    );
}

const mapDispatchToProps = {
    getbanner,
    getnewproduct,
    getTopDeals,
    addRecentViewed,
    getSizes,
};

const mapStateToProps = (state) => {
    console.log("state banner", state);
    return {
        banners: state.banners,
        categorylist: state.categories,
        topdeals: state.topdeals,
        newarrivals: state.newarrivals,
        recent: state.recent,
        sizes: state.sizes,
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePageTwo));
