import { MOBILE_MENU_CLOSE, MOBILE_MENU_OPEN,MENU_LIST,MENU_LIST_SUCCESS,MENU_LIST_FAILED } from './mobileMenuActionTypes';
import {menuPoint} from './endpoint'
export function mobileMenuOpen() {
    return { type: MOBILE_MENU_OPEN };
}

export function mobileMenuClose() {
    return { type: MOBILE_MENU_CLOSE };
}


export function MenuSuccess(menu) {
    return {
        type: MENU_LIST_SUCCESS,
        items: menu.data,
        eleitem: menu.elec_menu,
        batteryitem: menu.battery_inverter_menu,
    };
}

export function MenuFailed(error) {
    return {
        type: MENU_LIST_FAILED,
        items: [],
        error: error,
    };
}



// export function getMenu() {
//     return (dispatch) => {
//         dispatch({
//             type: MENU_LIST_SUCCESS,
//             items: [],
//             eleitem: [],
//             batteryitem:[]
//         });
//         fetch(menuPoint.list)
//             .then(function (response) {
//                 return response.json();
//             })
//             .then(function (response) {
//                 dispatch(MenuSuccess(response));
//                 //return response;
//             })
//             .catch(function (thrown) {
//                 dispatch(MenuFailed(thrown));
//             });
//     }       
// }