import { toast } from 'react-toastify';
import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM,WISHLIST_LIST,WISHLIST_LIST_FAILED,WISHLIST_LIST_SUCCESS } from './wishlistActionTypes';
import {wishlistPoint} from './endpoint'

import { frontUrl,Logintoken } from "../../common";
import {authCheckNull} from "../../common/AuthCheck"

export function wishlistAddItemSuccess(product) {

    console.log(`Product "${product.name}" added to wish list!`);

    toast.success(`Product "${product.name}" added to wish list!`);

    return {
        type: WISHLIST_ADD_ITEM,
        product,
    };
}

export function wishlistRemoveItemSuccess(productId) {
    return {
        type: WISHLIST_REMOVE_ITEM,
        productId,
    };
}

export function wishlistAddItem(product) {
    // sending request to server, timeout is used as a stu
    const logintoken = (!authCheckNull() ? '?token=' + Logintoken() : '');

    if(logintoken !==""){
            return (dispatch) => (
        new Promise((resolve) => { 
        
        setTimeout(() => {fetch(wishlistPoint.add + "/" + product.id + logintoken)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
              
                console.log("response data coming here please check ",response);
                dispatch(wishlistAddItemSuccess(product));
                dispatch(getWishList())
                resolve();
                //return response;
            })
            .catch(function (thrown) {
                console.log("wishlist data issue here",thrown);
            })
        }, 500);
   
            })
    )
    }
    else{
    window.location.href=frontUrl+"/account";
   }

    };
 


export function wishlistRemoveItem(productId) {
    // sending request to server, timeout is used as a stub
    const logintoken = (!authCheckNull() ? '?token=' + Logintoken() : '');

    if(logintoken !==""){

    return (dispatch) => (
        
        new Promise((resolve) => {
            dispatch(()=>({type:WISHLIST_LIST,loading:true,list:[]}));
            setTimeout(() => {

                fetch(wishlistPoint.add + "/" + productId + logintoken)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
              
                console.log("response data coming here please check ",response);
                dispatch(wishlistRemoveItemSuccess(productId));
                dispatch(getWishList())
                resolve();
                //return response;
            })
            .catch(function (thrown) {
                console.log("wishlist data issue here",thrown);
            });
              
                
            }, 500);
        })
    );

    }
    else{
        window.location.href=frontUrl+"/account";
    }
}

/*** wish List Success ***/
export function wishListSuccess(productdata)
{
    return {
        type: WISHLIST_LIST_SUCCESS,
        list: productdata,
    };
}

/*** wish List Failed ***/
export function wishListFailed(error)
{

    return {
        type: WISHLIST_LIST_FAILED,
        list: [],
        error
    };

}


/******* Get wishList */

export function getWishList()
{

    return (dispatch) => (

      
        new Promise((resolve) => {

            dispatch({type:WISHLIST_LIST,loading:true,list:[]});
            
            setTimeout(() => {
            const logintoken = (!authCheckNull() ? '?token=' + Logintoken() : '');

            if(logintoken !==""){
             
            fetch(wishlistPoint.list + logintoken) 
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
              
                console.log("response data coming here please check ",response.data);
                dispatch(wishListSuccess(response.data));
                resolve();
                //return response;
            })
            .catch(function (thrown) {
                console.log("wishlist data issue here",thrown);
                dispatch(wishListFailed(thrown));
            });
              
            }

            else{
                window.location.href=frontUrl+"/account";
            }
               
            }, 500);
        })
    );

}



