// react
import React, { useEffect, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

// data stubs
import theme from "../../data/theme";
import { orderReturnRequest } from "../../store/order/";
import Moment from "react-moment";

import classNames from "classnames";
import { toast } from "react-toastify";
import FileUpload from "../widgets/FileUpload";

export function AccountReturnRequest(props) {
    console.log(
        "Data coming in here details ",
        props,
        localStorage.getItem("detailorder" + props.match.params.orderId)
    );

    const [content, setcontent] = useState({
        return_reason: "",
        order_id: props.match.params.orderId,
        return_item_id: props.match.params.itemid,
        quantity_returned: 1,
        other_reason:''  
    });
    /*
     On Select change event 
    */
    const onselectchange = (element) => {
        console.log("element data ", element.target.name);
        if (element.target.name == "return_reason" && element.target.value == "Other") {
            otherreasonSet(true);
        } else {
            otherreasonSet(false);
        }
         if (element.target.name == "return_reason" && element.target.value == "Product is damaged") {
             damagereasonSet(true);
         } else {
             damagereasonSet(false); 
         }
         
        onchange(element);
    }
    const onchange = (element) => {
        console.log("element data ", element.target.name);
      
        setcontent({
            ...content,
            ...{
                [element.target.name]: element.target.value,
            },
        });
    };
    const [otherreason, otherreasonSet] = useState(false);
    const [damagereason, damagereasonSet] = useState(false);
    
    const [selectedFile, setSelectedFile] = useState(null);

    const submitUpdate = (event) => {
         event.preventDefault();
        if (props.match.params.qty < content.quantity_returned) { 
            toast.error("Item qty  can't be  more then ordered ");
            return false;
        }
        
        if (selectedFile != null) {
            let formData = new FormData(event.target);
            formData.append("return_reason", content.return_reason);
            formData.append("order_id", content.order_id);
            formData.append("return_item_id", content.return_item_id);
            formData.append("quantity_returned", content.quantity_returned);
            formData.append("other_reason", content.other_reason);
            formData.append("file", selectedFile);
           // content = selectedFile;
            
            console.log("files data coming here", formData, content);
            props.orderReturnRequest(formData);
            // toast.success("Your request submitted successfully!");
        }
        else {
            props.orderReturnRequest(content);
        }
       
        

        
    };
    const orderdetail = JSON.parse(localStorage.getItem("detailorder" + props.match.params.orderId));
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Order Details — ${theme.name}`}</title>
            </Helmet>

            <div className="card">
                <div className="order-header">
                    <div className="order-header__actions">
                        <Link to="/account/orders" className="btn btn-xs btn-secondary">
                            X
                        </Link>
                    </div>
                    <h5 className="order-header__title">Order #{orderdetail.id} Return Request</h5>
                </div>
                <div className="card-divider" />
                <form onSubmit={submitUpdate} enctype="multipart/form-data">
                    <div className="card-table">
                        <div className="table-responsive-sm container">
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12">
                                    <div className="form-group">
                                        <label htmlFor="password-new"> Choose Reason of Return </label>
                                        <select name="return_reason" className="form-control" onChange={onselectchange}>
                                            <option value="Wrong Product is ordered">Wrong Product is ordered</option>
                                            <option value="Product is damaged">Product is damaged</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    {otherreason ? (
                                        <div className="form-group">
                                            <label htmlFor="password-confirm">Other Reason</label>
                                            <textarea
                                                name="other_reason"
                                                placeholder="Other Reason"
                                                onKeyUp={onchange}
                                                className="form-control"
                                            ></textarea>
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    {damagereason ? (
                                        <div className="form-group">
                                            <label htmlFor="password-confirm">Damanged Photo</label>

                                            <FileUpload onFileSelect={(file) => setSelectedFile(file)} />
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    <div className="form-group">
                                        <label htmlFor="password-confirm">Item Return Quantity</label>

                                        <input
                                            type="number"
                                            name="quantity_returned"
                                            max={props.match.params.qty}
                                            onKeyUp={onchange}
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password-confirm">
                                            <input type="checkbox" name="term_condition" /> I agree  <Link to="/page/terms-conditions"> terms and conditions</Link>
                                        </label>
                                    </div>

                                    <div className="form-group mt-4 mb-3">
                                        <button
                                            type="submit"
                                            className={classNames("btn btn-primary", {
                                                "btn-loading": props.return.loading,
                                            })}
                                        >
                                            Save return
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        orderdata: state.order.items,
        return: state.orderreturn,
    };
};

const mapDispatchToProps = {
    orderReturnRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountReturnRequest);
