import { toast } from "react-toastify";
import {
    ADDRESS_LIST_SUCCESS,
    CREATE_ADDRESS_FAILED,
    CREATE_ADDRESS_SUCCESS,
    ADDRESS_EDIT_SUCCESS,
    CREATE_ADDRESS,
    ADDRESS_LIST_FAILED,
    ADDRESS_LIST,
    ADDRESS_EDIT,
} from "./addressActionTypes";
import { addressPoint, clientsPoint } from "./endpoint";
import { axios } from "axios";
import { Logintoken, frontUrl } from "../../common/index";

export function addressSuccess(products) {
    console.log("address Data coming here", products);

    return {
        type: ADDRESS_LIST_SUCCESS,
        items: products.data,
    };
}

export function addressFailed(error) {
    return {
        type: ADDRESS_LIST_FAILED,
        items: [],
        error: error,
    };
}

export function getAddresslist(id = "") {
    console.log("address Id list ", id);
    // sending request to server, timeout is used as a stub
    return (dispatch) => {
        dispatch({
            type: ADDRESS_LIST,
            list: { loading: true, items: [] },
        });
        fetch(addressPoint.list + (id != "" ? "/" + id : "") + "?token=" + Logintoken())
            .then(function (response) {
                return response.json();
            })
            .then((response) => {
                dispatch(addressSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(addressFailed(thrown));
            });
    };
}
/***** clear Address Action  ****/
export function clearAddressAction() {
    return (dispatch) => {
        dispatch({
            type: ADDRESS_EDIT,
            items: [],
        });
    };
}

export function addressDetailSuccess(products) {
    console.log("address Data coming here", products);
    return {
        type: ADDRESS_EDIT_SUCCESS,
        items: products.data,
    };
}

export function addressDetailFailed(error) {
    return {
        type: ADDRESS_LIST_FAILED,
        items: [],
        error: error,
    };
}

export function getAddressDetail(id = "") {
    // sending request to server, timeout is used as a stub
    return (dispatch) => {
        dispatch({
            type: ADDRESS_EDIT,
            list: { loading: true, items: [] },
        });
        fetch(addressPoint.list + (id != "" ? "/" + id : "") + "?token=" + Logintoken())
            .then(function (response) {
                return response.json();
            })
            .then((response) => {
                dispatch(addressDetailSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(addressDetailFailed(thrown));
            });
    };
}

/****** save addresss Success *******/
function SaveAddressSuccess(data) {
    //toast.success("Address Saved Successfully");
    //setTimeout(function(){ window.location.href = frontUrl+'/account/addresses'; }, 2000)
    return {
        type: CREATE_ADDRESS_SUCCESS,
        items: data,
        addressSaved: true,
    };
}

/****** save addresss Failed *******/
function SaveAddressFailed(data) {
    toast.error(data);
    return {
        type: CREATE_ADDRESS_FAILED,
        items: [],
        addressSaved: false,
    };
}

/******** Checkout Address Save  Async Action  */
export function SaveAddress(data, id = "") {
    return (dispatch) =>
        new Promise((resolve) => {
            dispatch({ type: CREATE_ADDRESS, loading: true, items: [] });
            setTimeout(() => {
                fetch(addressPoint.add + "?token=" + Logintoken(), {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                        "Content-Type": "application/json",
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(data), // body data type must match "Content-Type" header
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        console.log("response coming here", response);
                        toast.success("Address Saved Successfully!");
                        localStorage.setItem("save_address_response", JSON.stringify(response));
                        dispatch(SaveAddressSuccess(response));
                        dispatch(getAddresslist());

                        resolve();
                        //return response;
                    })
                    .catch(function (thrown) {
                        toast.error("Some Error occured , Please Contact Admin");
                        dispatch(SaveAddressFailed(thrown));
                    });
            }, 500);
        });
}

/******** Delete Address Save  Async Action  */
export function DeleteAddress(id) {
    return (dispatch) =>
        new Promise((resolve) => {
            //  dispatch({type:CREATE_ADDRESS,loading:true,items:[]})
            setTimeout(() => {
                fetch(addressPoint.list + "/" + id + "?token=" + Logintoken(), {
                    method: "DELETE", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                        "Content-Type": "application/json",
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer",
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        console.log("response coming here", response);
                        toast.success("Address Deleted Successfully!");

                        setTimeout(function () {
                            window.location.href = frontUrl + "/account/addresses";
                        }, 1000);
                        resolve();
                        //return response;
                    })
                    .catch(function (thrown) {
                        toast.error("Some Error occured , Please Contact Admin");
                    });
            }, 500);
        });
}

export function UpdateAddress(data, id = "") {
    return (dispatch) =>
        new Promise((resolve) => {
            dispatch({ type: CREATE_ADDRESS, loading: true, items: [] });
            setTimeout(() => {
                fetch(addressPoint.list + (id != "" ? "/" + id : "") + "?token=" + Logintoken(), {
                    method: "PUT", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                        "Content-Type": "application/json",
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(data), // body data type must match "Content-Type" header
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        console.log("response coming here", response);
                        toast.success("Address Updated Successdully!");
                        setTimeout(function () {
                            window.location.href = frontUrl + "/account/addresses";
                        }, 2000);
                        localStorage.setItem("save_address_response", JSON.stringify(response));
                        dispatch(SaveAddressSuccess(response));

                        resolve();
                        //return response;
                    })
                    .catch(function (thrown) {
                        toast.error("Some Error occured , Please Contact Admin");
                        dispatch(SaveAddressFailed(thrown));
                    });
            }, 500);
        });
}
