// react
import React, { useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link, Redirect } from "react-router-dom";

import { connect, useDispatch } from "react-redux";
import { login, OtploginSend, OtpVerifySend, register } from "../../store/auth";
// application
import PageHeader from "../shared/PageHeader";
import { Check9x7Svg } from "../../svg";
import classNames from "classnames";

// data stubs
import theme from "../../data/theme";
import { useEffect } from "react";
import { LOGIN } from "../../store/auth/authActionTypes";
import BlockLoader from "../blocks/BlockLoader";
import { authCheckNull } from "../../common/AuthCheck";

export function AccountPageLogin({ OtploginSend, registerAction, registerloading, auth, OtpVerifySend, loginAction }) {
    const [login, setlogin] = useState({ phone: "" });
    const [loginVal, setloginVal] = useState({
        mobileerror1: false,
        mobileerror2: false,
        emailerror1: false,
        emailerror2: false,
    });
    const [Otp, setotp] = useState({ otp: "", mobile: "", getCartItemFromState: "" });
    const [OtpVal, setOtpVal] = useState({ otperror: false });
    const [EmailLogin, setEmaiLogin] = useState({ email: "", password: "" });
    const [EmailLoginVal, setEmaiLoginVal] = useState({ emailerror: false, passerror: "" });
    const [registerVal, setregisterVal] = useState({
        emailerror: false,
        passworderror: false,
        confirmpassworderror: false,
        phoneerror: false,
    });
    const [register, setRegister] = useState({ email: "", password: "", resetpassword: "", phone: "" });
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "Login /Sign Up", url: "" },
    ];

    /****** Handle register ****/
    const handleLogin = (event) => {
        let key = event.currentTarget.name;
        let value = event.target.value;
        console.log("key name ", event);
        setlogin({ ...login, [key]: value });
    };

    /****** Handle OTP Verify ****/
    const handleOtpVerify = (event) => {
        let key = event.currentTarget.name;
        let value = event.target.value;
        console.log("key name ", event);
        setotp({ ...OtpVal, [key]: value });
    };

    /****** Handle Login ****/
    const handleRegister = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        login[key] = value;
        setRegister({ ...register, [key]: value });
    };

    const validateEmail = (emailField) => {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(emailField) == false) {
            //alert('Invalid Email Address');
            return false;
        }
        return true;
    };

    const showBtn = () => {
        // setTimeout(function() {
        //     document.getElementById('resendOtp1').style.display = 'block';
        //     document.getElementById('resendOtp2').style.display = 'none';
        //     }, 30000);
        // var time_limit = 30;
        // var time_out = setInterval(() => {
        // if(time_limit == 0) {
        //     document.getElementById('resendOtp1').style.display = 'block';
        //     document.getElementById('resendOtp2').style.display = 'none';
        //     document.getElementById('timer').style.display = 'none';
        // } else {
        //     if(time_limit < 10) {
        //     time_limit = time_limit;
        //     }
        //     console.log('time_limit======== ' + time_limit);
        //     document.getElementById('timer').innerHTML = time_limit;
        //     time_limit -= 1;
        // }
        // }, 1000);
    };

    /****** Handle Login submit */
    const handleLoginSubmit = (event) => {
        if (login.phone == "") {
            console.log("mobile blank");
            setloginVal({
                ...loginVal,
                mobileerror1: true,
                mobileerror2: false,
                emailerror1: false,
                emailerror2: false,
            });
            return false;
        }
        if (login.phone.length > 10 || login.phone.length < 10 || isNaN(login.phone)) {
            console.log("mobile lenght error");
            setloginVal({
                ...loginVal,
                mobileerror2: true,
                mobileerror1: false,
                emailerror1: false,
                emailerror2: false,
            });
            return false;
        } else {
            console.log("mobile proper");
            loginVal.mobileerror1 = false;
            loginVal.mobileerror2 = false;
        }
        console.log("email confirmation logs", login);
        document.getElementById("mform1").reset();
        document.getElementById("login-email").value = "";
        // document.getElementById("login-otp").value = "";
        OtploginSend(login);
        showBtn();
    };

    /****** On change event set values */
    const setLoginEmaillogin = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        setEmaiLogin({ ...EmailLogin, ...{ [key]: value } });
    };

    /**** handle Login submit  ******/
    const handleEmailLoginSubmit = (event) => {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (login.phone == "") {
            setloginVal({
                ...loginVal,
                emailerror1: true,
                emailerror2: false,
                mobileerror1: false,
                mobileerror2: false,
            });
            console.log("email blank");
            return false;
        }
        if (!filter.test(login.phone)) {
            setloginVal({
                ...loginVal,
                emailerror2: true,
                emailerror1: false,
                mobileerror1: false,
                mobileerror2: false,
            });
            console.log("not an email");
            return false;
        } else {
            console.log("proper email");
            loginVal.emailerror1 = false;
            loginVal.emailerror2 = false;
        }
        if (EmailLogin.password == "") {
            setEmaiLoginVal({ ...EmailLoginVal, passerror: true });
            return false;
        } else {
            EmailLogin.passerror = false;
        }
        var getCartItemFromState = localStorage.getItem("configuration");
        if (getCartItemFromState) {
            loginAction({ email: login.phone, password: EmailLogin.password, cart: getCartItemFromState });
        } else {
            loginAction({ email: login.phone, password: EmailLogin.password });
        }
        document.getElementById("login-email").value = "";
        document.getElementById("password").value = "";
    };

    /****** Handle Otp Verify  Submit */
    const handleOtpSubmit = (event) => {
        //console.log("email validation ",validateEmail(login.email))

        if (Otp.otp == "") {
            setOtpVal({ ...OtpVal, otperror: true });
            return false;
        } else {
            OtpVal.otperror = false;
        }
        Otp.phone = localStorage.getItem("authmobile");
        var getCartItemFromState = localStorage.getItem("configuration");
        if (getCartItemFromState) {
            Otp.cart = getCartItemFromState;
        } else {
            Otp.cart = "[]";
        }
        console.log("OTP data is sending here", Otp);
        //return false;
        OtpVerifySend(Otp);

        document.getElementById("login-email").value = "";
        // document.getElementById("login-otp").value = "";

        //loginAction(login);
    };

    /****** Resend Otp  ********/
    const resendOtp = () => {
        login.phone = localStorage.getItem("authmobile");
        OtploginSend(login);
    };

    /****** Resend Otp  ********/
    const back = () => {
        window.location.reload();
    };
    /****** Handle Login submit */
    const handleRegisterSubmit = (event) => {
        if (register.email == "" || !validateEmail(register.email)) {
            setregisterVal({ ...registerVal, emailerror: true });
            return false;
        } else {
            registerVal.emailerror = false;
            //setregisterVal({...registerVal,...{emailerror:false}})
        }

        if (register.phone == "" || register.phone.length != 10) {
            setregisterVal({ ...registerVal, phoneerror: true });
            return false;
        } else {
            registerVal.phoneerror = false;
            //setregisterVal({...registerVal,...{emailerror:false}})
        }

        if (register.password == "") {
            setregisterVal({ ...registerVal, passworderror: true });
            return false;
        } else {
            register.passworderror = false;
        }

        if (register.resetpassword == "" || register.password !== register.resetpassword) {
            setregisterVal({ ...registerVal, confirmpassworderror: true });
            return false;
        } else {
            registerVal.confirmpassworderror = false;
        }

        registerAction(register);
    };
    if (!authCheckNull()) {
        return <Redirect to="/account/dashboard" />;
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Login / Sign Up" breadcrumb={breadcrumb} />
            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    {!auth.OTP.data.status ? (
                                        // login form
                                        <form id="mform1">
                                            <h3 className="card-title"> Login</h3>
                                            <div className="form-group">
                                                <label htmlFor="login-email">Mobile Number</label>
                                                <input
                                                    id="login-email"
                                                    type="text"
                                                    name="phone"
                                                    className="form-control"
                                                    placeholder="Enter Email / Mobile Number"
                                                    onKeyUp={handleLogin}
                                                />
                                                {loginVal.emailerror1 ? (
                                                    <p className="alert-danger">Please Enter Email Address</p>
                                                ) : (
                                                    ""
                                                )}
                                                {loginVal.emailerror2 ? (
                                                    <p className="alert-danger">Please Enter Valid Email Address</p>
                                                ) : (
                                                    ""
                                                )}
                                                {loginVal.mobileerror1 ? (
                                                    <p className="alert-danger">Please Enter Your Mobile Number</p>
                                                ) : (
                                                    ""
                                                )}
                                                {loginVal.mobileerror2 ? (
                                                    <p className="alert-danger">Please Enter 10 Digit Mobile Number</p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password">Password </label>
                                                <input
                                                    id="password"
                                                    type="password"
                                                    name="password"
                                                    className="form-control"
                                                    placeholder="Enter Password"
                                                    onKeyUp={setLoginEmaillogin}
                                                />
                                                {EmailLoginVal.passerror ? (
                                                    <p className="alert-danger">Please Enter password </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="form-group d-flex">
                                                <button
                                                    type="button"
                                                    onClick={handleEmailLoginSubmit}
                                                    className={classNames("btn btn-primary mt-2 mt-md-3 mt-lg-2 mr-3", {
                                                        "btn-loading": auth.login.loading,
                                                    })}
                                                >
                                                    Login
                                                </button>
                                                <br />
                                                <button
                                                    type="button"
                                                    onClick={handleLoginSubmit}
                                                    className={classNames("btn btn-primary mt-2 mt-md-3 mt-lg-2", {
                                                        "btn-loading": auth.OTP.loading,
                                                    })}
                                                >
                                                    Request OTP
                                                </button>
                                            </div>
                                        </form>
                                    ) : (
                                        // Verify OTP form
                                        <form id="mform2">
                                            <h3 className="card-title">Verify OTP</h3>
                                            <div className="form-group">
                                                <div></div>
                                                <label htmlFor="login-otp">OTP Number</label>
                                                <input
                                                    id="login-otp"
                                                    type="text"
                                                    name="otp"
                                                    className="form-control"
                                                    placeholder="Enter OTP Number"
                                                    onKeyUp={handleOtpVerify}
                                                />
                                                {/* <span className="" id="timer"></span> */}
                                                {OtpVal.otperror ? (
                                                    <p className="alert-danger">Please Enter valid otp </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="form-group d-flex">
                                                <button
                                                    type="button"
                                                    onClick={handleOtpSubmit}
                                                    className={classNames("btn btn-primary mt-2 mt-md-3 mt-lg-2 mr-3", {
                                                        "btn-loading": auth.login.loading,
                                                    })}
                                                >
                                                    Verify
                                                </button>{" "}
                                                <button
                                                    type="button"
                                                    id="resendOtp1"
                                                    onClick={resendOtp}
                                                    className={classNames("btn btn-primary mt-2 mt-md-3 mt-lg-2 mr-3", {
                                                        "btn-loading": auth.OTP.loading,
                                                    })}
                                                >
                                                    Resend OTP
                                                </button>{" "}
                                                {/* <button
                                                    type="button"
                                                    id="resendOtp2"
                                                    className="btn btn-secondary mt-2 mt-md-3 mt-lg-2 mr-3"
                                                >
                                                    Resend OTP
                                                </button>{" "} */}
                                                <button
                                                    type="button"
                                                    onClick={back}
                                                    className="btn btn-primary mt-2 mt-md-3 mt-lg-2"
                                                >
                                                    Back
                                                </button>{" "}
                                            </div>
                                        </form>
                                    )}

                                    {/* <h3 className="card-title"> {!auth.OTP.data.status ? "Login" : "Verify OTP"}</h3> */}

                                    {/* <form action="post" id="mform">
                                        <div className="form-group">
                                            <label htmlFor="login-email">
                                                {!auth.OTP.data.status ? "Mobile Number" : "OTP  Number"}
                                            </label>
                                            {!auth.OTP.data.status ? (
                                                <input
                                                    id="login-email"
                                                    type="text"
                                                    name="phone"
                                                    className="form-control"
                                                    placeholder="Enter Email / Mobile Number"
                                                    onKeyUp={handleLogin}
                                                />
                                            ) : (
                                                <input
                                                    id="login-email"
                                                    type="text"
                                                    name="otp"
                                                    className="form-control"
                                                    placeholder="Enter OTP Number"
                                                    onKeyUp={handleOtpVerify}
                                                />
                                            )}
                                            {OtpVal.otperror ? <p className="alert-danger">Please Enter valid otp </p> : ""}
                                            {loginVal.emailerror1 ? (
                                                <p className="alert-danger">Please Enter Email Address</p>
                                            ) : (
                                                ""
                                            )}
                                            {loginVal.emailerror2 ? (
                                                <p className="alert-danger">Please Enter Valid Email Address</p>
                                            ) : (
                                                ""
                                            )}
                                            {loginVal.mobileerror1 ? (
                                                <p className="alert-danger">Please Enter Your Mobile Number</p>
                                            ) : (
                                                ""
                                            )}
                                            {loginVal.mobileerror2 ? (
                                                <p className="alert-danger">Please Enter 10 Digit Mobile Number</p>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        {!auth.OTP.data.status ? (
                                            <div className="form-group">
                                                <label htmlFor="password">Password </label>
                                                <input
                                                    id="password"
                                                    type="password"
                                                    name="password"
                                                    className="form-control"
                                                    placeholder="Enter Password"
                                                    onKeyUp={setLoginEmaillogin}
                                                />
                                                {EmailLoginVal.passerror ? (
                                                    <p className="alert-danger">Please Enter password </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        <div className="form-group d-flex">
                                        {auth.OTP.data.status ? (
                                            <React.Fragment>
                                                <button
                                                    type="button"
                                                    onClick={handleOtpSubmit}
                                                    className="btn btn-primary mt-2 mt-md-3 mt-lg-2 mr-3"
                                                >
                                                    Verify
                                                </button>{" "}
                                                <button
                                                    type="button"
                                                    onClick={resendOtp}
                                                    className="btn btn-primary mt-2 mt-md-3 mt-lg-2 mr-3"
                                                >
                                                    Resend OTP
                                                </button>{" "}
                                                <button
                                                    type="button"
                                                    onClick={back}
                                                    className="btn btn-primary mt-2 mt-md-3 mt-lg-2"
                                                >
                                                    Back
                                                </button>{" "}
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <button
                                                    type="button"
                                                    onClick={handleEmailLoginSubmit}
                                                    className="btn btn-primary mt-2 mt-md-3 mt-lg-2 mr-3"
                                                >
                                                    Login
                                                </button>
                                                <br />
                                                <button
                                                    type="button"
                                                    onClick={handleLoginSubmit}
                                                    className={classNames("btn btn-primary mt-2 mt-md-3 mt-lg-2", {
                                                        "btn-loading": auth.OTP.loading,
                                                    })}
                                                >
                                                    Request OTP
                                                </button>
                                            </React.Fragment>
                                        )}
                                        </div>
                                    </form> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex mt-4 mt-md-0">
                            {!auth.OTP.data.status ? (
                                <div className="card flex-grow-1 mb-0">
                                    <div className="card-body">
                                        <h3 className="card-title">Register</h3>
                                        <form>
                                            <div className="form-group">
                                                <label htmlFor="register-email">Email address</label>
                                                <input
                                                    id="register-email"
                                                    type="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    onKeyUp={handleRegister}
                                                />
                                                {registerVal.emailerror ? (
                                                    <p className="  alert-danger">Please Enter Valid Email </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="register-email">Phone Number</label>
                                                <input
                                                    id="phonenumber"
                                                    type="text"
                                                    name="phone"
                                                    className="form-control"
                                                    placeholder="Enter Phone Number"
                                                    onKeyUp={handleRegister}
                                                />
                                                {registerVal.phoneerror ? (
                                                    <p className="  alert-danger">Please Enter Valid Phone Number </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="register-password">Password</label>
                                                <input
                                                    id="register-password"
                                                    type="password"
                                                    name="password"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    onKeyUp={handleRegister}
                                                />
                                                {registerVal.passworderror ? (
                                                    <p className="  alert-danger">Please Enter Password </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="register-confirm">Repeat Password</label>
                                                <input
                                                    id="register-confirm"
                                                    type="password"
                                                    name="resetpassword"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    onKeyUp={handleRegister}
                                                />
                                                {registerVal.confirmpassworderror ? (
                                                    <p className="  alert-danger">
                                                        Password does not match to confirm password
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <button
                                                type="button"
                                                onClick={handleRegisterSubmit}
                                                className={classNames("btn btn-primary mt-2 mt-md-3 mt-lg-4", {
                                                    "btn-loading": auth.register.loading,
                                                })}
                                            >
                                                Register
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapDispatchToProps = {
    OtploginSend: OtploginSend,
    registerAction: register,
    OtpVerifySend: OtpVerifySend,
    loginAction: login,
};

const mapStateToProps = (state) => {
    console.log("state banner", state);
    return {
        auth: state.auth,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountPageLogin);
