import { toast } from "react-toastify";
import { TOPDEALS_LIST_SUCCESS, TOPDEALS_LIST_FAILED } from "./topDealsActionTypes";
import { topdealPoint } from "./endpoint";

export function topDealsSuccess(products) {
    return {
        type: TOPDEALS_LIST_SUCCESS,
        items: products.data,
    };
}

export function topDealsFailed(error) {
    return {
        type: TOPDEALS_LIST_FAILED,
        items: [],
        error: error,
    };
}

export function getTopDeals() {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        fetch(topdealPoint.list + "&topdeals=true")
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(topDealsSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(topDealsFailed(thrown));
            });
}
