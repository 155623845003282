import { ApiEndpointUrl } from "../../common";
export const authPoint = {
    login: ApiEndpointUrl + "customer/login?token=true",
    register: ApiEndpointUrl + "customer/register",
    productdetail: ApiEndpointUrl + "customer/",
    forgot_password: ApiEndpointUrl + "customer/forgot-password",
    logout: ApiEndpointUrl + "customer/logout",
    statelist: ApiEndpointUrl + "statelist",
    updateprofile: ApiEndpointUrl + "customer/profile",
    OtpLogin: ApiEndpointUrl + "send-verification-code",
    registerOtpLogin: ApiEndpointUrl + "send-registration-vcode",
    verifyRegOtp: ApiEndpointUrl + "verify-reg-code",
    VerifyOtp: ApiEndpointUrl + "verify-code",
};
