import React, { useEffect,useState} from 'react';
import { connect } from 'react-redux';
// application
import Pagination from '../shared/Pagination';
import Rating from '../shared/Rating';
import classNames from 'classnames';
import {listReview,createReview} from "../../store/review"
import { Link } from 'react-router-dom';
// data stubs
import reviews from '../../data/shopProductReviews';
import queryString from 'query-string';
import { authCheckNull } from '../../common/AuthCheck';
import BlockLoader from '../blocks/BlockLoader';
   


function ProductTabReviews({ review, listReview,
    createReview, productid, loading }) {
 
    const [valid, setvalid] = useState({
        rating_error: false,
        title_error: false,
        comment_error: false,
         
    });
    
     
    const [data, setdata] = useState({
        rating: 0,
        title: '',
        comment: '',
    });

    useEffect(() => {
        listReview(productid, 1);
    
    }, [productid])
    
    let params = queryString.parse(window.location.search)
    console.log("Review List data coming here", params)
    

    console.log("Review list coming here", review.list);
    if (!Array.isArray(review.list.items)) {
        review.list.items = [];
    }

    const postReview = () => {

        /**** submit Update */
     


        if (document.getElementById("rating").value == '') {
            
            setvalid({ ...valid, ...{ rating_error: true } })
            return false;
        }
        else {
            valid.rating_error = false;
            //setvalid({...valid,...{first_name_error:false}})
           
           
        }


        if (document.getElementById("title").value == '') {
            valid.title_error = true;
            setvalid({ ...valid, ...{ title_error: true } })
            return false;
        }
        else {
            valid.title_error = false;
            setvalid({ ...valid, ...{ title_error: false } })
          
        }

      
        if (document.getElementById("comment").value == '') {
            setvalid({ ...valid, ...{ comment_error: true } })
            valid.comment_error = true;
            return false;
        }
        else {
            
            valid.comment_error = false;
        }
       
        data.rating = document.getElementById("rating").value;
        data.title = document.getElementById("title").value;
        data.comment = document.getElementById("comment").value;
      
     
        createReview(data, productid, 1);
        document.getElementById("reviewform").reset()


    }
    /*** On Pagination Change */
    const onPageChange = (pagenumber) => {
        listReview(productid, pagenumber);

    }
    const reviewsList = review.list.items.map((review, index) => (
        <li key={index} className="reviews-list__item" style={{ 'display': review.status == 'approved' ? 'block' : 'none'}}>
            <div className="review">
                <div className="review__avatar"><img src='images/avatars/avatar-1.jpg' alt="" /></div>
                <div className=" review__content">
                    <div className=" review__author">{review.name}</div>
                    <div className=" review__rating">
                        <Rating value={review.rating} />    
                    </div>
                    <div className=" review__text">{review.comment}</div>
    
                    <div className=" review__date"> {new Intl.DateTimeFormat('en-GB', { 
                month: 'long', 
                day: '2-digit',
                year: 'numeric', 
            }).format(new Date(review.created_at))}</div>
                </div>
            </div>
        </li>
    ));

    return (
        <div className="reviews-view">
            <div className="reviews-view__list">
                <h3 className="reviews-view__header">Customer Reviews</h3>

                <div className="reviews-list">  
                {review.loading?<BlockLoader/>:''}
                    <ol className="reviews-list__content">
                        {reviewsList}
                    </ol>
                    {review.list.meta !=undefined&&review.list.meta.last_page>1 ?
                    <div className="reviews-list__pagination">
                        <Pagination current={review.list.meta.current_page} siblings={2} total={review.list.meta.total} onPageChange={onPageChange} last_page={review.list.meta.last_page}/>
                    </div>:''}
                </div>
            </div>
            {!authCheckNull()?<form className="reviews-view__form" id="reviewform">
                <h3 className="reviews-view__header">Write A Review</h3>
                <div className="row">
                    <div className="col-12 col-lg-9 col-xl-8">
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="review-stars">Review Stars</label>
                                <select id="rating" name="rating" className="form-control">
                                    <option  value="5">5 Stars Rating</option>
                                    <option  value="4">4 Stars Rating</option>
                                    <option  value="3">3 Stars Rating</option>
                                    <option  value="2">2 Stars Rating</option>
                                    <option  value="1">1 Stars Rating</option>
                                </select>
                                {valid.rating_error?<p class="  alert-danger">Please Select Rating </p>:''}
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="review-author">Title</label>
                                <input type="text" className="form-control" name="title" id="title" placeholder="Title" />
                                {valid.title_error?<p class="  alert-danger">Please Enter title </p>:''}
                            </div>
                    
                        </div>
                        <div className="form-group">
                            <label htmlFor="review-text">Your Review</label>
                            <textarea className="form-control" id="comment" name="comment" rows="6" />
                            {valid.comment_error?<p class="  alert-danger">Please Enter Comment </p>:''}
                        </div>
                        <div className="form-group mb-0">
                            <button type="button"  className={classNames('btn btn-primary btn-lg', {
                            'btn-loading': loading,
                        })} onClick={postReview}>Post Your Review</button>
                        </div>
                    </div>
                </div>
            </form>:<Link to="/account/login"><div class="btn btn-primary btn-lg">Login to add Reviews</div></Link>}
        </div>
    );
}

const mapStateToProps = (state) => ({
    review: state.review,
    loading:state.review.create.loading
});

const mapDispatchToProps = {
    listReview,
    createReview
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTabReviews);

