import {
    PRODUCT_LIST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAILED,
    PRODUCT_DETAIL,
    PRODUCT_DETAIL_SUCCESS,
    PRODUCT_DETAIL_FAILED,
    PRODUCT_VARIATION_DETAIL,
    PRODUCT_VARIATION_DETAIL_SUCCESS,
    PRODUCT_VARIATION_DETAIL_FAILED,
    NEW_PRODUCT_LIST,
    NEW_PRODUCT_LIST_SUCCESS,
    NEW_PRODUCT_LIST_FAILED,
    BRANDS_LIST_SUCCESS,
    PRODUCT_ADDITIONALS,
    PRODUCT_ADDITIONALS_SUCCESS,
    PRODUCT_ADDITIONALS_FAILED,
    PRODUCT_FILTERS_SUCCESS,
    PRODUCT_FILTERS,
    PRODUCT_RECENT_VIEWED_LIST,
    PRODUCT_SEARCH,
    PRODUCT_SEARCH_SUCCESS,
    PRODUCT_SEARCH_FAILED,
    RELATED_PRODUCTS,
    RELATED_PRODUCTS_SUCCESS,
    RELATED_PRODUCTS_FAILED,
    PRODUCTS_BANNERS,
    PRODUCTS_BANNERS_SUCCESS,
    PRODUCTS_BANNERS_FAILED,
    PRODUCTS_SIZES_SUCCESS,
} from "./productActionTypes";

/*
 * item example:
 * {
 *   id: 1,
 *   product: {...}
 *   options: [
 *     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
 *   ],
 *   price: 250,
 *   quantity: 2,
 *   total: 500
 * }
 * extraLine example:
 * {
 *   type: 'shipping',
 *   title: 'Shipping',
 *   price: 25
 * }
 */
const initialState = {
    loading: 0,
    items: [],
    itemmeta: { from: 0, tp: 0, total: 0 },
};

export default function productReducer(state = initialState, action) {
    switch (action.type) {
        case PRODUCT_LIST:
            return { ...state, ...{ loading: action.loading } };
        case PRODUCT_LIST_SUCCESS:
            return { ...state, ...{ loading: action.loading, items: action.items, meta: action.itemmeta } };
        case PRODUCT_LIST_FAILED:
            return { ...state, ...{ loading: action.loading, items: action.items, meta: action.itemmeta } };

        default:
            return state;
    }
}

let initialdetailState = { loading: 0, items: { data: [] } };
/******* product Detail Reducer */
export function detailProductReducer(state = initialdetailState, action) {
    switch (action.type) {
        case PRODUCT_DETAIL:
            return { ...state, ...{ loading: action.loading } };
        case PRODUCT_DETAIL_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false } };
        case PRODUCT_DETAIL_FAILED:
            return { ...state, ...{ items: action.items, loading: false } };
        default:
            return state;
    }
}

let initialdetailadditionsState = { loading: 0, items: [] };
/******* product Detail Reducer */
export function detailProductAdditionalsReducer(state = initialdetailadditionsState, action) {
    switch (action.type) {
        case PRODUCT_ADDITIONALS:
            return { ...state, ...{ loading: action.loading } };
        case PRODUCT_ADDITIONALS_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false } };
        case PRODUCT_ADDITIONALS_FAILED:
            return { ...state, ...{ items: action.items, loading: false } };
        default:
            return state;
    }
}

let initialdetailvariationConfigurationState = { loading: 0, items: [] };
/******* product Detail Reducer */
export function detailProductVariationsReducer(state = initialdetailvariationConfigurationState, action) {
    switch (action.type) {
        case PRODUCT_VARIATION_DETAIL:
            return { ...state, ...{ loading: action.loading, items: action.items } };
        case PRODUCT_VARIATION_DETAIL_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false } };
        case PRODUCT_VARIATION_DETAIL_FAILED:
            return { ...state, ...{ items: action.items, loading: false } };
        default:
            return state;
    }
}

let initialRelatedProducState = { loading: 0, items: [] };
/******* product Detail Reducer */
export function relatedProductReducer(state = initialRelatedProducState, action) {
    switch (action.type) {
        case RELATED_PRODUCTS:
            return { ...state, ...{ loading: true, items: [] } };
        case RELATED_PRODUCTS_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false } };
        case RELATED_PRODUCTS_FAILED:
            return { ...state, ...{ items: action.items, loading: false } };
        default:
            return state;
    }
}

const newProductInitialState = {
    loading: 0,
    items: [],
};

/*** New Arrival  Reducer */
export function newProductReducer(state = newProductInitialState, action) {
    switch (action.type) {
        case NEW_PRODUCT_LIST:
            return { ...state, action };
        case NEW_PRODUCT_LIST_SUCCESS:
            return { ...state, ...{ items: action.items } };
        case NEW_PRODUCT_LIST_FAILED:
            return { ...state, ...{ items: action.items } };
        default:
            return state;
    }
}

/*** New Arrival  Reducer */
export function brandReducer(state = newProductInitialState, action) {
    switch (action.type) {
        case BRANDS_LIST_SUCCESS:
            return { ...state, ...{ items: action.items } };
        default:
            return state;
    }
}

/*** New Arrival  Reducer */
export function sizesReducer(state = newProductInitialState, action) {
    switch (action.type) {
        case PRODUCTS_SIZES_SUCCESS:
            return { ...state, ...{ items: action.items } };
        default:
            return state;
    }
}
const productFiltersInitialState = {
    loading: 0,
    items: [],
    productspecific: [],
    maxprice: 100000,
};

/******** Product Filters ******/
export function productFiltersReducer(state = productFiltersInitialState, action) {
    switch (action.type) {
        case PRODUCT_FILTERS:
            return { ...state, ...{ loading: true } };
        case PRODUCT_FILTERS_SUCCESS:
            return {
                ...state,
                ...{ items: action.items, productspecific: action.productspecific, maxprice: action.maxprice,loading:action.loading },
            };
        case "SET_OPTION_VALUE":
            return {
                ...state,
                options: { ...state.options, page: 1, [action.option]: action.value },
            };
        case "SET_FILTER_VALUE":
            return {
                ...state,
                options: { ...state.options, page: 1 },
                filters: { ...state.filters, [action.filter]: action.value },
            };
        case "RESET_FILTERS":
            return { ...state, options: { ...state.options, page: 1 }, filters: {} };
        case "RESET":
            return state.init ? initialState : state;
        default:
            return state;
    }
}

let recentViewedInitialState = {
    loading: 1,
    items: JSON.stringify([]),
};
/*** Recent viewed  Product */
export function recentViewedReducer(state = recentViewedInitialState, action) {
    switch (action.type) {
        case PRODUCT_RECENT_VIEWED_LIST:
            return { ...state, ...{ items: action.items, loading: 0 } };
        default:
            return state;
    }
}

let initialSearchState = { loading: 0, items: { data: [] } };
/******* product Detail Reducer */
export function searchProductReducer(state = initialSearchState, action) {
    switch (action.type) {
        case PRODUCT_SEARCH:
            return { ...state, ...{ loading: action.loading } };
        case PRODUCT_SEARCH_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false } };
        case PRODUCT_SEARCH_FAILED:
            return { ...state, ...{ items: action.items, loading: false } };
        default:
            return state;
    }
}

let initialbannersState = { loading: 0, items: [] };
/******* product Detail Reducer */
export function productBannersReducer(state = initialbannersState, action) {
    switch (action.type) {
        case PRODUCTS_BANNERS:
            return { ...state, ...{ loading: action.loading } };
        case PRODUCTS_BANNERS_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false } };
        case PRODUCTS_BANNERS_FAILED:
            return { ...state, ...{ items: action.items, loading: false } };
        default:
            return state;
    }
}
