export default [
    {
        type: "link",
        label: "Home",
        url: "/",
        children: [],
    },

    {
        type: "link",
        label: "About Us",
        url: "/page/about-us",
        children: [],
    },

    {
        type: "link",
        label: "Bicycle Tyres",
        url: "",
        children: [
            {
                type: "link",
                label: "BMX",
                url: "/shop/catalog/bmx",
                children: [],
            },
            {
                type: "link",
                label: "Tubes",
                url: "/shop/catalog/tube",
                children: [],
            },
            {
                type: "link",
                label: "City Bike",
                url: "/shop/catalog/city-bike",
                children: [],
            },
            {
                type: "link",
                label: "MTB",
                url: "/shop/catalog/mtb",
                children: [],
            },
        ],
    },

    {
        type: "link",
        label: "Automotive",
        url: "",
        children: [
            {
                type: "link",
                label: "Motorcycle Tyre",
                url: "/shop/catalog/motorcycle-tyre",
                children: [],
            },
            {
                type: "link",
                label: "Scooter Tyre",
                url: "/shop/catalog/scooter-tyre",
                children: [],
            },
        ],
    },

    {
        type: "link",
        label: "Tubes",
        url: "/shop/catalog/tube",
        children: [],
    },

    {
        type: 'link',
        label: 'Innovation',
        url: '/page/innovation',
        children: [],
    },

    {
        type: "link",
        label: "Account",
        url: "/account",
        children: [
            { type: "link", label: "Dashboard", url: "/account/dashboard" },
            { type: "link", label: "Edit Profile", url: "/account/profile" },
            { type: "link", label: "Order History", url: "/account/orders" },
            { type: "link", label: "Address Book", url: "/account/addresses" },
            { type: "link", label: "Change Password", url: "/account/password" },
        ],
    },


    {
        type: "link",
        label: "Wish List",
        url: "/shop/wishlist",
        children: [],
    },

    {
        type: "link",
        label: "Cart",
        url: "/shop/cart",
        children: [],
    },


    {
        type: "link",
        label: "Contact Us",
        url: "/contact-us",
        children: [],
    },
];
