// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';  

// data stubs
import theme from '../../data/theme';
import Moment from 'react-moment';

export  function AccountPageOrderDetails( props  ) {  

    
    console.log("Data coming in here details ",props,localStorage.getItem('detailorder'+props.match.params.orderId))
    const orderdetail = JSON.parse(localStorage.getItem('detailorder'+props.match.params.orderId));
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Order Details — ${theme.name}`}</title>
            </Helmet>

            <div className="card">
                <div className="order-header">
                    <div className="order-header__actions">
                        <Link to="/account/orders" className="btn btn-xs btn-secondary">X</Link>
                    </div>
                    <h5 className="order-header__title">Order #{orderdetail.id} </h5>
                    <div className="order-header__subtitle">
                        Was placed on{" "}
                        <mark className="order-header__date">
                            <Moment format="DD/MM/YYYY">{orderdetail.created_at}</Moment>
                        </mark>{" "}
                        and is currently <mark className="order-header__status">{orderdetail.status}</mark>.
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="card-table__body card-table__body--merge-rows">
                                {orderdetail.items.map((item) => (
                                    <tr>
                                        <td>
                                            {item.product.name} × {item.qty_ordered}
                                        </td>
                                        <td>{item.formated_total}</td>
                                        <td>
                                            <Link
                                                to={
                                                    "/account/returnrequest/" +
                                                    props.match.params.orderId +
                                                    "/" +
                                                    item.id +
                                                    "/" +
                                                    item.qty_ordered
                                                }  
                                                className="btn btn-xs btn-primary"
                                            >
                                                Return Request
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                            <tbody className="card-table__body card-table__body--merge-rows">
                                <tr>
                                    <th>Subtotal</th>
                                    <td>{orderdetail.formated_sub_total}</td>
                                </tr>

                                {orderdetail.tax_amount > 0 ? (
                                    <>
                                    {orderdetail.billing_address.state === "PB" || orderdetail.billing_address.state === "Punjab" ? (
                                        <>
                                            <tr>
                                                <th>CGST</th>
                                                <td>₹{orderdetail.tax_amount / 2}</td>
                                            </tr> 
                                            <tr>
                                                <th>SGST</th>
                                                <td>₹{orderdetail.tax_amount / 2}</td>
                                            </tr> 
                                        </>
                                    ): (
                                    <tr>
                                        <th>IGST</th>
                                        <td>{orderdetail.tax_amount}</td>
                                    </tr> 
                                    )}
                                    </>
                                ) : (
                                   <tr>
                                        <th>Total Tax</th>
                                        <td>{orderdetail.tax_amount}</td>
                                    </tr> 
                                )}                

                                {/* <tr>
                                    <th>Total Tax</th>
                                    <td>{orderdetail.formated_tax_amount}</td>
                                </tr> */}



                                <tr>
                                    <th>Shipping</th>
                                    <td>{orderdetail.base_shipping_invoiced}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Total</th>
                                    <td>{orderdetail.formated_grand_total}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

            <div className="row mt-3 no-gutters mx-n2">
                <div className="col-sm-6 col-12 px-2">
                    <div className="card address-card address-card--featured" style={{'height': '225px'}}>
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">Shipping Address</div>
                            <div className="address-card__name">
                                {orderdetail.shipping_address.first_name} {orderdetail.shipping_address.last_name}
                            </div>
                            <div className="address-card__row">
                                {orderdetail.shipping_address.address1[0]+', '}
                                {orderdetail.shipping_address.city+', '} {orderdetail.shipping_address.state+', '}
                                {orderdetail.shipping_address.postcode},{orderdetail.shipping_address.country}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                <div className="address-card__row-content"> {orderdetail.shipping_address.phone}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Email Address</div>
                                <div className="address-card__row-content"> {orderdetail.shipping_address.email}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                    <div className="card address-card address-card--featured" style={{'height': '225px'}}>
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">Billing Address</div>
                            <div className="address-card__name">
                                {orderdetail.billing_address.first_name} {orderdetail.billing_address.last_name}
                            </div>
                            <div className="address-card__row">
                                {orderdetail.billing_address.address1[0]+', '}
                                {orderdetail.billing_address.city+', '} {orderdetail.billing_address.state+', '}
                                {orderdetail.billing_address.postcode},{orderdetail.billing_address.country}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                <div className="address-card__row-content">{orderdetail.billing_address.phone}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Email Address</div>
                                <div className="address-card__row-content">{orderdetail.billing_address.email}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
  return {
      orderdata:state.order.items
    }
};



export default connect(mapStateToProps)(AccountPageOrderDetails);
