// react
import React,  { Fragment, useEffect, useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';

// blocks
import BlockMap from '../blocks/BlockMap';

// data stubs
import theme from '../../data/theme';

import { contactRequest } from "../../store/page"
import { connect } from "react-redux";
import classNames from "classnames";
import BlockLoader from '../blocks/BlockLoader';


function SitePageContactUs({ location, contactrequest, contact }) {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Contact Us', url: '' },
    ];
    
    const [contactform, setContactForm] = useState({
      name: "",
      email: "",
      subject: "",
      comment: "",
      error: {
        nameerror: false,
        emailerror: false,
        subjecterror: false,
        commenterror: false,
      },
    });
  
    const keyUpEvent = (event) => {
      setContactForm({
        ...contactform,
        ...{ [event.target.name]: event.target.value },
      });
    };
    /******* contact Action Request */
    var contactrequestAction = (event) => {
      event.preventDefault();
      var error = false;
      
  
    
      contactrequest(contactform);
       document.getElementById("contactform").reset();
      
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Contact Us — ${theme.name}`}</title>
            </Helmet>

            <BlockMap />

            <PageHeader header="Contact Us" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        <div className="contact-us__address">
                                            <h3 className="contact-us__header card-title">RALSON (INDIA) LIMITED</h3>
                                            <p>
                                                {theme.contacts.address}
                                                <br />
                                                <b>Email: </b>{theme.contacts.email}
                                                <br />
                                                <b>Phone: </b>{theme.contacts.phone}
                                            </p>
                                        </div>

                                        <div className="contact-us__address">
                                            <h3 className="contact-us__header card-title">RALSON (INDIA) LIMITED</h3>
                                            <p>
                                                {theme.contacts1.address}
                                                <br />
                                                <b>Phone: </b>{theme.contacts1.phone}
                                                <br />
                                                <b>Website: </b>{theme.contacts1.website}
                                            </p>
                                        </div>
                                        <div className="contact-us__address">
                                            <h3 className="contact-us__header card-title">Registered Office</h3>
                                            <p>
                                                {theme.contacts2.address}
                                                <br />
                                                <b>Phone: </b>{theme.contacts2.phone}
                                                <br />
                                                <b>Fax: </b>{theme.contacts2.fax}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <h4 className="contact-us__header card-title">Leave us a Message</h4>

                                        <form onSubmit={contactrequestAction} id="contactform">
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-name">Your Name</label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="form-name"
                                                        className="form-control"
                                                        placeholder="Your Name"
                                                        onKeyUp={keyUpEvent}
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-email">Email</label>
                                                    <input
                                                        type="email"
                                                        id="form-email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Email Address"
                                                        onKeyUp={keyUpEvent}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-subject">Subject</label>
                                                <input
                                                    name="subject"
                                                    placeholder="Subject*"
                                                    type="text"
                                                    className="form-control"
                                                    onKeyUp={keyUpEvent}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-message">Message</label>
                                                <textarea
                                                    id="form-message"
                                                    name="comment"
                                                    placeholder="Your Message"
                                                    defaultValue={""}
                                                    onKeyUp={keyUpEvent}
                                                    className="form-control"
                                                    rows="4"
                                                />
                                            </div>

                                            <button
                                                type="submit"
                                                className={classNames("btn btn-primary", {
                                                    "btn-loading": contact.loading,
                                                })}
                                            >
                                                Send Message
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}



const mapStateToProps = (state) => {
    return {
     
      contact: state.contact,
    };
  };
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
      contactrequest: (data) => {
       dispatch(contactRequest(data));
      },
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(SitePageContactUs);
