// react
import React, { Component } from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import Megamenu from './Megamenu';
import Menu from './Menu';
import { ArrowRoundedRight6x9Svg } from '../../svg';

// data stubs
import departments from '../../data/DoubleMegamenu';

class DoubleMegamenuLinks extends Component {
    
    constructor(props) {
        super(props);

        // this.state = {
        //     open: true,
        //     fixed: false,
        //     area: null,
        // };
    }
    handleMouseEnter = (event) => {
        const megamenu = event.currentTarget.offsetParent.querySelector(".doublemegamenu__megamenu.visible");
        const currentMegamenu = event.currentTarget.querySelector(".megamenu");
        if(currentMegamenu) {
            currentMegamenu.style.height = "450px";
        }
        if(megamenu){
            megamenu.classList.remove("visible");
        }
    };

    handleMouseLeave = (event) => {
        const megamenu = event.currentTarget.offsetParent.querySelector(".doublemegamenu__megamenu");
        if(megamenu){
            megamenu.classList.add("visible");
        }
    };

   
    linksList = departments.map((department, index) => {
        let arrow = null;
        let submenu = null;
        let itemClass = '';
      
        if (department.submenu) {
            arrow = <ArrowRoundedRight6x9Svg className="doublemegamenu__link-arrow" />;
        }

        if (department.submenu && department.submenu.type === 'menu') {
            itemClass = 'doublemegamenu__item--menu';
            submenu = (
                <div className="doublemegamenu__menu">
                    <Menu items={department.submenu.menu} />
                </div>
            );
        }

        if (department.submenu && department.submenu.type === 'megamenu') {
            submenu = (
                <div className={`doublemegamenu__megamenu doublemegamenu__megamenu--${department.submenu.menu.size}`}>
                    <Megamenu menu={department.submenu.menu} location="department" />
                </div>
            );
        }

        return (
            <li key={index} className={`doublemegamenu__item ${itemClass}`} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                <Link to={department.url}>
                    {department.title}
                    {arrow}
                </Link>
                {submenu}
            </li>
        );
    });

    render() {
        let {menu} = this.props;
         console.log("menu link",menu)
           
        const linksList = menu.map((department, index) => {


          
            let arrow = null;
            let submenu = null;
            let itemClass = '';
          
            if (department.submenu) {
                arrow = <ArrowRoundedRight6x9Svg className="doublemegamenu__link-arrow" />;
            }
    
            if (department.submenu && department.submenu.type === 'menu') {
                itemClass = 'doublemegamenu__item--menu';
                submenu = (
                    <div className="doublemegamenu__menu">
                        <Menu items={department.submenu.menu} />
                    </div>
                );
                
            }
    
            if (department.submenu && department.submenu.type === 'megamenu') {
                console.log("Mega menu department",department.submenu.menu)
                submenu = (
                    <div className={`doublemegamenu__megamenu doublemegamenu__megamenu--${department.submenu.menu.size}`}>
                        <Megamenu menu={department.submenu.menu} location="department" />
                    </div>
                );  

                
            }

            return (
                <li key={index} className={`doublemegamenu__item ${itemClass}`} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                    <Link to={department.url}>
                        {department.title}
                        {arrow}
                    </Link>
                    {submenu}
                </li>
            );
    

            
        });
        console.log("Department data json", linksList)
        return (
            <ul className="doublemegamenu__links">
                {linksList}
            </ul>
        );
    }
}


export default DoubleMegamenuLinks;
