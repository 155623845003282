import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import StroykaSlick from '../shared/StroykaSlick';

const slickSettings = {
    autoplay: true,
    pauseOnHover: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
};

class BlockTextSlider extends React.PureComponent {


    constructor(props) {
        super(props);    
      }
    render() {
       console.log("props to news",this.props.newlist);
        return (
            <StroykaSlick {...slickSettings}>
               {this.props.newlist.items.length>0?this.props.newlist.items.map((data)=>(<p>{data.title}</p>)):''}

            </StroykaSlick>
        );
    }
}

const mapStateToProps =(state) => {
    console.log("state banner",state);
    return {
    newlist: state.news
  }
}

export default connect(mapStateToProps,{})(BlockTextSlider);

