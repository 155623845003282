// react
import React ,{ useState }  from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { resetAction} from  '../../store/auth/authAction';
// application
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';
import classNames from 'classnames';

// data stubs
import theme from '../../data/theme';

export  function AccountResetPass({ResetPassAction}) {

    const [login, setlogin] = useState({ email: ''});
    const [loginVal, setloginVal] = useState({ emailerror: false});

   
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Reset Password', url: '' },
    ];


/****** Handle register ****/
   const handleLogin = (event)=>{
         let key = event.currentTarget.name;
         let value= event.target.value;
         setlogin({...login,[key]:value})
    }



const validateEmail=(emailField)=>{
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(emailField) == false) 
    {
        //alert('Invalid Email Address');
        return false;
    }
    return true;
}


/****** Handle Login submit */
const handleresetSubmit = (event)=>{

    if(login.email==''  || !validateEmail(login.email)  )
    {
        
        setloginVal({...loginVal,emailerror:true})
        return false;
    }
    else
    {
        setloginVal({...loginVal,emailerror:false})
    }

   ResetPassAction(login)
   

}




    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Forgot Password" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">Reset Password</h3>
                                    <form action="post">
                                        <div className="form-group">
                                            <label htmlFor="login-email">Email address</label>
                                            <input
                                                id="login-email"
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Enter email"
                                                onKeyUp={handleLogin}
                                            />
                                           {loginVal.emailerror?<p class="  alert-danger">Please Enter Valid Email </p>:''}  
                                        </div>
                                        
                                        <button type="button" onClick={handleresetSubmit}   className={classNames('btn btn-primary mt-2 mt-md-3 mt-lg-4', {
                                            'btn-loading': false,
                                        })}>
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}


const mapDispatchToProps =  {
    ResetPassAction:resetAction

   }
   
   const mapStateToProps =(state) => {
      
       return {
       auth:state.auth,

     }
   }
   export default connect(mapStateToProps,mapDispatchToProps)(AccountResetPass);
