// react
import React from 'react';

// third-party

import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import PageHeader from './shared/PageHeader';

import { cartAddItem } from '../store/cart';
import { compareRemoveItem } from '../store/compare';


// data stubs
import theme from '../data/theme';

function OrderDetail(props) {
    const { products, compareRemoveItem, cartAddItem } = props;
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Order Detail', url: '' },
    ];

    let content;  
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div className="block-empty__message">Thanks for your order, You Order is placed successfully!</div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm">Continue Shopping</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    

    return (
        <React.Fragment>
            <Helmet>
                <title>{` ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Order Success " breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    products: state.compare,
});

const mapDispatchToProps = {
    cartAddItem,
    compareRemoveItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrderDetail);
