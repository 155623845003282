export const CATEGORY_LIST = "CATEGORY_LIST";
export const CATEGORY_LIST_FAILED = "CATEGORY_LIST_FAILED";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const SUB_CATEGORY_LIST = "SUB_CATEGORY_LIST";
export const SUB_CATEGORY_LIST_FAILED = "SUB_CATEGORY_LIST_FAILED";
export const SUB_CATEGORY_LIST_SUCCESS = "SUB_CATEGORY_LIST_SUCCESS";

export const SUB_MENU_CATEGORY_LIST = "SUB_MENU_CATEGORY_LIST";
export const SUB_MENU_CATEGORY_LIST_FAILED = "SUB_MENU_CATEGORY_LIST_FAILED";
export const SUB_MENU_CATEGORY_LIST_SUCCESS = "SUB_MENU_CATEGORY_LIST_SUCCESS";


export const PARENTS_SUB_MENU_CATEGORY_LIST = "PARENTS_SUB_MENU_CATEGORY_LIST";
export const PARENTS_SUB_MENU_CATEGORY_LIST_FAILED = "PARENTS_SUB_MENU_CATEGORY_LIST_FAILED";
export const PARENTS_SUB_MENU_CATEGORY_LIST_SUCCESS = "PARENTS_SUB_MENU_CATEGORY_LIST_SUCCESS";