// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { connect } from "react-redux";

function Currency(props) {
    const { value, currency, currentCurrency } = props;

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    const { symbol } = currency || currentCurrency;
    console.log("data symbol", symbol);
    return <React.Fragment>{`${symbol}${numberWithCommas(parseFloat(value).toFixed(2))}`}</React.Fragment>;
}

Currency.propTypes = {
    /** price value */
    value: PropTypes.number.isRequired,
    /** currency object, specify to override currentCurrency */
    currency: PropTypes.object,
    /** currency object */
    currentCurrency: PropTypes.object,
};

const mapStateToProps = (state) => ({
    currentCurrency: state.currency,
});

export default connect(mapStateToProps)(Currency);
