/******* Login Actions */
export const LOGIN = "LOGIN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

/******* Register  Actions */
export const REGISTER = "REGISTER";
export const REGISTER_FAILED = "REGISTER_FAILED";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

/******* Profile   Actions */
export const PROFILE = "PROFILE";
export const PROFILE_FAILED = "PROFILE_FAILED";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";

/******* RESET password   Actions */
export const RESET = "RESET";
export const RESET_FAILED = "RESET_FAILED";
export const RESET_SUCCESS = "RESET_SUCCESS";

/******* States List    Actions */
export const STATELIST = "STATELIST";
export const STATELIST_SUCCESS = "STATELIST_SUCCESS";
export const STATELIST_FAILED = "STATELIST_FAILED";

/******* States List    Actions */
export const OTP_LOGIN = "OTP_LOGIN";
export const OTP_LOGIN_SUCCESS = "OTP_LOGIN_SUCCESS";
export const OTP_LOGIN_FAILED = "OTP_LOGIN_FAILED";     


/** OTP Verify Action */
export const OTP_LOGIN_VERIFY = "OTP_LOGIN_VERIFY";
export const OTP_LOGIN_VERIFY_SUCCESS = "OTP_LOGIN_VERIFY_SUCCESS";
export const OTP_LOGIN_VERIFY_FAILED = "OTP_LOGIN_FAILED";  