export const ORDERS_LIST = "ORDERS_LIST";
export const ORDERS_LIST_FAILED = "ORDERS_LIST_FAILED";
export const ORDERS_LIST_SUCCESS = "ORDERS_LIST_SUCCESS";
/***** order Detail Action *****/
export const ORDERS_DETAIL = "ORDERS_DETAIL";
export const ORDERS_DETAIL_FAILED = "ORDERS_DETAIL_FAILED";
export const ORDERS_DETAIL_SUCCESS = "ORDERS_DETAIL_SUCCESS";

/***** order return Action *****/
export const ORDER_RETURN_REQUEST = "ORDER_RETURN_REQUEST";
export const ORDER_RETURN_REQUEST_FAILED = "ORDER_RETURN_REQUEST_FAILED";
export const ORDER_RETURN_REQUEST_SUCCESS = "ORDER_RETURN_REQUEST_SUCCESS";


/***** order return List Action *****/
export const ORDER_RETURN_LIST = "ORDER_RETURN_LIST";
export const ORDER_RETURN_LIST_FAILED = "ORDER_RETURN_LIST_FAILED";
export const ORDER_RETURN_LIST_SUCCESS = "ORDER_RETURN_LIST_SUCCESS";
