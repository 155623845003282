// react
import React, { useEffect, useState } from "react";

import { stateslistAction } from "../../store/auth";

import { connect } from "react-redux";
// third-party
import { Helmet } from "react-helmet-async";

import { SaveAddress, getAddressDetail, UpdateAddress, clearAddressAction } from "../../store/address";
import { postcodeValidator, postcodeValidatorExistsForCountry } from "postcode-validator";

import classNames from "classnames";

// data stubs
import theme from "../../data/theme";
import { InputGroup } from "reactstrap";
import BlockLoader from "../blocks/BlockLoader";

export function AccountPageEditAddress({
    stateslistAction,
    statelist,
    address,
    SaveAddress,
    getAddressDetail,
    match,
    UpdateAddress,
    clearAddressAction,
}) {
    const [valid, setvalid] = useState({
        first_name_error: false,
        last_name_error: false,
        street_address_error: false,
        city_error: false,
        state_error: false,
        postcode_error: false,

        phonenumber_error: false,
    });

    console.log("data coming here to check", address.edit);
    if (match.params.addressId != "add") {
    } else {
        address.edit.items = {
            first_name: "",
            last_name: "",
            company_name: "",
            appartment_name: "",
            city: "",
            state: "",
            postcode: "",
            phone: "",
            address1: [],
            country: "IN",
        };
    }
    const [data, setdata] = useState({
        first_name: address.edit.items.first_name ? address.edit.items.first_name : "",
        last_name: address.edit.items.last_name ? address.edit.items.last_name : "",
        company_name: address.edit.items.company_name ? address.edit.items.company_name : "",
        appartment_name: "",
        city: address.edit.items.city ? address.edit.items.city : "",
        state: address.edit.items.state ? address.edit.items.state : "",
        postcode: address.edit.items.postcode ? address.edit.items.postcode : "",

        phone: address.edit.items.phone ? address.edit.items.phone : "",
        address1: address.edit.items.address1 ? address.edit.items.address1 : [],
        country: "IN",
    });

    useEffect(() => {
        stateslistAction();
        console.log("Address id coming here", match.params.addressId);
        if (match.params.addressId != "add") {
            getAddressDetail(match.params.addressId);
        } else {
            setdata({
                first_name: "",
                last_name: "",
                company_name: "",
                appartment_name: "",
                city: "",
                state: "",
                postcode: "",
                phone: "",
                address1: [],
                country: "IN",
            });
        }
    }, [match.params.addressId]);
    /****** Handle Login submit */
    var statedata = {
        payment: "bank",
        first_name: "",
        last_name: "",
        company_name: "",
        appartment_name: "",
        city: "",
        state: "",
        postcode: "",

        phone: "",
        address1: "",
        country: 1,
        use_for_shipping: false,
        checkout_term_error: false,
        address_id: 0,
        address_list: [],
        shipping_rates: [],
        cart_items: [],
        save_address_response: [],
        validation: {
            first_name_error: false,
            last_name_error: false,
            street_address_error: false,
            city_error: false,
            state_error: false,
            postcode_error: false,

            phonenumber_error: false,
        },
    };

    /**** Handle change  */
    const handlechange = (event) => {
        if (event.target.name == "address1") {
            setdata({ ...data, ...{ [event.target.name]: [event.target.value] } });
        } else {
            setdata({ ...data, ...{ [event.target.name]: event.target.value } });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("data coming here", data);
        console.log("Validation data coming here", valid);

        if (document.getElementById("first_name").value == "") {
            setvalid({ ...valid, ...{ first_name_error: true } });
            return false;
        } else {
            valid.first_name_error = false;
            //setvalid({...valid,...{first_name_error:false}})
        }
        if (document.getElementById("last_name").value == "") {
            valid.last_name_error = true;
            setvalid({ ...valid, ...{ last_name_error: true } });
            return false;
        } else {
            valid.last_name_error = false;
            setvalid({ ...valid, ...{ last_name_error: false } });
        }

        if (document.getElementById("address1").value == "") {
            setvalid({ ...valid, ...{ street_address_error: true } });
            return false;
        } else {
            valid.street_address_error = false;
        }

        if (document.getElementById("city").value == "") {
            setvalid({ ...valid, ...{ city_error: true } });
            return false;
        } else {
            valid.city_error = false;
        }

        if (document.getElementById("state").value == "") {
            setvalid({ ...valid, ...{ state_error: true } });

            return false;
        } else {
            valid.state_error = false;
        }

        if (
            document.getElementById("postcode").value == "" ||
            !postcodeValidator(document.getElementById("postcode").value, "IN")
        ) {
            setvalid({ ...valid, ...{ postcode_error: true } });
            valid.postcode_error = true;
            return false;
        } else {
            valid.postcode_error = false;
        }

        if (document.getElementById("phone").value == "") {
            setvalid({ ...valid, ...{ phonenumber_error: true } });
            valid.phonenumber_error = true;
            return false;
        } else {
            valid.phonenumber_error = false;
        }

        if (document.getElementById("phone").value.toString().length != 10) {
            setvalid({ ...valid, ...{ phonenumber_error_1: true } });
            valid.phonenumber_error_1 = true;
            return false;
        } else {
            valid.phonenumber_error_1 = false;
        }

        if (match.params.addressId != undefined && match.params.addressId != "add") {
            data.id = match.params.addressId;
            console.log("data coming here", match.params.addressId);
            UpdateAddress(data, match.params.addressId);
        } else {
            SaveAddress(data);
        }
    };

    console.log("Account data coming here", address);

    return (
        <div className="card">
            <Helmet>
                <title>{`Edit Address — ${theme.name}`}</title>
            </Helmet>
            {address.edit.loading ? <BlockLoader /> : ""}
            {!address.edit.loading ? (
                <div className="card-header">
                    <h5>{address.edit.items.first_name ? "Edit Address" : "Add Address"}</h5>
                </div>
            ) : (
                ""
            )}
            <div className="card-divider" />
            {!address.edit.loading ? (
                <div className="card-body">
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-10 col-xl-8">
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="checkout-first-name">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="first_name"
                                        placeholder="First Name"
                                        name="first_name"
                                        defaultValue={
                                            address.edit.items.first_name ? address.edit.items.first_name : ""
                                        }
                                        onChange={handlechange}
                                    />
                                    {valid.first_name_error ? (
                                        <p class="  alert-danger">Please Enter First Name </p>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="checkout-last-name">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="last_name"
                                        placeholder="Last Name"
                                        name="last_name"
                                        defaultValue={address.edit.items.last_name ? address.edit.items.last_name : ""}
                                        onChange={handlechange}
                                    />
                                    {valid.last_name_error ? <p class="  alert-danger">Please Enter Last Name </p> : ""}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="checkout-company-name">
                                    Company Name <span className="text-muted">(Optional)</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="company"
                                    placeholder="Company Name"
                                    name="company"
                                    defaultValue={
                                        address.edit.items.company_name ? address.edit.items.company_name : ""
                                    }
                                    onChange={handlechange}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="checkout-street-address">Street Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address1"
                                    placeholder="Street Address"
                                    name="address1"
                                    defaultValue={address.edit.items.address1 ? address.edit.items.address1 : ""}
                                    onChange={handlechange}
                                />
                                {valid.street_address_error ? (
                                    <p class="  alert-danger">Please Enter Street Address </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkout-address">
                                    Apartment, suite, unit etc. <span className="text-muted">(Optional)</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="apartment_address"
                                    name="apartment_address"
                                    onChange={handlechange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkout-city">Town / City</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    name="city"
                                    defaultValue={address.edit.items.city ? address.edit.items.city : ""}
                                    onChange={handlechange}
                                />
                                {valid.city_error ? <p class="  alert-danger">Please Enter City </p> : ""}
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkout-state">State</label>
                                <select
                                    name="states"
                                    id="state"
                                    name="state"
                                    defaultValue={address.edit.items.state}
                                    onChange={handlechange}
                                    className="form-control"
                                >
                                    <option value="">Select State</option>
                                    {statelist.map((value) => (
                                        <option value={value.code}>{value.default_name}</option>
                                    ))}
                                </select>

                                {valid.state_error ? <p class="  alert-danger">Please select state </p> : ""}
                            </div>
                            <div className="form-group">
                                <label htmlFor="checkout-postcode">Postcode / ZIP</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="postcode"
                                    name="postcode"
                                    onChange={handlechange}
                                    defaultValue={address.edit.items.postcode ? address.edit.items.postcode : ""}
                                />
                                {valid.postcode_error ? (
                                    <p class="  alert-danger">Please Enter Valid Postal code </p>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="checkout-phone">Phone</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="phone"
                                        placeholder="Phone"
                                        name="phone"
                                        onChange={handlechange}
                                        defaultValue={address.edit.items.phone ? address.edit.items.phone : ""}
                                    />
                                    {valid.phonenumber_error ? (
                                        <p class="  alert-danger">Please Enter Phone number </p>
                                    ) : (
                                        ""
                                    )}
                                    {valid.phonenumber_error_1 ? (
                                        <p class="  alert-danger">Please Enter 10 Digit Mobile number </p>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            <div className="form-group mt-3 mb-0">
                                <button
                                    className={classNames("btn btn-primary", {
                                        "btn-loading": address.create.loading,
                                    })}
                                    onClick={handleSubmit}
                                    type="button"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    statelist: state.states.items,
    saveaddress: state.saveaddress,
    address: state.address,
    auth: state.auth,
});

const mapDispatchToProps = {
    stateslistAction,
    SaveAddress,
    getAddressDetail,
    UpdateAddress,
    clearAddressAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageEditAddress);
