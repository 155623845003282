export default [
    {
        title: "Home",
        url: "/",
        // submenu: {
        //     type: 'menu',
        //     menu: [
        //         { title: 'Home 1', url: '/' },
        //         { title: 'Home 2', url: '/home-two' },
        //     ],
        // },
    },
    {
        title: "Mobile & Tablets",
        url: "/shop/catalog/mobile-electronics",
        submenu: {
            type: "megamenu",
            menu: {
                size: "lg",
                image: {
                    ltr: "images/megamenu/mobile.png",
                    rtl: "images/megamenu/mobile.png",
                },
                imageSizeStyle: "contain",
                columns: [
                    {
                        size: 3,
                        links: [
                            {
                                title: "Type",
                                url: "",
                                links: [
                                    { title: "iPhone", url: "/shop/products-listing?type=iphone" },
                                    { title: "Smartphones", url: "/shop/products-listing?type=smartphones" },
                                    { title: "Basic Phones", url: "/shop/products-listing?type=basicphones" },
                                    { title: "Tablets", url: "/shop/products-listing?type=tablets" },
                                ],
                            },
                            // { title: 'Tab 10', url: '', links: [] },
                            // { title: 'Samsung Notebook', url: '', links: [] },
                            // { title: 'Samsung Watch', url: '', links: [] },
                            // { title: 'Riveters', url: '', links: [] },
                            {
                                title: "Tablet & Ipad",
                                url: "",
                                links: [
                                    { title: "IPad",url: "/shop/products-listing?type=ipad" },
                                    { title: "Kindle", url: "/shop/products-listing?type=Kindle"  },
                                    // { title: 'Brush Cutters', url: '' },
                                ],
                            },
                        ],
                    },
                    {
                        size: 3,
                        links: [
                            {
                                title: "Operating System",
                                url: "",
                                links: [
                                    { title: "Android", url: "/shop/products-listing?OS=android"},
                                    { title: "IOS", url: "/shop/products-listing?OS=ios"},
                                ],
                            },
                            {
                                title: "RAM Capacity",
                                url: "",
                                links: [
                                    { title: "2 GB", url: "/shop/products-listing?RAM=2 GB" },
                                    { title: "3 GB", url: "/shop/products-listing?RAM=3 GB"},
                                    { title: "4 GB", url: "/shop/products-listing?RAM=4 GB"},
                                    { title: "6 GB", url: "/shop/products-listing?RAM=6 GB" },
                                    { title: "8 GB", url: "/shop/products-listing?RAM=8 GB" },
                                    { title: "12 GB",url: "/shop/products-listing?RAM=12 GB"},
                                    // { title: 'Brush Cutters', url: '' },
                                ],
                            },
                        ],
                    },
                    {
                        size: 2,
                        links: [
                            {
                                title: "Internal Storage",
                                url: "",
                                links: [
                                    { title: "8 GB", url: "/shop/products-listing?Storage=8 GB"  },
                                    { title: "16 GB", url: "/shop/products-listing?Storage=16 GB"  },
                                    { title: "32 GB", url: "/shop/products-listing?Storage=32 GB"  },
                                    { title: "64 GB", url: "/shop/products-listing?Storage=64 GB"  },
                                ],
                            },
                            // {
                            //     title: 'Battery Capacity',
                            //     url: '',
                            //     links: [
                            //         { title: '1199 mAh and Below', url: '' },
                            //         { title: '1600 - 2199 mAh', url: '' },
                            //         { title: '2200 - 2999 mAh', url: '' },
                            //         { title: '3000 - 3999 mAh', url: '' },
                            //     ],
                            // },
                        ],
                    },
                    // {
                    //     size: 2,
                    //     links: [
                    //         {
                    //             title: 'Color',
                    //             url: '',
                    //             links: [
                    //                 { title: 'Titanium', url: '' },
                    //                 { title: 'Interstellar Glow', url: '' },
                    //                 { title: 'Midnight Black', url: '' },
                    //                 { title: 'Midnight Green', url: '' },
                    //             ],
                    //         },
                    //         {
                    //             title: 'Mobile Accessories',
                    //             url: '',
                    //             links: [
                    //                 { title: 'Head Phones', url: '' },
                    //                 { title: 'Earphones', url: '' },
                    //                 { title: 'Power Banks', url: '' },
                    //                 { title: 'Bluetooth Headsets', url: '' },
                    //             ],
                    //         },
                    //     ],
                    // },
                ],
            },
        },
    },
    // {
    //     title: 'Mobile & Tablets',
    //     url: '',
    //     submenu: {
    //         type: 'megamenu',
    //         menu: {
    //             size: 'nl',
    //             columns: [
    //                 {
    //                     size: 6,
    //                     links: [
    //                         {
    //                             title: 'Samsung',
    //                             url: '',
    //                             links: [
    //                                 { title: 'Samsung Galaxy M21', url: '' },
    //                                 { title: 'Samsung Galaxy Note10 Lite', url: '' },
    //                                 { title: 'Samsung Galaxy Note 20 Ultra 5G', url: '' },
    //                                 { title: 'Samsung Galaxy S10', url: '' },
    //                             ],
    //                         },
    //                         {
    //                             title: 'MI (Xiaomi)',
    //                             url: '',
    //                             links: [
    //                                 { title: 'Redmi 8A Dual', url: '' },
    //                                 { title: 'Redmi 6 Pro', url: '' },
    //                                 { title: 'Mi Phone Redmi 8', url: '' },
    //                                 { title: 'Mi 10', url: '' },
    //                                 { title: 'Redmi Note 5 Pro', url: '' },
    //                                 { title: 'Xiaomi Mi A3', url: '' },
    //                             ],
    //                         },
    //                     ],
    //                 },
    //                 {
    //                     size: 6,
    //                     links: [
    //                         {
    //                             title: 'OPPO',
    //                             url: '',
    //                             links: [
    //                                 { title: 'OPPO A31', url: '' },
    //                                 { title: 'OPPO A12', url: '' },
    //                                 { title: 'OPPO A11K', url: '' },
    //                                 { title: 'OPPO F15', url: '' },
    //                                 { title: 'OPPO Reno3 Pro', url: '' },
    //                                 { title: 'Oppo A52', url: '' },
    //                             ],
    //                         },
    //                         {
    //                             title: 'Vivo',
    //                             url: '',
    //                             links: [
    //                                 { title: 'Vivo U10', url: '' },
    //                                 { title: 'Vivo Y15', url: '' },
    //                                 { title: 'Vivo Y83', url: '' },
    //                                 { title: 'Vivo V19', url: '' },
    //                             ],
    //                         },
    //                     ],
    //                 },
    //             ],
    //         },
    //     },
    // },
    // {
    //     title: 'Electronics',
    //     url: '',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             { title: 'LEDs / TVs', url: '' },
    //             { title: 'Washing Machines', url: '' },
    //             { title: 'Refrigerators', url: '' },
    //             { title: 'Air conditioners', url: '' },
    //             { title: 'Micro Wave', url: '' },
    //         ],
    //     },
    // },
    {
        title: "Electronics333",
        url: "/shop/catalog/electronics",
        submenu: {
            type: "doublemegamenu",
            menu: {
                size: "xl",
                image: {
                    ltr: "images/megamenu/megamenu-1-ltr.jpg",
                    rtl: "images/megamenu/megamenu-1-rtl.jpg",
                },
                columns: [
                    {
                        size: 3,
                        links: [
                            {
                                title: "Mobile & Tablets",
                                url: "",
                                links: [
                                    { title: "Samsung Galaxy M21", url: "/shop/products-listing?product=Samsung Galaxy M21" },
                                    { title: "Samsung Galaxy Note10", url: "/shop/products-listing?product=Samsung Galaxy Note10" },
                                    { title: "Samsung Galaxy Note 20", url: "/shop/products-listing?product=Samsung Galaxy Note 20" },
                                    { title: "Samsung Galaxy S10", url: "/shop/products-listing?product=Samsung Galaxy S10" },
                                ],
                            },
                            { title: "Tab 10", url: "/shop/products-listing?product=Tab 10", links: [] },
                            { title: "Samsung Notebook", url: "/shop/products-listing?product=Samsung Notebook", links: [] },
                            { title: "Samsung Watch", url: "/shop/products-listing?product=Samsung Watch", links: [] },
                            // { title: 'Riveters', url: '', links: [] },
                        ],
                    },
                    {
                        size: 3,
                        links: [    
                            {
                                title: "Washing Machines",
                                url: "washing-machines",
                                links: [
                                    { title: "Front Lead 1190", url: "/shop/products-listing?product=Front Lead 1190"},
                                    { title: "Top Loading 3984", url: "/shop/products-listing?product=Top Loading 3984"},
                                    { title: "Top Loading 27x82", url: "/shop/products-listing?product=Top Loading 27x82" },
                                    { title: "Fully Automatic", url: "/shop/products-listing?product=Fully Automatic" },
                                    { title: "Semi-Automatic", url: "/shop/products-listing?product=Semi-Automatic" },
                                    { title: "Front Loading with EcoBubble",  url: "/shop/products-listing?product=Front Loading with EcoBubble"},
                                ],
                            },
                            {
                                title: "Air Conditioner",
                                url: "",
                                links: [
                                    { title: "Split AC",url: "/shop/products-listing?type=Split AC" },
                                    { title: "Window AC",url: "/shop/products-listing?type=Window AC" },
                                    { title: "Inverter Split AC",url: "/shop/products-listing?type=Inverter Split AC" },
                                    // { title: 'Brush Cutters', url: '' },
                                ],
                            },
                        ],
                    },
                    {
                        size: 3,
                        links: [
                            {
                                title: "LEDs / TVs",
                               url: "/shop/products-listing?product=Front Loading with EcoBubble",
                                links: [
                                    { title: "Smart HD TV",url: "/shop/products-listing?type=Smart HD TV" },
                                    { title: "4k Smart Crystal UHD TV",url: "/shop/products-listing?type=4k Smart Crystal UHD TV" },
                                    { title: "8k Smart QLED TV",url: "/shop/products-listing?type=8k Smart QLED TV" },
                                    { title: "4k Smart QLED TV",url: "/shop/products-listing?type=4k Smart QLED TV" },
                                    { title: "Curved Smart QLED TV",url: "/shop/products-listing?type=Curved Smart QLED TV" },
                                ],
                            },
                        ],
                    },
                    {
                        size: 3,
                        links: [
                            {
                                title: "Micro Wave",
                                url: "",
                                links: [
                                    { title: "Convection MWO", url: "/shop/products-listing?type=Convection MWO" },
                                    { title: "MC28M6036CC Convection", url: "/shop/products-listing?type=MC28M6036CC Convection" },
                                    { title: "Convection Slim Fry", url: "/shop/products-listing?type=Convection Slim Fry" },
                                    { title: "Sun Dry", url: "/shop/products-listing?type=Sun Dry" },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    // {
    //     title: 'Shop',
    //     url: '/shop/category-grid-3-columns-sidebar',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             {
    //                 title: 'Shop Grid',
    //                 url: '/shop/category-grid-3-columns-sidebar',
    //                 submenu: [
    //                     { title: '3 Columns Sidebar', url: '/shop/category-grid-3-columns-sidebar' },
    //                     { title: '4 Columns Full', url: '/shop/category-grid-4-columns-full' },
    //                     { title: '5 Columns Full', url: '/shop/category-grid-5-columns-full' },
    //                 ],
    //             },
    //             { title: 'Shop List', url: '/shop/category-list' },
    //             { title: 'Shop Right Sidebar', url: '/shop/category-right-sidebar' },
    //             {
    //                 title: 'Product',
    //                 url: '/shop/product-standard',
    //                 submenu: [
    //                     { title: 'Product', url: '/shop/product-standard' },
    //                     { title: 'Product Alt', url: '/shop/product-columnar' },
    //                     { title: 'Product Sidebar', url: '/shop/product-sidebar' },
    //                 ],
    //             },
    //             { title: 'Cart', url: '/shop/cart' },
    //             { title: 'Checkout', url: '/shop/checkout' },
    //             { title: 'Order Success', url: '/shop/checkout/success' },
    //             { title: 'Wishlist', url: '/shop/wishlist' },
    //             { title: 'Compare', url: '/shop/compare' },
    //             { title: 'Track Order', url: '/shop/track-order' },
    //         ],
    //     },
    // },
    // {
    //     title: 'Account',
    //     url: '/account',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             { title: 'Login', url: '/account/login' },
    //             { title: 'Dashboard', url: '/account/dashboard' },
    //             { title: 'Edit Profile', url: '/account/profile' },
    //             { title: 'Order History', url: '/account/orders' },
    //             { title: 'Order Details', url: '/account/orders/5' },
    //             { title: 'Address Book', url: '/account/addresses' },
    //             { title: 'Edit Address', url: '/account/addresses/5' },
    //             { title: 'Change Password', url: '/account/password' },
    //         ],
    //     },
    // },
    // {
    //     title: "Battery & Inverters",
    //     url: "/shop/catalog/battery-inverters",
    //     submenu: {
    //         type: "menu",
    //         menu: [
    //             { title: "Batteries", url: "/shop/catalog/battery" },
    //             { title: "Inverters", url: "/shop/catalog/inverters" },
    //             {
    //                 title: "Solar",
    //                 url: "",
    //                 submenu: [
    //                     { title: "One-Grid", url: "/shop/catalog/solar-on-grid" },
    //                     { title: "Off-Grid", url: "/shop/catalog/solar-off-grid" },
    //                 ],
    //             },
    //         ],
    //     },
    // },
    // {
    // title: 'Blog',
    // url: '/blog/category-classic',
    // submenu: {
    //     type: 'menu',
    //     menu: [
    //         { title: 'Blog Classic', url: '/blog/category-classic' },
    //         { title: 'Blog Grid', url: '/blog/category-grid' },
    //         { title: 'Blog List', url: '/blog/category-list' },
    //         { title: 'Blog Left Sidebar', url: '/blog/category-left-sidebar' },
    //         { title: 'Post Page', url: '/blog/post-classic' },
    //         { title: 'Post Without Sidebar', url: '/blog/post-full' },
    //     ],
    // },
    // },
    // {
    //     title: 'Pages',
    //     url: '/site/about-us',
    //     submenu: {
    //         type: 'menu',
    //         menu: [
    //             { title: 'About Us', url: '/site/about-us' },
    //             { title: 'Contact Us', url: '/site/contact-us' },
    //             { title: 'Contact Us Alt', url: '/site/contact-us-alt' },
    //             { title: '404', url: '/site/not-found' },
    //             { title: 'Terms And Conditions', url: '/site/terms' },
    //             { title: 'FAQ', url: '/site/faq' },
    //             { title: 'Components', url: '/site/components' },
    //             { title: 'Typography', url: '/site/typography' },
    //         ],
    //     },
    // },
    // {
    //     title: 'Buy Theme',
    //     url: 'https://themeforest.net/item/stroyka-tools-store-react-ecommerce-template/23909258',
    //     props: {
    //         external: true,
    //         target: '_blank',
    //     },
    // },
];
