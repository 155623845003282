export default {
    name: "Ralson Shop",
    fullName: "Ralson Shop",
    url: "",
    author: {
        name: "Cyberframe",
        profile_url: "https://cyberframe.in",
    },
    contacts: {
        address: "Ralson Nagar, G.t. Road, Ludhiana - 141003, Punjab (India)",
        email: "ho@ralson.com or info@ralson.com",
        phone: "+91-161­-2511501 upto 510",
        website: "https://www.ralson.com/",
    },
    contacts1: {
        address:
            "906, 9th Floor, Aggarwal Corporate Heights, Plot No. A-7, Netaji Subhash ,place, Pitam Pura, Delhi-110034",
        email: "ho@ralson.com or info@ralson.com",
        phone: "+91-11­-45734353",
        website: "https://www.ralsonshop.com",
    },
    contacts2: {
        address: "J-38, Udyog Nagar, Rohtak Road, New Delhi-41 (India)",
        phone: "+91-11-25473917-18",
        fax: "91-11-25473735",
    },
    timings: "Mon-Sat 10:00 AM - 5:00 PM",
    about: "In 2009, Ralson Sports marked Ralson’s journey into high-street sports goods procurement and retail business. Cricket has been earmarked as the game in focus for the brand, which has led Ralson to market its very own, complete head-to-toe cricketing gear. During this time, the company opened two upmarket sport-outlets in Rajouri Garden and Lajpat Nagar, New Delhi, the latter being inaugurated by none other than, the legendary, Rahul Dravid.",
};
