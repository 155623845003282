// react
import React, { useEffect }  from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';  
// data stubs
import addresses from '../../data/accountAddresses';
import allOrders from '../../data/accountOrders';
import theme from '../../data/theme';
import {AuthData} from '../../common/AuthCheck';
import {getAddresslist} from "../../store/address/addressActions"
import {getorders} from '../../store/order'
import Moment from 'react-moment';

export  function AccountPageDashboard({getAddresslist,address,getorders,orderdata,loading}) { 
    useEffect(() => {
        getAddresslist();
        getorders()
     }, []);
    address = address[0] !=undefined?address[0]:[];
    const authdata = JSON.parse(localStorage.getItem('auth'));
    console.log("auth data coming here",authdata);  
    let orders = orderdata.slice(0, 3).map((order) => (
        <tr key={order.id}>
           <td><Link to={"/account/orders/"+order.id} onClick={()=>{
                  localStorage.setItem('detailorder'+order.id,JSON.stringify(order));
   
                }}>{`#${order.id}`}</Link></td>
                <td><Moment format="DD/MM/YYYY">{order.created_at}</Moment></td>  
                <td>{order.status}</td>
                <td>{order.formated_sub_total}</td>
        </tr>
    ));
    if(orderdata.length<1)
    {
        orders =(
        <tr >
            <td colSpan="4" style={{'text-align':'center'}}>
                 No Recent Order Available.
               </td>
        </tr>
    )
    }

    return (
        <div className="dashboard">
            <Helmet>
                <title>{`My Account — ${theme.name}`}</title>
            </Helmet>

            <div className="dashboard__profile card profile-card">
                <div className="card-body profile-card__body">
                    <div className="profile-card__avatar">
                        <img src="images/avatars/avatar-3.jpg" alt="" />
                    </div>
    <div className="profile-card__name">{authdata.data.first_name} {authdata.data.last_name}</div>
                    <div className="profile-card__email">{authdata.data.email}</div>
                    <div className="profile-card__edit">
                        <Link to="profile" className="btn btn-secondary btn-sm">Edit Profile</Link>
                    </div>
                </div>
            </div>
            {address.length>0?
            <div className="dashboard__address card address-card address-card--featured">
                {<div className="address-card__badge">Default Address</div>}
                <div className="address-card__body">
                    <div className="address-card__name">{`${address.firstName} ${address.lastName}`}</div>
                    <div className="address-card__row">
                        {address.country}
                        <br />
                        {address.postcode}
                        ,
                        {address.city}
                        <br />
                        {address.address}
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Phone Number</div>
                        <div className="address-card__row-content">{address.phone}</div>
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Email Address</div>
                        <div className="address-card__row-content">{address.email}</div>
                    </div>
                    <div className="address-card__footer">
                        <Link to="/account/addresses/5">Edit Address</Link>
                    </div>
                </div>
            </div>:''}
            <div className="dashboard__orders card">
                <div className="card-header">
                    <h5>Recent Orders</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapDispatchToProps = {
   
    getAddresslist,
    getorders
};
const mapStateToProps =(state) => {
  
    return {
    productdetailvariation:state.productvariations,
    address:state.address,
    orderdata:state.order.items,
    ordermeta:state.order.meta,
    loading:state.order.loading
  }
}
export default connect(
    mapStateToProps,
     mapDispatchToProps,
 
 )(AccountPageDashboard);