import {
    NEWS_LIST,
    NEWS_LIST_SUCCESS,
    NEWS_LIST_FAILED,
   BLOG_LIST,
   BLOG_LIST_SUCCESS,
   BLOG_LIST_FAILED,
} from "./newsActionTypes";

/*
 * item example:
 * {
 *   id: 1,
 *   product: {...}
 *   options: [
 *     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
 *   ],
 *   price: 250,
 *   quantity: 2,
 *   total: 500
 * }
 * extraLine example:
 * {
 *   type: 'shipping',
 *   title: 'Shipping',
 *   price: 25
 * }
 */
const initialState = {
    loading: 0,
    items: [],
};

export default function newsReducer(state = initialState, action) {
    switch (action.type) {
        case NEWS_LIST:
            return { ...state, ...{items: action.items} };
        case NEWS_LIST_SUCCESS:
            return { ...state, ...{items: action.items} };
        case NEWS_LIST_FAILED:
            return { ...state, ...{items: action.items} };
        default:
            return state;
    }
}

const initialblogState = {
    loading: 0,
    items: [],
};

export  function blogReducer(state = initialblogState, action) {
    switch (action.type) {
        case BLOG_LIST:
            return { ...state, ...{ items: action.items,loading:true } };
        case BLOG_LIST_SUCCESS:
            return { ...state, ...{ items: action.items,loading:false } };
        case BLOG_LIST_FAILED:
            return { ...state, ...{ items: action.items,loading:false } };
        default:
            return state;
    }
}
