import { toast } from 'react-toastify';
import { getProductDetailAdditionals } from '../product';
import { productPoint } from '../product/endpoint';
import { COMPARE_ADD_ITEM, COMPARE_REMOVE_ITEM } from './compareActionTypes';

export function compareAddItemSuccess(product) {
    toast.success(`Product "${product.name}" added to compare!`);

    return {
        type: COMPARE_ADD_ITEM,
        product,
    };
}

export function compareRemoveItemSuccess(productId) {
    return {
        type: COMPARE_REMOVE_ITEM,
        productId,
    };
}

export function compareAddItem(product) {
    // sending request to server, timeout is used as a stub
    
    return (dispatch) => (
        new Promise((resolve) => {
            fetch( productPoint.additions + "/" + product.id)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                //dispatch(productDetailAdditionalsSuccess(response));
                //return response;
                setTimeout(() => {
                    dispatch(compareAddItemSuccess({...product,...{[product.id]:response.data}}));
                    resolve();
                }, 500);
            })
            .catch(function (thrown) {
              //  dispatch(productDetailAdditionalsFailed(thrown));
            });
        //   dispatch(getProductDetailAdditionals(product.id));   
            
        })
    );
}

export function compareRemoveItem(productId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(compareRemoveItemSuccess(productId));
                resolve();
            }, 500);
        })
    );
}
