import { combineReducers } from "redux";

// reducers
import cartReducer from "./cart";
import compareReducer from "./compare";
import currencyReducer from "./currency";
import localeReducer from "./locale";
import mobileMenuReducer from "./mobile-menu";
import { MenuReducer } from "./mobile-menu/mobileMenuReducer";
import quickviewReducer from "./quickview";
import sidebarReducer from "./sidebar";
import version from "./version";
import wishlistReducer from "./wishlist";
import bannerReducer from "./banners";
import productReducer from "./product";

import {
    newProductReducer,
    brandReducer,
    detailProductReducer,
    detailProductAdditionalsReducer,
    detailProductVariationsReducer,
    productFiltersReducer,
    searchProductReducer,
    relatedProductReducer,
    productBannersReducer,
    sizesReducer,
} from "./product/productReducer";
import { recentViewedReducer } from "./product/productReducer";
import topDealReducer from "./topdeals";
import newsReducer from "./news";
import clientReducer from "./client";

import { ordersReducer, orderItemReturnRequestReducer, orderItemReturnListReducer } from "./order/orderReducer";
import categoryReducer from "./category";
import { categoryParentSubMenuReducer, categorySubMenuReducer, categorySubReducer, FilterReducer } from "./category/categoryReducer";
import { wishlistDataReducer } from "./wishlist/wishlistReducer";
import { cartListReducer } from "./cart/cartReducer";  
import authReducer from "./auth";

import {
    SaveAddressReducer,
    SaveShippingReducer,
    SavePaymentMethodReducer,
    PaymentMethodListReducer,
    SaveOrderMethodReducer,
    shippingListReducer,
} from "./checkout/checkoutReducer";
import { stateReducer } from "./auth/authReducer";
import addressReducer from "./address/addressReducer";
import reviewReducer from "./review/reviewReducer";
import pageReducer, { contactReducer } from "./page/pageReducer";
import { blogReducer } from "./news/newsReducer";

export default combineReducers({
    version: (state = version) => state,
    cart: cartReducer,
    compare: compareReducer,
    currency: currencyReducer,
    locale: localeReducer,
    mobileMenu: mobileMenuReducer,
    quickview: quickviewReducer,
    sidebar: sidebarReducer,
    wishlist: wishlistReducer,
    banners: bannerReducer,
    productlist: productReducer,
    newarrivals: newProductReducer,
    topdeals: topDealReducer,
    recent: recentViewedReducer,
    news: newsReducer,
    client: clientReducer,
    order: ordersReducer,

    categories: categoryReducer,
    brands: brandReducer,
    productdetail: detailProductReducer,
    productadditions: detailProductAdditionalsReducer,
    productvariations: detailProductVariationsReducer,
    productfilter: productFiltersReducer,
    filtersearchdata: FilterReducer,
    menu: MenuReducer,
    wishlistdata: wishlistDataReducer,
    auth: authReducer,
    saveaddress: SaveAddressReducer,
    saveshipping: SaveShippingReducer,
    savepayment: SavePaymentMethodReducer,
    saveorder: SaveOrderMethodReducer,
    states: stateReducer,
    address: addressReducer,
    searchProduct: searchProductReducer,
    review: reviewReducer,
    related: relatedProductReducer,
    page: pageReducer,
    blog: blogReducer,
    probanner: productBannersReducer,
    paymentmethods: PaymentMethodListReducer,
    orderreturn: orderItemReturnRequestReducer,
    returnlist: orderItemReturnListReducer,
    sizes: sizesReducer,
    contact: contactReducer,
    subcat: categorySubReducer,
    submenucat: categorySubMenuReducer,
    menusubcat: categoryParentSubMenuReducer,
    shippinglist:shippingListReducer 
});  
