// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import InputNumber from "../shared/InputNumber";
import PageHeader from "../shared/PageHeader";
import { cartRemoveItem, cartUpdateQuantities, cartItemListGet, addCoupon, RemoveCoupon } from "../../store/cart";
import { Cross12Svg } from "../../svg";
import { url } from "../../services/utils";

// data stubs
import theme from "../../data/theme";
import { toast } from "react-toastify";
import BlockLoader from "../blocks/BlockLoader";

class ShopPageCart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cartupdated: false,
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
            couponcode: "",
        };

        this.submitCoupons = this.submitCoupons.bind(this);
        this.couponCodeType = this.couponCodeType.bind(this);
        this.RemoveCoupon = this.RemoveCoupon.bind(this);
    }

    componentDidMount() {
        console.log("cart item list Get ");
        this.props.cartItemListGet();
    }

    getItemQuantity(item) {
        const { quantities } = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);

        return quantity ? quantity.value : item.quantity;
    }

    handleChangeQuantity = (item, quantity) => {
        this.setState((state) => {
            const stateQuantity = state.quantities.find((x) => x.itemId === item.id);

            if (!stateQuantity) {
                state.quantities.push({ itemId: item.id, value: quantity });
            } else {
                stateQuantity.value = quantity;
            }

            return {
                quantities: state.quantities,
            };
        });
        this.setState({ cartupdated: true });
    };

    /***** coupon  type  **/
    couponCodeType(event) {
        console.log("event data ", event.target.value);
        this.setState({ couponcode: event.target.value });
    }

    /**** Submit coupons ****/
    submitCoupons(event) {
        if (this.state.couponcode == "") {
            toast.error("Please Enter Coupon code");
        } else {
            document.getElementById("input-coupon-code").value = "";
            localStorage.setItem("coupon", this.state.couponcode);
            this.props.addCoupon({ code: this.state.couponcode });
            this.setState({ couponcode: this.state.couponcode });
        }
    }

    /****** Remove Coupon */
    RemoveCoupon() {
        localStorage.removeItem("coupon");

        this.props.RemoveCoupon(this.state.couponcode);
        this.setState({ couponcode: "" });
    }

    cartNeedUpdate() {
        const { quantities } = this.state;
        const { cart } = this.props;

        return (
            quantities.filter((x) => {
                const item = cart.items.find((item) => item.id === x.itemId);

                return item && item.quantity !== x.value && x.value !== "";
            }).length > 0
        );
    }

    renderItems() {
        const { cart, cartRemoveItem } = this.props;
        console.log("cart items data", cart);

        return cart.cartlist.items.map((item) => {
            let image;
            let options;

            if (item.product.images.length > 0) {
                image = (
                    <div className="product-image">
                        <Link to={url.product(item.product)} className="product-image__body">
                            <img className="product-image__img" src={item.product.images[0].url} alt="" />
                        </Link>
                    </div>
                );
            }

            if (item.options != undefined && item.options.length > 0) {
                options = (
                    <ul className="cart-table__options">
                        {item.options.map((option, index) => (
                            <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                        ))}
                    </ul>
                );
            }

            const removeButton = (
                <AsyncAction
                    action={() => cartRemoveItem(item.id, item.id)}
                    render={({ run, loading }) => {
                        const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                            "btn-loading": loading,
                        });

                        return (
                            <button type="button" onClick={run} className={classes}>
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );

            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">{image}</td>
                    <td className="cart-table__column cart-table__column--product">
                        <Link to={url.product(item.product)} className="cart-table__product-name">
                            {item.product.name}
                        </Link>
                        {options}
                    </td>
                    <td className="cart-table__column cart-table__column--price" data-title="Price">
                        <Currency value={item.price} />
                    </td>
                    <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                        <InputNumber
                            onChange={(quantity) => this.handleChangeQuantity(item, quantity)}
                            value={this.getItemQuantity(item)}
                            min={1}
                        />
                    </td>
                    <td className="cart-table__column cart-table__column--total" data-title="Total">
                        <Currency value={item.total} />
                    </td>
                    <td className="cart-table__column cart-table__column--remove">{removeButton}</td>
                </tr>
            );
        });
    }

    renderTotals() {
        const { cart } = this.props;

        if (cart.extraLines.length <= 0) {
            return null;
        }

        /* const extraLines = cart.cartlist.items.map((extraLine, index) => {
            let calcShippingLink;

            if (extraLine.type === 'shipping') {
                calcShippingLink = <div className="cart__calc-shipping"><Link to="/">Calculate Shipping</Link></div>;
            }

            return (
                <tr key={index}>
                    <th>{extraLine.title}</th>
                    <td>
                        <Currency value={extraLine.price} />
                        {calcShippingLink}
                    </td>
                </tr>
            );
        }); */

        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>Subtotal</th>
                        <td>
                            <Currency value={cart.cartlist.subtotal} />
                        </td>
                    </tr>
                    {cart.cartlist.coupon_code != null ? (
                        <tr>
                            <th>Discount</th>
                            <td>
                                <Currency value={cart.cartlist.discount} />
                            </td>
                        </tr>
                    ) : (
                        ""
                    )}
                </thead>
                <tbody className="cart__totals-body"></tbody>
            </React.Fragment>
        );
    }

    updatecart(quantities) {
        const { cart, cartUpdateQuantities } = this.props;
        this.setState({ cartupdated: false });
        cartUpdateQuantities(quantities);
    }

    renderCart() {
        const { cart, cartUpdateQuantities } = this.props;
        const { quantities, cartupdated } = this.state;
        const coupon = localStorage.getItem("coupon");

        console.log("cart data coming here", cart, coupon);
        let that = this;
        const updateCartButton = (
            <AsyncAction
                action={() => {
                    this.updatecart(quantities);
                }}
                render={({ run, loading }) => {
                    const classes = classNames("btn btn-primary cart__update-button", {
                        "btn-loading": cart.cartlist.loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes} disabled={!cartupdated}>
                            Update Cart
                        </button>
                    );
                }}
            />
        );

        return (
            <div className="cart block">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image">Image</th>
                                <th className="cart-table__column cart-table__column--product">Product</th>
                                <th className="cart-table__column cart-table__column--price">Price</th>
                                <th className="cart-table__column cart-table__column--quantity">Quantity</th>
                                <th className="cart-table__column cart-table__column--total">Total</th>
                                <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">{this.renderItems()}</tbody>
                    </table>
                    <div className="cart__actions">
                        {cart.cartlist.coupon_code == null ? (
                            <form className="cart__coupon-form">
                                <label htmlFor="input-coupon-code" className="sr-only">
                                    Password
                                </label>
                                <input
                                    type="text"
                                    onKeyUp={this.couponCodeType}
                                    className="form-control"
                                    id="input-coupon-code"
                                    placeholder="Coupon Code"
                                />
                                <button
                                    type="button"
                                    onClick={this.submitCoupons}
                                    className={classNames("btn btn-primary ", {
                                        "btn-loading": cart.coupon.loading,
                                    })}
                                >
                                    Apply Coupon
                                </button>
                            </form>
                        ) : (
                            <div>
                                <div className="font-weight-bold">Applied Coupon</div>
                                <div
                                    className={classNames("couponcode ", {
                                        "btn-loading": cart.coupon.loading,
                                    })}
                                >
                                    {cart.coupon.loading ? "processing..." : cart.cartlist.coupon_code}
                                    {cart.coupon.loading ? (
                                        ""
                                    ) : (
                                        <button
                                            type="button"
                                            onClick={this.RemoveCoupon}
                                            className="button button-danger close "
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="cart__buttons">
                            <Link to="/" className="btn btn-light">
                                Continue Shopping
                            </Link>
                            {updateCartButton}
                        </div>
                    </div>

                    <div className="row justify-content-end pt-md-5 pt-4">
                        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Cart Totals</h3>
                                    <table className="cart__totals">
                                        {this.renderTotals()}
                                        <tfoot className="cart__totals-footer">
                                            <tr>
                                                <th>Total</th>
                                                <td>
                                                    <Currency value={cart.cartlist.grandtotal} />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <Link
                                        to="/shop/checkout"
                                        className="btn btn-primary btn-xl btn-block cart__checkout-button"
                                    >
                                        Proceed to checkout
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { cart } = this.props;
        const breadcrumb = [
            { title: "Home", url: "" },
            { title: "Shopping Cart", url: "" },
        ];

        let content;

        if (cart.cartlist.items.length > 0) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            <div className="block-empty__message">Your shopping cart is empty!</div>
                            <div className="block-empty__actions">
                                <Link to="/" className="btn btn-primary btn-sm">
                                    Continue
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Shopping Cart — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Shopping Cart" breadcrumb={breadcrumb} />

                {cart.cartlist.loading ? <BlockLoader /> : content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    cartRemoveItem,
    cartUpdateQuantities,
    cartItemListGet,
    addCoupon,
    RemoveCoupon,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
