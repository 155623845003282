import {
    ORDERS_LIST,
    ORDERS_LIST_SUCCESS,
    ORDERS_LIST_FAILED,
    ORDERS_DETAIL,
    ORDERS_DETAIL_SUCCESS,
    ORDERS_DETAIL_FAILED,
    ORDER_RETURN_REQUEST,
    ORDER_RETURN_REQUEST_SUCCESS,
    ORDER_RETURN_REQUEST_FAILED,
    ORDER_RETURN_LIST,
    ORDER_RETURN_LIST_SUCCESS,
    ORDER_RETURN_LIST_FAILED
} from "./orderActionTypes";

/*
 * item example:
 * {
 *   id: 1,
 *   product: {...}
 *   options: [
 *     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
 *   ],
 *   price: 250,
 *   quantity: 2,
 *   total: 500
 * }
 * extraLine example:
 * {
 *   type: 'shipping',
 *   title: 'Shipping',
 *   price: 25
 * }
 */
const initialState = {
    loading: 0,
    items: [],
    meta: { current_page: 1, total: 0 },
    orderdetail: { items: {}, loading: false },
};

export function ordersReducer(state = initialState, action) {
    console.log("checking action typre");
    switch (action.type) {
        case ORDERS_LIST:
            return { ...state, ...{ items: [], meta: { current_page: 1, total: 0 }, loading: true } };
        case ORDERS_LIST_SUCCESS:
            return { ...state, ...{ items: action.items.data, meta: action.items.meta, loading: false } };
        case ORDERS_LIST_FAILED:
            return { ...state, ...{ items: [], meta: { current_page: 1, total: 0 }, loading: false } };
        case ORDERS_DETAIL:
            return {
                ...state,
                ...{
                    orderdetail: { items: {}, loading: true },
                },
            };
        case ORDERS_DETAIL_SUCCESS:
            return {
                ...state,
                ...{
                    orderdetail: {
                        items: action.items,
                        loading: false,
                    },
                },
            };
        case ORDERS_DETAIL_FAILED:
            return {
                ...state,
                ...{
                    orderdetail: { items: {}, loading: true },
                },
            };
        default:
            return state;
    }
}

export function orderItemReturnRequestReducer(state = initialState, action) {
    console.log("checking action typre");
    switch (action.type) {
        case ORDER_RETURN_REQUEST:
            return { ...state, ...{ items: [], meta: { current_page: 1, total: 0 }, loading: true } };
        case ORDER_RETURN_REQUEST_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false } };
        case ORDER_RETURN_REQUEST_FAILED:
            return { ...state, ...{ items: [], loading: false } };

        default:
            return state;
    }
}




export function orderItemReturnListReducer(state = initialState, action) {
    console.log("checking action typre");
    switch (action.type) {
        case ORDER_RETURN_LIST:
            return { ...state, ...{ items: [], meta: { current_page: 1, total: 0 }, loading: true } };
        case ORDER_RETURN_LIST_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false } };
        case ORDER_RETURN_LIST_FAILED:
            return { ...state, ...{ items: [], loading: false } };

        default:
            return state;
    }
}

export default {};
