// react
import React from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import { Cart16Svg } from "../../svg";
import { cartAddItem } from "../../store/cart";
import { url } from "../../services/utils";

function Suggestions(props) {
    const { context, className, products, cartAddItem } = props;

    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);
    //console.log(" Product List Suggestion",product);
    const list =
        products.length > 0 &&
        products.map((product) => {
            console.log("variations data coming here", product.variants);
            if (product.variants != undefined && product.variants.length > 0) {
                product.price = product.variants[0].price;
                product.special_price = product.variants[0].special_price;
            }
            let price;
            if (parseFloat(product.special_price) < parseFloat(product.price)) {
                price = <Currency value={product.special_price} />;
            } else {
                price = <Currency value={product.price} />;
            }
            return (
                <li key={product.id} className="suggestions__item">
                    {product.images && product.images.length > 0 && (
                        <div className="suggestions__item-image product-image">
                            <div className="product-image__body">
                                <img className="product-image__img" src={product.images[0].url} alt="" />
                            </div>
                        </div>
                    )}
                    <div className="suggestions__item-info">
                        <Link className="suggestions__item-name" to={url.product(product)}>
                            {product.name}
                        </Link>
                        <div className="suggestions__item-meta">SKU: {product.sku}</div>
                    </div>
                    <div className="suggestions__item-price">{price}</div>

                    {product.in_stock && product.type == "simple" ? (
                        <div className="suggestions__item-actions">
                            <AsyncAction
                                action={() => cartAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        onClick={run}
                                        title="Add to cart"
                                        className={classNames("btn btn-primary btn-sm btn-svg-icon", {
                                            "btn-loading": loading,
                                        })}
                                    >
                                        <Cart16Svg />
                                    </button>
                                )}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                </li>
            );
        });

    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">{list}</ul>
        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);
