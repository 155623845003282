import { toast } from "react-toastify";
import {
    REVIEW_LIST,
    REVIEW_LIST_SUCCESS,
    REVIEW_LIST_FAILED,
    CREATE_REVIEW,
    CREATE_REVIEW_SUCCESS,
    CREATE_REVIEW_FAILED
} from "./reviewActionTypes";

import { reviewPoint } from "./endpoint";
import { Logintoken } from "../../common";
import { authCheckNull } from "../../common/AuthCheck";






/****** save addresss Success *******/
function reviewSuccess(data) {

    
    return {
        type: REVIEW_LIST_SUCCESS,
        items: data,
    };
}

/****** save addresss Failed *******/
function reviewFailed(data) {
    return {
        type: REVIEW_LIST_FAILED,
        items: data,
    };
}

/******** Review Address Save  Async Action  */
export function listReview(id,page) {
    return (dispatch) =>
        new Promise((resolve) => {
            dispatch({type:REVIEW_LIST,loading:true,items:[]})
              setTimeout(() => {
                fetch(reviewPoint.list+'?product_id='+id+(!authCheckNull? "&token="+Logintoken():'')+'&limit=10&page='+page)
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        console.log("response coming here", response);
                        dispatch(reviewSuccess(response)); 
                        resolve();
                    })
                    .catch(function (thrown) {
                        toast.error(thrown.message);
                        dispatch(reviewFailed(thrown));
                    });
            }, 500);
        });
}



/****** Create Review Success *******/
function createReviewSuccess(data) {

    
    return {
        type: CREATE_REVIEW_SUCCESS,
        items: data,
    };
}

/****** save addresss Failed *******/
function createReviewFailed(data) {
    return {
        type: CREATE_REVIEW_FAILED,
        items: data,
    };
}

/******** Create Review Save  Async Action  *****/
export function createReview(data,id) {
    return (dispatch) =>
        new Promise((resolve) => { 
            dispatch({type:CREATE_REVIEW,loading:true,items:[]})
              setTimeout(() => {
                fetch(reviewPoint.list+'/'+id+'/create?token='+Logintoken(),{
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                        "Content-Type": "application/json",
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(data), // body data type must match "Content-Type" header
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        console.log("response coming here", response);
                        toast.success("Your Review is submitted successfully. Ones approved ,will be visible on website");
                        dispatch(createReviewSuccess(response)); 
                        dispatch(listReview(id))
                        resolve();
                    })
                    .catch(function (thrown) {
                        toast.error(thrown.message);
                        dispatch(createReviewFailed(thrown));
                    });
            }, 500);
        });
}