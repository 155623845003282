export const ADDRESS_LIST = "ADDRESS_LIST";
export const ADDRESS_LIST_FAILED = "ADDRESS_LIST_FAILED";
export const ADDRESS_LIST_SUCCESS = "ADDRESS_LIST_SUCCESS";

/***** Address Create Address  */
export const CREATE_ADDRESS = "CREATE_ADDRESS";
export const CREATE_ADDRESS_FAILED = "CREATE_ADDRESS_FAILED";
export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS";


/***** Address Update Address   */
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const UPDATE_ADDRESS_FAILED = "UPDATE_ADDRESS_FAILED";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";

/******** Edit Address Data */
export const ADDRESS_EDIT = "ADDRESS_EDIT";
export const ADDRESS_EDIT_FAILED = "ADDRESS_EDIT_FAILED";
export const ADDRESS_EDIT_SUCCESS = "ADDRESS_EDIT_SUCCESS";
