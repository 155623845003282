import { toast } from "react-toastify";
import {
    PAGE_LIST,
    PAGE_LIST_SUCCESS,
    PAGE_LIST_FAILED,
    CONTACT_REQUEST,
    CONTACT_REQUEST_SUCCESS,
    CONTACT_REQUEST_FAILED,
    CONTACT_REQUEST_SUBSCRIBE,
    CONTACT_REQUEST_SUBSCRIBE_SUCCESS,
    CONTACT_REQUEST_SUBSCRIBE_FAILED,
} from "./pageActionTypes";
import { pagePoint } from "./endpoint";

export function pageSuccess(pages) {
    return {
        type: PAGE_LIST_SUCCESS,
        items: pages.data,
    };
}

export function pageFailed(error) {
    return {
        type: PAGE_LIST_FAILED,
        items: [],
        error: error,
    };
}

export function getpage(slug) {
    // sending request to server, timeout is used as a stub
    console.log("get pages coming here");
    return (dispatch) => {
        dispatch({ type: PAGE_LIST, items: [], loading: true });
        fetch(pagePoint.content + "/" + slug)
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                console.log("coming in response here", data);
                if (data.data == null) {
                    dispatch(pageSuccess({ data: { page_title: "404", html_content: "Page Not found" } }));
                } else {
                    dispatch(pageSuccess(data));
                }
            })
            .catch(function (thrown) {
                console.log("coming in response on Failed", thrown);
                dispatch(pageFailed(thrown));
            });
    };
}

export function getpageContent(slug) {
    // sending request to server, timeout is used as a stub
    console.log("get pages coming here");
    return (dispatch) => {
        dispatch({ type: PAGE_LIST, items: [], loading: true });
        fetch(pagePoint.content + "/" + slug)
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                console.log("coming in response here", data);
                if (data.data == null) {
                    dispatch(pageSuccess({ data: { page_title: "404", html_content: "Page Not found" } }));
                } else {
                    dispatch(pageSuccess(data));
                }
            })
            .catch(function (thrown) {
                console.log("coming in response on Failed", thrown);
                dispatch(pageFailed(thrown));
            });
    };
}

/******** Contact Form  Request  Action  *****/

export function contactRequestSuccess(data) {
    return {
        type: CONTACT_REQUEST_SUCCESS,
        items: data,
    };
}

export function contactRequestFailed(error) {
    return {
        type: CONTACT_REQUEST_FAILED,
        items: [],
        error: error,
    };
}

export function contactRequest(data, addToast) {
    return (dispatch) => {
        dispatch({ type: CONTACT_REQUEST, items: [], loading: true });
        fetch(pagePoint.contact, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                console.log("coming in response here", data);
                if (data.status == 200) {
                    toast.success(data.message);
                    dispatch(contactRequestSuccess(data));
                } else {
                    toast.error(data.message);
                    dispatch(contactRequestFailed(data));
                }
            })
            .catch(function (thrown) {
                console.log("coming in response on Failed", thrown);
                dispatch(contactRequestFailed(thrown));
            });
    };
}

/******** Contact Form  Request  Action  *****/

export function contactSubscribeRequestSuccess(data) {
    return {
        type: CONTACT_REQUEST_SUBSCRIBE_SUCCESS,
        items: data,
    };
}

export function contactSubscribeRequestFailed(error) {
    return {
        type: CONTACT_REQUEST_SUBSCRIBE_FAILED,
        items: [],
        error: error,
    };
}

export function contactSubscribeRequest(data, addToast) {
    return (dispatch) => {
        dispatch({ type: CONTACT_REQUEST_SUBSCRIBE, items: [], loading: true });
        fetch(pagePoint.subscribe, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                console.log("coming in response here", data);
                if (data.status == 200) {
                    toast.success(data.message);
                    dispatch(contactSubscribeRequestSuccess(data));
                } else {
                    toast.error(data.message);
                    dispatch(contactSubscribeRequestFailed(data));
                }
            })
            .catch(function (thrown) {
                console.log("coming in response on Failed", thrown);
                dispatch(contactSubscribeRequestFailed(thrown));
            });
    };
}
