import { toast } from "react-toastify";
import {
    CHECKOUT_SAVE_ADDRESS,
    CHECKOUT_SAVE_ADDRESS_SUCCESS,
    CHECKOUT_SAVE_ADDRESS_FAILED,
    CHECKOUT_SAVE_PAYMENT_METHOD,
    CHECKOUT_SAVE_PAYMENT_METHOD_SUCCESS,
    CHECKOUT_SAVE_PAYMENT_METHOD_FAILED,
    CHECKOUT_SAVE_SHIPPING_METHOD,
    CHECKOUT_SAVE_SHIPPING_METHOD_FAILED,
    CHECKOUT_SAVE_SHIPPING_METHOD_SUCCESS,
    CHECKOUT_SAVE_ORDER_METHOD,
    CHECKOUT_SAVE_ORDER_METHOD_SUCCESS,
    CHECKOUT_SAVE_ORDER_METHOD_FAILED,
    PAYMENT_METHOD_LIST_GET_SUCCESS,
    PAYMENT_METHOD_LIST_GET_FAILED,
    PAYMENT_METHOD_LIST_GET,
    ORDERS_DETAIL,
    ORDERS_DETAIL_SUCCESS,
    ORDERS_DETAIL_FAILED,
    SHIPPING_LIST_GET,
    SHIPPING_LIST_GET_SUCCESS,
    SHIPPING_LIST_GET_FAILED,
} from "./checkoutActionTypes";
import { CART_EMPTY } from "../cart/cartActionTypes";
import { checkoutPoint } from "./endpoint";
import { frontUrl, Logintoken } from "../../common";
import { authCheckNull } from "../../common/AuthCheck";
import { cartItemListGet } from "../cart";

/****** save addresss Success *******/
function checkoutSaveAddressSuccess(data) {
    toast.success("Address Saved Successfully");
    return {
        type: CHECKOUT_SAVE_ADDRESS_SUCCESS,
        items: data,
        addressSaved: true,
    };
}

/****** save addresss Failed *******/
function checkoutSaveAddressFailed(data) {
    toast.error(data);
    return {
        type: CHECKOUT_SAVE_ADDRESS_FAILED,
        items: [],
        addressSaved: false,
    };
}

/******** Checkout Address Save  Async Action  */
export function checkoutSaveAddress(data) {
    const logintoken = !authCheckNull() ? "?token=" + Logintoken() : "";
    return (dispatch) =>
        new Promise((resolve) => {
            dispatch({ type: CHECKOUT_SAVE_ADDRESS, loading: true, items: [] });
            setTimeout(() => {
                fetch(checkoutPoint.save_address + logintoken, {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                        "Content-Type": "application/json",
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(data), // body data type must match "Content-Type" header
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        console.log("response coming here", response);
                        //  toast.success("Address Saved Successdully!");
                        localStorage.setItem("save_address_response", JSON.stringify(response));
                        dispatch(checkoutSaveAddressSuccess(response));

                        resolve();
                        //return response;
                    })
                    .catch(function (thrown) {
                        toast.error("Some Error occured , Please Contact Admin");
                        dispatch(checkoutSaveAddressFailed(thrown));
                    });
            }, 500);
        });
}

/****** save addresss Success *******/
function checkoutSaveShippingSuccess(data) {
    // console.log("shipping data data", data);
    return {
        type: CHECKOUT_SAVE_SHIPPING_METHOD_SUCCESS,
        items: data,
    };
}

/****** save addresss Failed *******/
function checkoutSaveShippingFailed(data) {
    return {
        type: CHECKOUT_SAVE_SHIPPING_METHOD_FAILED,
        items: [],
    };
}

/******** Checkout Address Save  Async Action  */
export function checkoutSaveShipping(data, payment = "") {
    const logintoken = !authCheckNull() ? "?token=" + Logintoken() + "&" : "?";
    return (dispatch) =>
        new Promise((resolve) => {
            dispatch({ type: CHECKOUT_SAVE_SHIPPING_METHOD, loading: true, items: [] });

            setTimeout(() => {
                fetch(
                    checkoutPoint.save_shipping_method +
                        logintoken +
                        "shipping_method=" +
                        data.shipping_method +
                        "&shipping_method_name=" +
                        data.shipping_method_name +
                        "&shippingdata=" +
                        data.shippingdata
                )
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        console.log("response coming here", response);
                        dispatch(checkoutSaveShippingSuccess(response));
                        if (payment != "") {
                            dispatch(checkoutSavePayment(payment));
                        } else {
                            dispatch(cartItemListGet());
                        }
                        resolve();
                        //return response;
                    })
                    .catch(function (thrown) {
                        ///toast.error(thrown.message);
                        dispatch(checkoutSaveShippingFailed(thrown));
                        dispatch({ type: CHECKOUT_SAVE_ORDER_METHOD_FAILED, loading: false, items: [] });
                    });
            }, 500);
        });
}

/****** save addresss Success *******/
function checkoutSavePaymentSuccess(data) {
    return {
        type: CHECKOUT_SAVE_PAYMENT_METHOD_SUCCESS,
        items: data,
    };
}

/****** save addresss Failed *******/
function checkoutSavePaymentFailed(data) {
    return {
        type: CHECKOUT_SAVE_PAYMENT_METHOD_FAILED,
        items: data,
    };
}

/******** Checkout Address Save  Async Action  */
export function checkoutSavePayment(data) {
    const logintoken = !authCheckNull() ? "?token=" + Logintoken() : "";
    return (dispatch) =>
        new Promise((resolve) => {
            dispatch({ type: CHECKOUT_SAVE_PAYMENT_METHOD, loading: true, items: [] });
            setTimeout(() => {
                fetch(checkoutPoint.save_payment_method + logintoken, {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                        "Content-Type": "application/json",
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(data), // body data type must match "Content-Type" header
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        console.log("response coming here", response);
                        dispatch(checkoutSavePaymentSuccess(response));
                        console.log("Method data coming here", data);
                        if (data.payment.method == "razorpay") {
                            const billingdata = JSON.parse(localStorage.getItem("save_address_response"));
                            return false;
                        } else if (data.payment.method == "payumoney") {
                            const billingdata = JSON.parse(localStorage.getItem("save_address_response"));
                            window.location.href =
                                checkoutPoint.pay4u +
                                "?email=" +
                                billingdata.data.cart.billing_address.email +
                                "&firstname=" +
                                billingdata.data.cart.billing_address.first_name +
                                "" +
                                billingdata.data.cart.billing_address.last_name +
                                "&phone=" +
                                billingdata.data.cart.billing_address.phone +
                                (!authCheckNull() ? "&token=" + Logintoken() : "");
                            localStorage.removeItem("save_address_response");
                            return false;
                            // localStorage.removeItem("redux_localstorage_simple");
                        } else {
                            dispatch(checkoutSaveOrder(response.data));
                        }

                        // dispatch(checkoutSaveOrder(response.data))
                        resolve();
                        //return response;
                    })
                    .catch(function (thrown) {
                        ///toast.error(thrown.message);
                        dispatch(checkoutSavePaymentFailed(thrown));
                    });
            }, 500);
        });
}

/****** save addresss Success *******/
function checkoutSaveOrderSuccess(data) {
    return {
        type: CHECKOUT_SAVE_ORDER_METHOD_SUCCESS,
        items: data,
    };
}

/****** save addresss Failed *******/
function checkoutSaveOrderFailed(data) {
    return {
        type: CHECKOUT_SAVE_ORDER_METHOD_FAILED,
        items: data,
    };
}

/******** Checkout Address Save  Async Action  */
export function checkoutSaveOrder(data) {
    const logintoken = !authCheckNull() ? "?token=" + Logintoken() : "";
    return (dispatch) =>
        new Promise((resolve) => {
            dispatch({ type: CHECKOUT_SAVE_ORDER_METHOD, loading: true, items: [] });
            setTimeout(() => {
                fetch(checkoutPoint.save_order + logintoken, {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                        "Content-Type": "application/json",
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(data), // body data type must match "Content-Type" header
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        console.log("response coming here", response);
                        dispatch(checkoutSaveOrderSuccess(response));
                        if (response.status == 500) {
                            toast.error(response.message);
                            return false;
                        }
                        toast.success("Order placed Successfully!");
                        localStorage.setItem("recentorderdata", JSON.stringify(response.order));
                        dispatch({ type: CART_EMPTY, loading: true, items: [], quantity: 0, subtotal: 0, total: 0 });
                        localStorage.removeItem("state");
                        localStorage.removeItem("save_address_response");
                        localStorage.removeItem("recentorderdata");
                        window.location.href = frontUrl + "/ordersuccess/" + response.order.increment_id;
                        resolve();
                        //return response;
                    })
                    .catch(function (thrown) {
                        ///toast.error(thrown.message);
                        localStorage.removeItem("state");
                        console.log("message content data ", thrown);
                        toast.error(thrown.message);
                        // window.location.href = frontUrl + "/shop/checkout";
                        dispatch(checkoutSaveOrderFailed(thrown));
                    });
            }, 500);
        });
}

/******* Get All Payment Methods ***************/

/****** Payment Method List Success *******/
function paymentMethodListSuccess(data) {
    return {
        type: PAYMENT_METHOD_LIST_GET_SUCCESS,
        items: data.data,
    };
}

/****** Payment Method List Failed *******/
function paymentMethodListFailed(data) {
    return {
        type: PAYMENT_METHOD_LIST_GET_FAILED,
        items: data,
    };
}
/******** Payment Methods List Async Action  */
export function getPaymentMethodsList(data) {
    return (dispatch) =>
        new Promise((resolve) => {
            dispatch({
                type: PAYMENT_METHOD_LIST_GET,
                loading: true,
                items: [],
            });
            setTimeout(() => {
                fetch(checkoutPoint.payment_methods_list)
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        console.log("response coming here", response);
                        dispatch(paymentMethodListSuccess(response));

                        resolve();
                        //return response;
                    })
                    .catch(function (thrown) {
                        ///toast.error(thrown.message);
                        dispatch(paymentMethodListFailed(thrown));
                    });
            }, 500);
        });
}

/****** Payment List Success *******/
function getShippingMethodSuccess(data) {
    return {
        type: SHIPPING_LIST_GET_SUCCESS,
        items: data.data,
        minRateShipping: data.minRateShipping,
        minRateAmongMinDays: data.minRateAmongMinDays,
    };
}

/****** Payment Method List Failed *******/
function getShippingMethodFailed(data) {
    return {
        type: SHIPPING_LIST_GET_FAILED,
        items: data,
    };
}

function getLowestPriceShipping(allData) {
    return allData.reduce((prev, curr) => {
        return prev.rate < curr.rate ? prev : curr;
    });
}

function getLowestPriceAmongLowestDays(all_Data) {
    return all_Data.reduce((prev, curr) => {
        if (prev.estimated_delivery_days <= curr.estimated_delivery_days) {
            return prev.rate < curr.rate ? prev : curr;
        }
        return prev.estimated_delivery_days < curr.estimated_delivery_days ? prev : curr;
    });
}

/******** Payment Methods List Async Action  */
export function getShippingMethodsList(data) {
    return (dispatch) =>
        new Promise((resolve) => {
            dispatch({
                type: SHIPPING_LIST_GET,
                loading: true,
                items: [],
                minRateShipping: [],
                minRateAmongMinDays: [],
            });
            setTimeout(() => {
                fetch(checkoutPoint.shipping_list + "?shippingzipcode=" + data)
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (response) {
                        response.minRateShipping = getLowestPriceShipping(response.data);
                        response.minRateAmongMinDays = getLowestPriceAmongLowestDays(response.data);

                        // console.log(getLowestPriceShipping(response.data), "aaaaaaaaaa");
                        // console.log(getLowestPriceAmongLowestDays(response.data), "bbbbbbbbbb");
                        dispatch(getShippingMethodSuccess(response));
                        resolve();
                        //return response;
                    })
                    .catch(function (thrown) {
                        ///toast.error(thrown.message);
                        dispatch(getShippingMethodFailed(thrown));
                    });
            }, 500);
        });
}
