import { MOBILE_MENU_CLOSE, MOBILE_MENU_OPEN,MENU_LIST,MENU_LIST_SUCCESS, MENU_LIST_FAILED } from './mobileMenuActionTypes';

const initialState = {
    open: false,
};

export default function mobileMenuReducer(state = initialState, action) {
    switch (action.type) {
    case MOBILE_MENU_OPEN:
        return {
            ...state,
            open: true,
        };
    case MOBILE_MENU_CLOSE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
}


const initialMenuState = {
    loading: false,
    list: [],
    eleitem: [],
    batteryitem: [],
};

export function MenuReducer(state = initialMenuState, action) {
    switch (action.type) {
    case MENU_LIST:
        return {
            ...state,
            ...{ list: action.items, elelist: action.eleitem, batteryitem: []},
        };
        case MENU_LIST_SUCCESS:
            return {
                ...state,
                ...{ list: action.items, elelist: action.eleitem, batteryitem: action.batteryitem },
            };
            case MENU_LIST_FAILED:
                return {
                    ...state,
                    ...{list:action.items}
                };
    default:
        return state;
    }
}
