export const CART_ADD_ITEM          = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM       = 'CART_REMOVE_ITEM';
export const CART_UPDATE_QUANTITIES = 'CART_UPDATE_QUANTITIES';
export const CART_LIST_REQUEST      = 'CART_LIST_REQUEST';
export const CART_LIST_SUCCESS      = 'CART_LIST_SUCCESS';
export const CART_LIST_FAILED       = 'CART_LIST_FAILED';
export const CART_EMPTY             = 'CART_EMPTY';

/******* Coupon Action  ******/
export const COUPON_ADDED = "COUPON_ADDED"  
export const COUPON_ADDED_SUCCESS = "COUPON_ADDED_SUCCESS"
export const COUPON_ADDED_FAILED = "COUPON_ADDED_FAILED"


/******* Coupon DeleteAction  ******/
export const COUPON_DELETE = "COUPON_DELETE"  
export const COUPON_DELETE_SUCCESS = "COUPON_DELETE_SUCCESS"
export const COUPON_DELETE_FAILED = "COUPON_DELETE_FAILED"