import React from 'react';
import ContentLoader from 'react-content-loader'

const staticWidth = 870;
let rows = '';

let screenWidth = window.innerWidth;

if ( screenWidth > 1200 ) {
    rows = 5
}
else if ( screenWidth > 575 ) {
    rows = 2;
}
else{
    rows = 1;
}

const Catalog = ({
    width = '100%',
    heading = { width: 120, height: 30 },
    row =1,
    column = rows,
    padding = 30,
    borderRadius = 4,
    ...props
  }) => {
    const list = []
  
    let height
  
    for (let i = 1; i <= row; i++) {
      for (let j = 0; j < column; j++) {
        const itemWidth = (staticWidth - padding * (column + 1)) / column
  
        const x = padding + j * (itemWidth + padding)
  
        const height1 = itemWidth - 5
  
        const height2 = 20
  
        const height3 = 20
  
        const space =
          padding + height1 + (padding / 2 + height2) + height3 + padding * 2
  
        const y1 = space * (i - 1)
  
        const y2 = y1 + padding + height1
  
        const y3 = y2 + padding / 2 + height2

        console.log("this is x",x, y1, itemWidth)
  
        list.push(
          <>
            <rect
              x={x}
              y={y1}
              rx={borderRadius}
              ry={borderRadius}
              width={itemWidth}
              height={height1}
            />
            <rect x={x} y={y2} rx={0} ry={0} width={itemWidth} height={height2} />
            <rect
              x={x}
              y={y3}
              rx={0}
              ry={0}
              width={itemWidth * 0.6}
              height={height3}
            />
          </>
        )
  
        if (i === row) {
          height = y3 + height3
        }
      }
    }
  
    return (
      <ContentLoader
        viewBox={`0 0 ${staticWidth} ${height}`}
        width={width}
        height={'auto'}
        {...props}
      >
        {/* {heading && (
          <rect
            x={padding}
            y={padding}
            rx={0}
            ry={0}
            width={heading.width}
            height={heading.height}
          />
        )} */}
        {list}
      </ContentLoader>
    )
  }
  
  Catalog.metadata = {
    name: 'I am Doong - I come from Việt Nam',
    github: 'toiladoong',
    description: 'CatalogMagic',
    filename: 'CatalogMagic',
  }

export default Catalog;