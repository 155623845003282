// react
import React,{ Fragment, useEffect } from "react";

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import StroykaSlick from '../shared/StroykaSlick';

import { getpage } from "../../store/page";
import { connect } from "react-redux";

// data stubs
import theme from '../../data/theme';
import BlockLoader from "../blocks/BlockLoader";

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function SitePageContent({ location, content, match, getpage }) {
    console.log("match paramsn data coming herer",match)
      useEffect(() => {
          getpage(match.params.slug);
      }, [match.params.slug]);  
    return (
        <div className="block about-us">
            <Helmet>
                <title>{`${content.items.page_title} — ${theme.name}`}</title>
            </Helmet>

            <div className="about-us__image" style={{ backgroundImage: 'url("images/aboutus.jpg")' }} />
            <div className="container">
            {content.loading ? (
                                    <BlockLoader />
                                ) : (
                                    <span dangerouslySetInnerHTML={{ __html: content.items.html_content }}></span>
                                )}
            </div>
        </div>
    );  
}

const mapStateToProps = (state) => {
    return {
        content: state.page,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getpage: (pageslug) => {
            dispatch(getpage(pageslug));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SitePageContent);

