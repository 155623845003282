// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {getProductDetailAdditionals} from '../../store/product/productActions';
// application
import ProductTabDescription from './ProductTabDescription';
import ProductTabSpecification from './ProductTabSpecification';
import ProductTabReviews from './ProductTabReviews';

class ProductTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTab: 'specification',
        };
    }

    componentDidMount()
    {   
        console.log("data product id coming here ",this.props);
        if(this.props.product.variants !=undefined && this.props.product.variants.length>0)
        {
            console.log("attribute check in tab")
            this.props.getProductDetailAdditionals(this.props.product.variants[0].id);
        }
        else
        {

            this.props.getProductDetailAdditionals(this.props.productid);
        }
         
        
      


    }

    setTab = (newTab) => {
        this.setState(() => ({ currentTab: newTab }));
    };

    render() {
        const { currentTab } = this.state;
        const { withSidebar,content } = this.props;
        const classes = classNames('product-tabs', {
            'product-tabs--layout--sidebar': withSidebar,
        });
        console.log("description data coming here", this.props);
        const tabs = [
          
            { key: 'specification', title: 'Specification', content: <ProductTabSpecification /> },
            { key: 'reviews', title: 'Reviews', content: <ProductTabReviews productid={this.props.productid} /> },
        ];

        const tabsButtons = tabs.map((tab) => {
            const classes = classNames('product-tabs__item', {
                'product-tabs__item--active': currentTab === tab.key,
            });

            return <button key={tab.key} type="button" onClick={() => this.setTab(tab.key)} className={classes}>{tab.title}</button>;
        });

        const tabsContent = tabs.map((tab) => {
            const classes = classNames('product-tabs__pane', {
                'product-tabs__pane--active': currentTab === tab.key,
            });

            return <div key={tab.key} className={classes}>{tab.content}</div>;
        });

        return (
            <div className={classes}>
                <div className="product-tabs__list">
                    {tabsButtons}
                </div>
                <div className="product-tabs__content">
                    {tabsContent}
                </div>
            </div>
        );
    }
}

ProductTabs.propTypes = {
    withSidebar: PropTypes.bool,
    content:PropTypes.any,
    productid:PropTypes.number.isRequired,
    product:PropTypes.any

};

ProductTabs.defaultProps = {
    withSidebar: false,
};
const mapDispatchToProps =  {
 getProductDetailAdditionals
}

const mapStateToProps =(state) => {
  
    return {
    
    
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(ProductTabs);

