import { CLIENTS_LIST, CLIENTS_LIST_SUCCESS, CLIENTS_LIST_FAILED } from "./clientActionTypes";

/*
 * item example:
 * {
 *   id: 1,
 *   product: {...}
 *   options: [
 *     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
 *   ],
 *   price: 250,
 *   quantity: 2,
 *   total: 500
 * }
 * extraLine example:
 * {
 *   type: 'shipping',
 *   title: 'Shipping',
 *   price: 25
 * }
 */
const initialState = {
    loading: 0,
    items: [],
};

export default function clientsReducer(state = initialState, action) {
    switch (action.type) {
        case CLIENTS_LIST:
            return { ...state, action };
        case CLIENTS_LIST_SUCCESS:
            return { ...state, action };
        case CLIENTS_LIST_FAILED:
            return { ...state, action };
        default:
            return state;
    }
}
