// react
import React, { useState } from "react";

// third-party
import { Link } from "react-router-dom";

// application
import Indicator from "./Indicator";
import { Person20Svg } from "../../svg";
import { authCheckNull, AuthData } from "../../common/AuthCheck";
import classNames from "classnames";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { login, register, OtploginSend, OtpVerifySend, OtploginSuccess } from "../../store/auth";
import BlockLoader from "../blocks/BlockLoader";

export function IndicatorAccount({
    OtploginSend,
    registerAction,
    registerloading,
    OtpVerifySend,
    auth,
    loginAction,
    OtploginSuccess,
}) {
    const [EmailLogin, setEmaiLogin] = useState({ email: "", password: "" });
    const [login, setlogin] = useState({ phone: "" });
    const [loginVal, setloginVal] = useState({
        mobileerror1: false,
        mobileerror2: false,
        emailerror1: false,
        emailerror2: false,
    });
    const [Otp, setotp] = useState({ otp: "", mobile: "", getCartItemFromState: "" });
    const [OtpVal, setOtpVal] = useState({ otperror: false });
    // const [EmailLogin, setEmaiLogin] = useState({ email: "", password: "" });
    const [EmailLoginVal, setEmaiLoginVal] = useState({ emailerror: false, passerror: "" });
    const is_loggedin = authCheckNull();
    const authdata = AuthData();

    /****** Handle register ****/
    const handleLogin = (event) => {
        let key = event.currentTarget.name;
        let value = event.target.value;
        console.log("key name ", event);
        setlogin({ ...login, [key]: value });
    };

    /****** Handle OTP Verify ****/
    const handleOtpVerify = (event) => {
        let key = event.currentTarget.name;
        let value = event.target.value;
        console.log("key name ", event);
        setotp({ ...Otp, [key]: value });
    };

    useEffect(() => {
        // window.addEventListener("beforeunload", localStorage.removeItem("state"));
        OtploginSuccess([]);
    }, []);

    /****** Resend Otp  ********/
    const resendOtp = () => {
        login.phone = localStorage.getItem("authmobile");
        OtploginSend(login);
    };

    /****** On change event set values */
    const setLoginEmaillogin = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        setEmaiLogin({ ...EmailLogin, ...{ [key]: value } });
    };

    // const sssubmit = (event) => {
    //     document.getElementById("password").addEventListener("keydown", function (event) {
    //         if (event.code === "Enter" || event.code === "NumpadEnter") {
    //             // event.preventDefault();
    //             document.getElementById("login__btn__").click();
    //         }
    //     });
    // };

    const showBtn = () => {
        setTimeout(function () {
            document.getElementById("resendOtp1").style.display = "block";
            document.getElementById("resendOtp2").style.display = "none";
        }, 30000);

        // var time_limit = 30;
        // var time_out = setInterval(() => {
        // if(time_limit == 0) {
        //     document.getElementById('resendOtp1').style.display = 'block';
        //     document.getElementById('resendOtp2').style.display = 'none';
        //     document.getElementById('timer').style.display = 'none';
        // } else {
        //     if(time_limit < 10) {
        //     time_limit = time_limit;
        //     }
        //     console.log('time_limit======== ' + time_limit);
        //     document.getElementById('timer').innerHTML = time_limit;
        //     time_limit -= 1;
        // }
        // }, 1000);
    };

    /****** Handle Login submit */
    const handleLoginSubmit = (event) => {
        // console.log("email confirmation logs", loginVal);
        // if (login.phone == "") {
        //     setloginVal({ ...loginVal, mobileerror: true });
        //     return false;
        // } else {
        //     loginVal.mobileerror = false;
        // }
        // localStorage.setItem("authmobile", login.mobile);
        // OtploginSend(login);
        // document.getElementById("login-email").value = "";
        // document.getElementById("login-email").focus();

        if (login.phone == "") {
            console.log("mobile blank");
            setloginVal({
                ...loginVal,
                mobileerror1: true,
                mobileerror2: false,
                emailerror1: false,
                emailerror2: false,
            });
            return false;
        }
        if (login.phone.length > 10 || login.phone.length < 10 || isNaN(login.phone)) {
            console.log("mobile lenght error");
            setloginVal({
                ...loginVal,
                mobileerror2: true,
                mobileerror1: false,
                emailerror1: false,
                emailerror2: false,
            });
            return false;
        } else {
            console.log("mobile proper");
            loginVal.mobileerror1 = false;
            loginVal.mobileerror2 = false;
        }
        console.log("email confirmation logs", login);
        document.getElementById("mform1").reset();
        OtploginSend(login);
        showBtn();
    };

    /**** handle Login submit  ******/
    const handleEmailLoginSubmit = (event) => {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (login.phone == "") {
            setloginVal({
                ...loginVal,
                emailerror1: true,
                emailerror2: false,
                mobileerror1: false,
                mobileerror2: false,
            });
            console.log("email blank");
            return false;
        }
        if (!filter.test(login.phone)) {
            setloginVal({
                ...loginVal,
                emailerror2: true,
                emailerror1: false,
                mobileerror1: false,
                mobileerror2: false,
            });
            console.log("not an email");
            return false;
        } else {
            console.log("proper email");
            loginVal.emailerror1 = false;
            loginVal.emailerror2 = false;
        }
        if (EmailLogin.password == "") {
            setEmaiLoginVal({ ...EmailLoginVal, passerror: true });
            return false;
        } else {
            EmailLogin.passerror = false;
        }
        var getCartItemFromState = localStorage.getItem("configuration");
        if (getCartItemFromState) {
            loginAction({ email: login.phone, password: EmailLogin.password, cart: getCartItemFromState });
        } else {
            loginAction({ email: login.phone, password: EmailLogin.password });
        }
        // document.getElementById("login-email").value = "";
        // document.getElementById("password").value = "";
    };

    /****** Handle Otp Verify  Submit */
    const handleOtpSubmit = (event) => {
        //console.log("email validation ",validateEmail(login.email))

        console.log("email confirmation logs", loginVal);
        if (Otp.otp == "") {
            setOtpVal({ ...OtpVal, otperror: true });
            return false;
        } else {
            OtpVal.otperror = false;
        }
        Otp.phone = localStorage.getItem("authmobile");
        var getCartItemFromState = localStorage.getItem("configuration");
        if (getCartItemFromState) {
            Otp.cart = getCartItemFromState;
        } else {
            Otp.cart = "[]";
        }
        console.log("OTP data is sending here", Otp);
        //return false;
        OtpVerifySend(Otp);
        //loginAction(login);
    };

    const validateEmail = (emailField) => {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(emailField) == false) {
            //alert('Invalid Email Address');
            return false;
        }
        return true;
    };

    const dropdown = (
        <div className="account-menu">
            {is_loggedin ? (
                <div>
                    {!auth.OTP.data.status ? (
                        <form className="account-menu__form" id="mform1">
                            <div className="account-menu__form-title">Login In to Your Account</div>
                            <div className="form-group">
                                <label htmlFor="header-signin-email" className="sr-only">
                                    Mobile Number
                                </label>
                                <input
                                    id="login-email"
                                    type="text"
                                    name="phone"
                                    className="form-control"
                                    placeholder="Enter Mobile Number / Email"
                                    onKeyUp={handleLogin}
                                />
                                {loginVal.emailerror1 ? <p class="alert-danger">Please Enter Email Address</p> : ""}
                                {loginVal.emailerror2 ? <p class="alert-danger">Enter Valid Email Address</p> : ""}
                                {loginVal.mobileerror1 ? <p class="alert-danger">Enter Mobile Number</p> : ""}
                                {loginVal.mobileerror2 ? (
                                    <p class="alert-danger">Please Enter 10 Digit Mobile Number</p>
                                ) : (
                                    ""
                                )}
                                <div className="form-group">
                                    <label htmlFor="password"> </label>
                                    <input
                                        id="password"
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        placeholder="Enter Password"
                                        onKeyUp={setLoginEmaillogin}
                                        // onKeyDown={sssubmit}
                                    />
                                    {EmailLoginVal.passerror ? <p class="alert-danger">Please Enter password </p> : ""}
                                </div>
                            </div>

                            <div
                                className="form-group account-menu__form-button mt-0"
                                style={{ display: "flex", justifyContent: "space-between", height: "auto" }}
                            >
                                <React.Fragment>
                                    <button
                                        type="button"
                                        // id="login__btn__"
                                        onClick={handleEmailLoginSubmit}
                                        className={classNames("btn btn-primary mt-2 mt-md-3 mt-lg-2", {
                                            "btn-loading": auth.login.loading,
                                        })}
                                        style={{ padding: "10px 25px" }}
                                    >
                                        Login
                                    </button>
                                    <br />
                                    <button
                                        type="button"
                                        style={{ padding: "10px 25px" }}
                                        onClick={handleLoginSubmit}
                                        className={classNames("btn btn-primary mt-2 mt-md-3 mt-lg-2", {
                                            "btn-loading": auth.OTP.loading,
                                        })}
                                    >
                                        Request OTP
                                    </button>
                                </React.Fragment>
                            </div>
                            <div className="account-menu__form-link">
                                <Link to="/account/login">Create An Account</Link>
                            </div>
                        </form>
                    ) : (
                        // verify otp
                        <form className="account-menu__form" id="mform2">
                            <div className="account-menu__form-title">Verify OTP</div>
                            <div className="form-group">
                                <label htmlFor="header-signin-email" className="sr-only">
                                    OTP Number
                                </label>
                                <input
                                    id="login-email"
                                    type="text"
                                    name="otp"
                                    className="form-control"
                                    placeholder="Enter OTP Number"
                                    onKeyUp={handleOtpVerify}
                                />
                                {/* <div className="" id="timer"></div> */}
                                {OtpVal.otperror ? <p class="alert-danger">Please Enter valid otp </p> : ""}
                            </div>
                            <div
                                className="form-group account-menu__form-button mt-0"
                                style={{ display: "flex", justifyContent: "space-between", height: "auto" }}
                            >
                                <React.Fragment>
                                    {" "}
                                    <button
                                        type="button"
                                        style={{ padding: "10px 25px" }}
                                        onClick={handleOtpSubmit}
                                        className={classNames("btn btn-primary mt-2 mt-md-3 mt-lg-2", {
                                            "btn-loading": auth.login.loading,
                                        })}
                                    >
                                        Verify
                                    </button>{" "}
                                    <button
                                        type="button"
                                        style={{ padding: "10px 25px", display: "none" }}
                                        id="resendOtp1"
                                        onClick={resendOtp}
                                        className="btn btn-primary mt-2 mt-md-3 mt-lg-2"
                                    >
                                        Resend OTP
                                    </button>
                                    <button
                                        type="button"
                                        id="resendOtp2"
                                        className="btn btn-secondary mt-2 mt-md-3 mt-lg-2"
                                        style={{ cursor: "no-drop" }}
                                    >
                                        Resend OTP
                                    </button>{" "}
                                </React.Fragment>
                            </div>
                            <div className="account-menu__form-link">
                                <Link to="/account/login">Create An Account</Link>
                            </div>
                        </form>
                    )}

                    {/* <form className="account-menu__form" id="mform">
                        <div className="account-menu__form-title">
                            {!auth.OTP.data.status ? "Login In to Your Account" : "Verify OTP"}
                        </div>

                        <div className="form-group">
                            <label htmlFor="header-signin-email" className="sr-only">
                                {!auth.OTP.data.status ? "Mobile Number" : "OTP  Number"}
                            </label>
                            {!auth.OTP.data.status ? (
                                <input
                                    id="login-email"
                                    type="text"
                                    name="phone"
                                    className="form-control"
                                    placeholder="Enter Mobile Number / Email"
                                    onKeyUp={handleLogin}
                                />
                            ) : (
                                <input
                                    id="login-email"
                                    type="text"
                                    name="otp"
                                    className="form-control"
                                    placeholder="Enter OTP Number"
                                    onKeyUp={handleOtpVerify}
                                />
                            )}

                            {OtpVal.otperror ? <p class="alert-danger">Please Enter valid otp </p> : ""}
                            {loginVal.emailerror1 ? (
                                <p class="alert-danger">Please Enter Email Address</p>
                            ) : (
                                ""
                            )}
                            {loginVal.emailerror2 ? (
                                <p class="alert-danger">Enter Valid Email Address</p>
                            ) : (
                                ""
                            )}
                            {loginVal.mobileerror1 ? (
                                <p class="alert-danger">Enter Mobile Number</p>
                            ) : (
                                ""
                            )}
                            {loginVal.mobileerror2 ? (
                                <p class="alert-danger">Please Enter 10 Digit Mobile Number</p>
                            ) : (
                                ""
                            )}
                            {!auth.OTP.data.status ? (
                                <div className="form-group">
                                    <label htmlFor="password"> </label>
                                    <input
                                        id="password"
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        placeholder="Enter Password"
                                        onKeyUp={setLoginEmaillogin}
                                    />
                                    {EmailLoginVal.passerror ? <p class="alert-danger">Please Enter password </p> : ""}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="form-group account-menu__form-button mt-0" style={{display: "flex", justifyContent: "space-between", height:"auto"}}>
                            {auth.OTP.data.status ? (
                                <React.Fragment>
                                    {" "}
                                    <button
                                        type="button"
                                        style={{padding: "10px 25px"}}
                                        onClick={handleOtpSubmit}
                                        className="btn btn-primary mt-2 mt-md-3 mt-lg-2"
                                    >
                                        Verify
                                    </button>{" "}
                                    <button
                                        type="button"
                                        style={{padding: "10px 25px"}}
                                        onClick={resendOtp}
                                        className="btn btn-primary mt-2 mt-md-3 mt-lg-2"
                                    >
                                        Resend OTP
                                    </button>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <button
                                        type="button"
                                        onClick={handleEmailLoginSubmit}
                                        className="btn btn-primary mt-2 mt-md-3 mt-lg-2"
                                        style={{padding: "10px 25px"}}
                                    >
                                        Login
                                    </button>
                                    <br />
                                    <button
                                        type="button"
                                        style={{padding: "10px 25px"}}
                                        onClick={handleLoginSubmit}
                                        className={classNames("btn btn-primary mt-2 mt-md-3 mt-lg-2", {
                                            "btn-loading": auth.OTP.loading,
                                        })}
                                    >
                                        Request OTP
                                    </button>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="account-menu__form-link">
                            <Link to="/account/login">Create An Account</Link>
                        </div>
                    </form> */}
                </div>
            ) : (
                ""
            )}
            <div className="account-menu__divider" />

            {!is_loggedin && authdata.data ? (
                <React.Fragment>
                    <Link to="/account/dashboard" className="account-menu__user">
                        <div className="account-menu__user-avatar">
                            <img src="images/avatars/avatar-3.jpg" alt="" />
                        </div>
                        <div className="account-menu__user-info">
                            <div className="account-menu__user-name">
                                {authdata.data.first_name} {authdata.data.last_name}
                            </div>
                            <div className="account-menu__user-email">{authdata.data.email}</div>
                        </div>
                    </Link>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li>
                            <Link to="/account/profile">Edit Profile</Link>
                        </li>
                        <li>
                            <Link to="/account/orders">Order History</Link>
                        </li>
                        <li>
                            <Link to="/account/addresses">Addresses</Link>
                        </li>
                        <li>
                            <Link to="/account/password">Password</Link>
                        </li>
                    </ul>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li>
                            <Link
                                to="/"
                                onClick={() => {
                                    localStorage.removeItem("auth");
                                    localStorage.removeItem("state");
                                    localStorage.clear();
                                    window.location.reload();
                                }}
                            >
                                Logout
                            </Link>
                        </li>
                    </ul>
                </React.Fragment>
            ) : (
                ""
            )}
        </div>
    );

    return <Indicator url="/account" dropdown={dropdown} tooltip="Account" icon={<Person20Svg />} />;
}

const mapDispatchToProps = {
    OtploginSend: OtploginSend,
    registerAction: register,
    OtpVerifySend: OtpVerifySend,
    loginAction: login,
    OtploginSuccess: OtploginSuccess,
};

const mapStateToProps = (state) => {
    console.log("state banner", state);
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorAccount);
