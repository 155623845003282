// react
import React, { Component  }  from 'react';

// third-party
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// application
import Pagination from '../shared/Pagination';

import { connect } from 'react-redux';  
// data stubs
import dataOrders from '../../data/accountOrders';
import theme from '../../data/theme';

import {getorders} from '../../store/order'
import { ORDERS_LIST } from '../../store/order/orderActionTypes';
import Moment from 'react-moment';
export  class AccountPageOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: dataOrders,
            page: 1,
        };
    }

    componentDidMount()
    {
       const {getorders,dispatch} =this.props;
       getorders();
    }
    handlePageChange = (page) => {
        this.setState(() => ({ page }));
        getorders(page)
    };

    render() {
        const { page, orders } = this.state;
        const {orderdata,ordermeta,loading} = this.props;

        console.log("order list page",orderdata,ordermeta,orderdata.length);
   
        let ordersList = orderdata.map((order) => (
            <tr key={order.id}>
                <td><Link to={"/account/orders/"+order.id} onClick={()=>{
                  localStorage.setItem('detailorder'+order.id,JSON.stringify(order));
   
                }}>{`#${order.id}`}</Link></td>
                <td><Moment format="DD/MM/YYYY">{order.created_at}</Moment></td>  
                <td>{order.status}</td>
                <td>{order.formated_sub_total}</td>
            </tr>
        ));
        if(orderdata.length==0)
        {
            
            ordersList = ( <tr >
                <td colspan="4" style={{'text-align':'center'}}>No order Available!</td>
               
            </tr>);

        }

        return (
            <div className="card">
                <Helmet>
                    <title>{`Order History — ${theme.name}`}</title>
                </Helmet>

                <div className="card-header">
                    <h5>Order History</h5>
                </div>
                {!loading?<React.Fragment>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ordersList}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-footer">
                    {ordermeta.current_page!=undefined?
                    <Pagination current={ordermeta.current_page} total={ordermeta.total} onPageChange={this.handlePageChange} />
                     :''}
                    </div>
                </React.Fragment>:''}
            </div>
        );
    }
}


const mapDispatchToProps = {
   
    getorders
};
const mapStateToProps =(state) => {
    console.log("order data coming here",state.order)
    return {
    orderdata:state.order.items,
    ordermeta:state.order.meta,
    loading:state.order.loading
  }
}
export default connect(
    mapStateToProps,
     mapDispatchToProps,
 
 )(AccountPageOrders);