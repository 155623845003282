        export default [
            {
                title: 'LEDs / TVs',
                url: '',
                submenu: {  
                    type: 'megamenu',
                    menu: {
                        size: 'xl',
                        image: {
                            ltr: 'images/megamenu/led.png',
                            rtl: 'images/megamenu/led.png',
                        },
                        columns: [
                            {
                                size: 3,
                                links: [
                                    {
                                        title: 'Brand',
                                        url: '',
                                        links: [
                                            { title: 'Samsung', url: '' },
                                            { title: 'Sony', url: '' },
                                            { title: 'MI', url: '' },
                                            { title: 'LG', url: '' },
                                            { title: 'VU', url: '' },
                                        ],
                                    },
                                    {
                                        title: 'Technology',
                                        url: '',
                                        links: [
                                            { title: 'LED', url: '' },
                                            { title: 'OLED', url: '' },
                                            { title: 'QLED', url: '' },
                                            { title: 'Nanocell', url: '' },
                                        ],
                                    },
                                    // { title: 'Tab 10', url: '', links: [] },
                                    // { title: 'Samsung Notebook', url: '', links: [] },
                                    // { title: 'Samsung Watch', url: '', links: [] },
                                    // { title: 'Riveters', url: '', links: [] },
                                ],
                            },
                            {
                                size: 3,
                                links: [
                                    {
                                        title: 'Resolution',
                                        url: '',
                                        links: [
                                            { title: '4k/Ultra HD', url: '' },
                                            { title: 'Full HD', url: '' },
                                            { title: 'HD Ready', url: '' },
                                            { title: '8K/Ultra HD', url: '' },
                                        ],
                                    },
                                    {
                                        title: 'Screen Size',
                                        url: '',
                                        links: [
                                            { title: '24 inch & Below', url: '' },
                                            { title: '28-32 inch', url: '' },
                                            { title: '39-43 inch', url: '' },
                                            { title: '48-55 inch', url: '' },
                                            { title: '65-75 inch', url: '' },
                                            { title: '76-84 inch', url: '' },
                                            { title: '85 inch & Above', url: '' },
                                        ],
                                    },
                                ],
                            },
                            {
                                size: 3,
                                links: [
                                    {
                                        title: 'Operating System',
                                        url: '',
                                        links: [
                                            { title: 'Android', url: '' },
                                            { title: 'Tizen', url: '' },
                                            { title: 'Certified Android', url: '' },
                                            { title: 'Home OS', url: '' },
                                        ],
                                    },
                                ],
                            },
                            {
                                size: 3,
                                links: [
                                    {
                                        title: 'Functionality',
                                        url: '',
                                        links: [
                                            { title: 'Smart', url: '' },
                                            { title: 'Regular', url: '' },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
            {
                title: 'Washing Machines',
                url: '',
                submenu: {
                    type: 'megamenu',
                    menu: {
                        size: 'lg',
                        image: {
                            ltr: 'images/megamenu/washing.jpg',
                            rtl: 'images/megamenu/washing.jpg',
                        },
                        imageSizeStyle: 'contain',
                        columns: [
                            {
                                size: 4,
                                links: [
                                    {
                                        title: 'Brand',
                                        url: '',
                                        links: [
                                            { title: 'Samsung', url: '' },
                                            { title: 'LG', url: '' },
                                            { title: 'LG', url: '' },
                                            { title: 'IFB', url: '' },
                                            { title: 'Bosch', url: '' },
                                        ],
                                    },
                                    {
                                        title: 'Washing Type',
                                        url: '',
                                        links: [
                                            { title: 'Dryers', url: '' },
                                            { title: 'Washer Dryer Combo', url: '' },
                                        ],
                                    },
                                ],
                            },
                            {
                                size: 4,
                                links: [
                                    {
                                        title: 'Load Type',
                                        url: '',
                                        links: [
                                            { title: 'Fully Automatic Top Load', url: '' },
                                            { title: 'Semi Automatic Top Load', url: '' },
                                            { title: 'Fully Automatic Front Load', url: '' },
                                            { title: 'Washer with Dryer', url: '' },
                                            // { title: 'Slotting machines', url: '' },
                                            // { title: 'Lathes', url: '' },
                                        ],
                                    },
                                ],
                            },
                            // {
                            //     size: 4,
                            //     links: [
                            //         {
                            //             title: 'Instruments',
                            //             url: '',
                            //             links: [
                            //                 { title: 'Welding Equipment', url: '' },
                            //                 { title: 'Power Tools', url: '' },
                            //                 { title: 'Hand Tools', url: '' },
                            //                 { title: 'Measuring Tool', url: '' },
                            //             ],
                            //         },
                            //     ],
                            // },
                        ],
                    },
                },
            },
            {
                title: 'Refrigerators',
                url: '',
                // submenu: {
                //     type: 'megamenu',
                //     menu: {
                //         size: 'nl',
                //         image: {
                //             ltr: 'images/megamenu/megamenu-3-ltr.jpg',
                //             rtl: 'images/megamenu/megamenu-3-rtl.jpg',
                //         },
                //         columns: [
                //             {
                //                 size: 6,
                //                 links: [
                //                     {
                //                         title: 'Hand Tools',
                //                         url: '',
                //                         links: [
                //                             { title: 'Screwdrivers', url: '' },
                //                             { title: 'Handsaws', url: '' },
                //                             { title: 'Knives', url: '' },
                //                             { title: 'Axes', url: '' },
                //                             { title: 'Multitools', url: '' },
                //                             { title: 'Paint Tools', url: '' },
                //                         ],
                //                     },
                //                     {
                //                         title: 'Garden Equipment',
                //                         url: '',
                //                         links: [
                //                             { title: 'Motor Pumps', url: '' },
                //                             { title: 'Chainsaws', url: '' },
                //                             { title: 'Electric Saws', url: '' },
                //                             { title: 'Brush Cutters', url: '' },
                //                         ],
                //                     },
                //                 ],
                //             },
                //             {
                //                 size: 6,
                //                 links: [
                //                     {
                //                         title: 'Instruments',
                //                         url: '',
                //                         links: [
                //                             { title: 'Welding Equipment', url: '' },
                //                             { title: 'Power Tools', url: '' },
                //                             { title: 'Hand Tools', url: '' },
                //                             { title: 'Measuring Tool', url: '' },
                //                         ],
                //                     },
                //                 ],
                //             },
                //         ],
                //     },
                // },
            },
            {
                title: 'Air conditioners',
                url: '',
                // submenu: {
                //     type: 'megamenu',
                //     menu: {
                //         size: 'sm',
                //         columns: [
                //             {
                //                 size: 12,
                //                 links: [
                //                     {
                //                         title: 'Hand Tools',
                //                         url: '',
                //                         links: [
                //                             { title: 'Screwdrivers', url: '' },
                //                             { title: 'Handsaws', url: '' },
                //                             { title: 'Knives', url: '' },
                //                             { title: 'Axes', url: '' },
                //                             { title: 'Multitools', url: '' },
                //                             { title: 'Paint Tools', url: '' },
                //                         ],
                //                     },
                //                     {
                //                         title: 'Garden Equipment',
                //                         url: '',
                //                         links: [
                //                             { title: 'Motor Pumps', url: '' },
                //                             { title: 'Chainsaws', url: '' },
                //                             { title: 'Electric Saws', url: '' },
                //                             { title: 'Brush Cutters', url: '' },
                //                         ],
                //                     },
                //                 ],
                //             },
                //         ],
                //     },
                // },
            },
            {
                title: 'Micro Wave',
                url: '',
                // submenu: {
                //     type: 'menu',
                //     menu: [
                //         {
                //             title: 'Soldering Equipment',
                //             url: '',
                //             submenu: [
                //                 { title: 'Soldering Station', url: '' },
                //                 { title: 'Soldering Dryers', url: '' },
                //                 { title: 'Gas Soldering Iron', url: '' },
                //                 { title: 'Electric Soldering Iron', url: '' },
                //             ],
                //         },
                //         { title: 'Light Bulbs', url: '' },
                //         { title: 'Batteries', url: '' },
                //         { title: 'Light Fixtures', url: '' },
                //         { title: 'Warm Floor', url: '' },
                //         { title: 'Generators', url: '' },
                //         { title: 'UPS', url: '' },
                //     ],
                // },
            },
            // { title: 'LG', url: '', submenu: null },
            // { title: 'Realme', url: '', submenu: null },
            // { title: 'Carrier', url: '', submenu: null },
            // { title: 'Hitachi', url: '', submenu: null },
            // { title: 'Daikin', url: '', submenu: null },
            // { title: 'Bosch', url: '', submenu: null },
            ];
