// react
import React from "react";

// third-party
import PropTypes from "prop-types";

// application
import BlockHeader from "../shared/BlockHeader";
import ProductCard from "../shared/ProductCard";
import { connect } from "react-redux";


function BlockProducts(props) {
    const { title, layout, featuredProduct, products } = props;

    let large;
    let smalls;

    let topdealdata = [];
    if (props.topdeals.items.length > 0) {
        props.topdeals.items.forEach((element) => {
            topdealdata.push({
                id: element.id,
                name: element.name,
                sku: element.sku,
                slug: element.url_key,
                price: parseFloat(element.price),
                compareAtPrice: null,
                badges: "salsddde",
                images: element.images,
                rating: element.reviews.average_rating,
                availability: "in-stock",
                brand: null,
                in_stock: element.in_stock,
                url_key: element.url_key,
                type: element.type,
                categories: [],
                attributes: [],
                customFields: {},
            });
        });
    }

    if (topdealdata.length > 0) {
        large = (
            <div className="block-products__featured">
                <div className="block-products__featured-item">
                    <ProductCard product={topdealdata[0]} />
                </div>
            </div>
        );
    }

    if (topdealdata.length > 0) {
        const productsList = topdealdata.slice(0, 6).map((product, index) => (
            <div key={index} className="block-products__list-item">
                <ProductCard product={product} />
            </div>
        ));

        smalls = <div className="block-products__list">{productsList}</div>;
    }
    console.log("loading here please check", props.topdeals.loading);
    return (
        <div className={`block block-products block-products--layout--${layout}`}>
            <div className="container">
                <BlockHeader title={title} />

                <div className="block-products__body">
                    {layout === "large-first" && large}
                    {smalls}
                    {layout === "large-last" && large}
                </div>
            </div>
        </div>
    );
}

BlockProducts.propTypes = {
    title: PropTypes.string.isRequired,
    featuredProduct: PropTypes.object,
    products: PropTypes.array,
    layout: PropTypes.oneOf(["large-first", "large-last"]),
};

BlockProducts.defaultProps = {
    products: [],
    layout: "large-first",
};

const mapStateToProps = (state) => {
    return {
        topdeals: state.topdeals,
    };
};
export default connect(mapStateToProps)(BlockProducts);
