// react
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

// third-party
import { Helmet } from "react-helmet-async";

import { profileEdit } from "../../store/auth/";

import classNames from "classnames";

// data stubs
import theme from "../../data/theme";

export function AccountPagePassword({ profileEdit, loading }) {
    const authdata = JSON.parse(localStorage.getItem("auth"));

    const [valid, setvalid] = useState({
        new_password_error: false,
        renter_password_error: false,
    });

    const [data, setdata] = useState({
        first_name: authdata.data.first_name,
        last_name: authdata.data.last_name,
        email: authdata.data.email,
        password: "",
        password_confirmation: "",
    });

    /**** submit Update */
    const submitUpdate = () => {
        if (document.getElementById("new_password").value == "") {
            setvalid({ ...valid, ...{ new_password_error: true } });

            return false;
        } else {
            valid.new_password_error = false;
            data.password = document.getElementById("new_password").value;

            //setvalid({...valid,...{first_name_error:false}})
        }

        if (
            document.getElementById("renter_password").value == "" ||
            document.getElementById("new_password").value != document.getElementById("renter_password").value
        ) {
            setvalid({ ...valid, ...{ renter_password_error: true } });
            return false;
        } else {
            valid.last_name_error = false;
            data.password_confirmation = document.getElementById("renter_password").value;
            setvalid({ ...valid, ...{ renter_password_error: false } });
        }

        data.gender = "male";
        //data.date_of_birth ='';
        profileEdit(data);
    };

    return (
        <div className="card">
            <Helmet>
                <title>{`Change Password — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Change Password</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <div className="form-group">
                            <label htmlFor="password-new">New Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="new_password"
                                name="new_password"
                                placeholder="New Password"
                            />
                            {valid.new_password_error ? <p class="  alert-danger">Please Enter New Password </p> : ""}
                            {valid.new_password_error ? (
                                <p class="  alert-danger">Password must be at least 6 characters </p>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="password-confirm">Reenter New Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="renter_password"
                                name="renter_password"
                                placeholder="Reenter New Password"
                            />
                            {valid.renter_password_error ? (
                                <p class="  alert-danger">Password does not match to New Password </p>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="form-group mt-5 mb-0">
                            <button
                                type="button"
                                onClick={submitUpdate}
                                className={classNames("btn btn-primary", {
                                    "btn-loading": loading,
                                })}
                            >
                                Change
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapDispatchToProps = {
    profileEdit,
};
const mapStateToProps = (state) => {
    return {
        loading: state.auth.profile.loading,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountPagePassword);
