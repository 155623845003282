// react
import React, { useEffect } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// data stubs
import addresses from "../../data/accountAddresses";
import allOrders from "../../data/accountOrders";
import theme from "../../data/theme";
import { AuthData } from "../../common/AuthCheck";
import { getAddresslist } from "../../store/address/addressActions";
import { getorderReturnList } from "../../store/order";
import Moment from "react-moment";
import BlockLoader from "../blocks/BlockLoader";
import ContentLoader from "react-content-loader";

export function AccountPageRms({ getorderReturnList, loading,returnlist }) {
    useEffect(() => {
        getorderReturnList();
   
    }, []);
const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};
    let orders = returnlist.items.map((order) => (
        <tr key={order.id}>
            <td>{`#${order.id}`}</td>
            <td>{order.name}</td>
            <td>{order.quantity_returned}</td>
            <td>{order.other_reason == "" ? order.return_reason : order.other_reason}</td>
            <td>{capitalize(order.status.replace("_", " "))}</td>
            <td>
                {" "}
                <Moment format="DD/MM/YYYY">{order.returned_on}</Moment>
            </td>
        </tr>
    ));
    if (returnlist.items.length < 1) {
        orders = (
            <tr>
                <td colSpan="4" style={{ "text-align": "center" }}>
                    No Return Request Available.
                </td>
            </tr>
        );
    }

    return (
        <div className="dashboard">
            <Helmet>
                <title>{`My Account — ${theme.name}`}</title>
            </Helmet>

            <div className="dashboard__orders card">
                <div className="card-header">
                    <h5>RMS List</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    {loading ? <ContentLoader /> : ""}
                    {!loading ? (
                        <div className="table-responsive-sm">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Order Id</th>
                                        <th>Item</th>
                                        <th>Qty</th>
                                        <th> Reason</th>
                                        <th>Status</th>
                                        <th> Date</th>
                                    </tr>
                                </thead>
                                <tbody>{orders}</tbody>
                            </table>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    getorderReturnList,
};
const mapStateToProps = (state) => {
    return {
        returnlist: state.returnlist,
        loading: state.returnlist.loading,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountPageRms);
