import { toast } from "react-toastify";
import { NEWS_LIST_SUCCESS, NEWS_LIST_FAILED, BLOG_LIST, BLOG_LIST_SUCCESS, BLOG_LIST_FAILED } from "./newsActionTypes";
import { newsPoint } from "./endpoint";
import { axios } from "axios";

export function NewsSuccess(products) {
    return {
        type: NEWS_LIST_SUCCESS,
        items: products.data,
    };
}

export function NewsFailed(error) {
    return {
        type: NEWS_LIST_FAILED,
        items: [],
        error: error,
    };
}

export function getNews() {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>

    fetch(newsPoint.list)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(NewsSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(NewsFailed(thrown));
            });
        
}


export function BlogSuccess(products) {
    return {
        type: BLOG_LIST_SUCCESS,
        items: products.data,
    };
}

export function BlogFailed(error) {
    return {
        type: BLOG_LIST_FAILED,
        items: [],
        error: error,
    };
}

export function getBlog() {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        fetch(newsPoint.blog)
            .then(function (response) {
                return response.json();
            })
            .then(function (response) {
                dispatch(BlogSuccess(response));
                //return response;
            })
            .catch(function (thrown) {
                dispatch(BlogFailed(thrown));
            });
}