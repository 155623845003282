import { ApiEndpointUrl } from "../../common";
export const checkoutPoint = {
    save_address: ApiEndpointUrl + "checkout/save-address",
    save_shipping_method: ApiEndpointUrl + "shiprocket/saveShipping",
    save_payment_method: ApiEndpointUrl + "checkout/save-payment",
    save_order: ApiEndpointUrl + "checkout/save-order",
    payment_methods_list: ApiEndpointUrl + "payumoney/payment/paymentmethods",
    pay4u: ApiEndpointUrl + "payumoney/payment/direct",
    shipping_list: ApiEndpointUrl + "shiprocket/shippingrates",
};


