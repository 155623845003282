// react
import React, { Fragment, useEffect } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// application
import Currency from "../shared/Currency";
import { Check100Svg } from "../../svg";
import { connect } from "react-redux";
import Moment from "react-moment";
import { getorderdetail } from "../../store/order";
// data stubs
import order from "../../data/accountOrderDetails";
import theme from "../../data/theme";
import { url } from "../../services/utils";
import BlockLoader from "../blocks/BlockLoader";

export function ShopPageOrderSuccess({ getorderdetail, match, orderdata }) {
    useEffect(() => {
        console.log("Address id coming here", match.params.addressId);
        getorderdetail(match.params.orderid);
    }, [match.params.orderid]);
    const ordersdata = Object.keys(orderdata.orderdetail.items).length === 0 ? "" : orderdata.orderdetail.items;
    console.log("Order Page success", ordersdata);
    const items =
        ordersdata != ""
            ? ordersdata.items.map((item) => {
                  const options = (item.options || []).map((option) => (
                      <li className="order-list__options-item">
                          <span className="order-list__options-label">
                              {option.label}
                              {": "}
                          </span>
                          <span className="order-list__options-value">{option.value}</span>
                      </li>
                  ));
                  console.log("item data", item.product, url.product);
                  return (
                      <tr>
                          <td className="order-list__column-image">
                              <div className="product-image">
                                  <Link to={url.product(item.product)} className="product-image__body">
                                      {item.product.base_image != undefined ? (
                                          <img
                                              className="product-image__img"
                                              src={item.product.base_image.large_image_url}
                                              alt=""
                                          />
                                      ) : (
                                          ""
                                      )}
                                  </Link>
                              </div>
                          </td>
                          <td className="order-list__column-product">
                              <Link to={url.product(item.product)}>{item.name}</Link>
                              {options.length > 0 && (
                                  <div className="order-list__options">
                                      <ul className="order-list__options-list">{options}</ul>
                                  </div>
                              )}
                          </td>
                          <td className="order-list__column-quantity" data-title="Qty:">
                              {item.qty_ordered}
                          </td>
                          <td className="order-list__column-total">{item.formated_base_grant_total}</td>
                      </tr>
                  );
              })
            : "";

    const additionalLines = "";

    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Order Success — ${theme.name}`}</title>
            </Helmet>

            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        <Check100Svg className="order-success__icon" />
                        <h1 className="order-success__title">Thank you</h1>
                        <div className="order-success__subtitle">Your order has been received</div>
                        <div className="order-success__actions">
                            <Link to="/" className="btn btn-xs btn-secondary">
                                Go To Homepage
                            </Link>
                        </div>
                    </div>
                    {ordersdata != "" ? (
                        <React.Fragment>
                            <div className="order-success__meta">
                                <ul className="order-success__meta-list">
                                    <li className="order-success__meta-item">
                                        <span className="order-success__meta-title">Order number:</span>
                                        <span className="order-success__meta-value">{`#${ordersdata.increment_id}`}</span>
                                    </li>
                                    <li className="order-success__meta-item">
                                        <span className="order-success__meta-title">Created At:</span>
                                        <span className="order-success__meta-value">
                                            <Moment format="DD/MM/YYYY">{ordersdata.created_at}</Moment>
                                        </span>
                                    </li>
                                    <li className="order-success__meta-item">
                                        <span className="order-success__meta-title">Total:</span>
                                        <span className="order-success__meta-value">
                                            <Currency value={ordersdata.grand_total} />
                                        </span>
                                    </li>
                                    <li className="order-success__meta-item">
                                        <span className="order-success__meta-title">Payment Method:</span>
                                        <span className="order-success__meta-value">{ordersdata.payment_title}</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="card">
                                <div className="order-list">
                                    <table>
                                        <thead className="order-list__header">
                                            <tr>
                                                <th className="order-list__column-label" colSpan="2">
                                                    Product
                                                </th>
                                                <th className="order-list__column-quantity">Qty</th>
                                                <th className="order-list__column-total">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody className="order-list__products">{items}</tbody>
                                        {ordersdata.base_sub_total && (
                                            <tbody className="order-list__subtotals">
                                                <tr>
                                                    <th className="order-list__column-label" colSpan="3">
                                                        Subtotal
                                                    </th>
                                                    <td className="order-list__column-total">
                                                        <Currency value={ordersdata.base_sub_total} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="order-list__column-label" colSpan="3">
                                                        Discount
                                                    </th>
                                                    <td className="order-list__column-total">
                                                        {" "}
                                                        <Currency value={ordersdata.discount_amount} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="order-list__column-label" colSpan="3">
                                                        Shipping & Handling
                                                    </th>
                                                    <td className="order-list__column-total">
                                                        <Currency value={ordersdata.shipping_amount} />
                                                    </td>
                                                </tr>



                                            {ordersdata.tax_amount > 0 ? (
                                                <>
                                                {ordersdata.billing_address.state === "PB" || ordersdata.billing_address.state === "Punjab" ? (
                                                    <>
                                                        <tr>
                                                            <th className="order-list__column-label" colSpan="3">
                                                                CGST
                                                            </th>
                                                            <td className="order-list__column-total">
                                                                <Currency value={ordersdata.tax_amount / 2} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th className="order-list__column-label" colSpan="3">
                                                                SGST
                                                            </th>
                                                            <td className="order-list__column-total">
                                                                <Currency value={ordersdata.tax_amount / 2} />
                                                            </td>
                                                        </tr>
                                                    </>
                                                ) : (
                                                    <tr>
                                                        <th className="order-list__column-label" colSpan="3">
                                                            IGST
                                                        </th>
                                                        <td className="order-list__column-total">
                                                            <Currency value={ordersdata.tax_amount} />
                                                        </td>
                                                    </tr>
                                                )}
                                                </>
                                            ) : (
                                                <>
                                                <tr>
                                                    <th className="order-list__column-label" colSpan="3">
                                                        Total Tax
                                                    </th>
                                                    <td className="order-list__column-total">
                                                        <Currency value={ordersdata.tax_amount} />
                                                    </td>
                                                </tr>
                                                </>
                                            )}




                                            </tbody>
                                        )}
                                        <tfoot className="order-list__footer">
                                            <tr>
                                                <th className="order-list__column-label" colSpan="3">
                                                    Total
                                                </th>
                                                <td className="order-list__column-total">
                                                    <Currency value={ordersdata.grand_total} />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-sm-6 col-12">
                                    <div className="card address-card">
                                        <div className="address-card__body">
                                            <div className="address-card__badge address-card__badge--muted">
                                                Shipping Address
                                            </div>
                                            <div className="address-card__name">
                                                {`${ordersdata.shipping_address.first_name} ${ordersdata.shipping_address.last_name}`}
                                            </div>
                                            <div className="address-card__row">
                                                {ordersdata.shipping_address["address1"][0]}
                                                {ordersdata.shipping_address["address1"][0] ? <br /> : ""}
                                                {ordersdata.shipping_address.city} &nbsp; (
                                                {ordersdata.shipping_address.postcode})
                                                <br />
                                                {ordersdata.shipping_address.state}, &nbsp;
                                                {ordersdata.shipping_address.country_name}
                                            </div>
                                            <div className="address-card__row">
                                                <div className="address-card__row-title">Phone Number</div>
                                                <div className="address-card__row-content">
                                                    {ordersdata.shipping_address.phone}
                                                </div>
                                            </div>
                                            <div className="address-card__row">
                                                <div className="address-card__row-title">Email Address</div>
                                                <div className="address-card__row-content">
                                                    {ordersdata.shipping_address.email}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12  mt-sm-0 mt-3">
                                    <div className="card address-card">
                                        <div className="address-card__body">
                                            <div className="address-card__badge address-card__badge--muted">
                                                Billing Address
                                            </div>
                                            <div className="address-card__name">
                                                {`${ordersdata.billing_address.first_name} ${ordersdata.billing_address.last_name}`}
                                            </div>
                                            <div className="address-card__row">
                                                {ordersdata.billing_address["address1"][0]}
                                                {ordersdata.billing_address["address1"][0] ? <br /> : ""}
                                                {ordersdata.billing_address.city} &nbsp; (
                                                {ordersdata.billing_address.postcode})
                                                <br />
                                                {ordersdata.billing_address.state}, &nbsp;
                                                {ordersdata.billing_address.country_name}
                                            </div>
                                            <div className="address-card__row">
                                                <div className="address-card__row-title">Phone Number</div>
                                                <div className="address-card__row-content">
                                                    {ordersdata.billing_address.phone}
                                                </div>
                                            </div>
                                            <div className="address-card__row">
                                                <div className="address-card__row-title">Email Address</div>
                                                <div className="address-card__row-content">
                                                    {ordersdata.billing_address.email}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        <BlockLoader />
                    )}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    orderdata: state.order,
});

const mapDispatchToProps = {
    getorderdetail,
};
export default connect(mapStateToProps, mapDispatchToProps)(ShopPageOrderSuccess);
