// react
import React, { useEffect, useState } from "react";

// third-party
import PropTypes, { element } from "prop-types";
import queryString from "query-string";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";

// application
import BlockLoader from "../blocks/BlockLoader";
import CategorySidebar from "./CategorySidebar";
import CategorySidebarItem from "./CategorySidebarItem";
import PageHeader from "../shared/PageHeader";
import ProductsView from "./ProductsView";
import shopApi from "../../api/shop";
import WidgetFilters from "../widgets/WidgetFilters";
import WidgetProducts from "../widgets/WidgetProducts";
import { sidebarClose } from "../../store/sidebar";
import { getProduct, getProductFilters, handleResetFilters } from "../../store/product";
import { getSubCategories, resetfilter, setfilter } from "../../store/category";

// data stubs
import theme from "../../data/theme";
import { url, getCategoryParents } from "../../services/utils";
import WidgetCategories from "../widgets/WidgetCategories";

function buildQuery(options, filters) {
    const params = {};

    if (options.page !== 1) {
        params.page = options.page;
    }

    if (options.limit !== 12) {
        params.limit = options.limit;
    }

    if (options.sort !== "default") {
        params.sort = options.sort;
    }

    Object.keys(filters)
        .filter((x) => x !== "category" && !!filters[x])
        .forEach((filterSlug) => {
            if (filterSlug == "filter_price") {
                filters[filterSlug] = filters[filterSlug].split("-")[0] + "," + filters[filterSlug].split("-")[1];
            }
            params[`filter_${filterSlug}`] = filters[filterSlug];
        });

    console.log("params data", params);

    return queryString.stringify(params, { encode: false });
}

function $_GET(param) {
    var vars = {};
    window.location.href.replace("", "").replace(
        /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
        function (m, key, value) {
            // callback
            vars[key] = value !== undefined ? value : "";
        }
    );
    console.log("All Params", vars, vars[param], param);
    if (param) {
        return vars[param] ? vars[param] : null;
    }
    return vars;
}

function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};

    if (typeof query.page !== undefined) {
        optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === "string") {
        optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === "string") {
        optionValues.sort = query.sort;
    }

    return optionValues;
}

function parseQueryFilters(location) {
    const query = queryString.parse(location);
    const filterValues = {};

    Object.keys(query).forEach((param) => {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            return;
        }

        const filterSlug = mr[1];
        if (filterSlug == "filter_price") {
            query[param] = query[param].split("-")[0] + "," + query[param].split("-")[1];
        }
        filterValues[filterSlug] = query[param];
    });

    return filterValues;
}

function parseQuery(location) {
    return [parseQueryOptions(location), parseQueryFilters(location)];
}

let runfirst = 0;
function ShopPageCategory(props) {
    const {
        categorySlug,
        columns,
        viewMode,
        sidebarPosition,
        getProduct,
        products,
        dispatch,
        getProductFilters,
        filters,
        filtersearchdata,
        getSubCategories,
        subcat,
        sidebarClose,
        handleResetFilters,
        resetfilter,
        limit,
        setfilter,
    } = props;
    const offcanvas = columns === 3 ? "mobile" : "always";

    const [Limit, setLimit] = useState(12);

    parseQuery(window.location.search);

    console.log("filter search data", filtersearchdata);

    useEffect(() => {
        if (Object.keys(filtersearchdata.filters).length < 1 && window.location.search.includes("?")) {
            let urlkeys = $_GET();
            let filterval = Object.keys(urlkeys)[0];
            console.log("coming here to see if clear filter also works", filterval, {
                type: "SET_FILTER_VALUE",
                filter: filterval.replace("filter_", ""),
                value: Object.values(urlkeys)[0],
            });

            setfilter({
                type: "SET_FILTER_VALUE",
                filter: filterval.replace("filter_", ""),
                value: Object.values(urlkeys)[0],
            });
        }
        setLimit(12);
    }, [categorySlug, window.location.href]);

    useEffect(() => {
        const query = buildQuery(filtersearchdata.options, filtersearchdata.filters);
        let location = `${window.location.pathname}${query ? "?" : ""}${query}`;
        console.log("filter  content comes here", Object.keys(filtersearchdata.filters).length, window.location.href);
        if (Object.keys(filtersearchdata.filters).length < 1 && window.location.search.indexOf("?") == 0) {
            //  location = `${window.location.pathname}${window.location.search}`;
        }

        getSubCategories(categorySlug.split("?")[0]);
        getProductFilters(categorySlug.split("?")[0]);
        // console.log("Query string data linked here",props.location.search)

        console.log(
            "query data coming here to see",

            categorySlug + "&" + props.location.search.replace("?", "").replace(/filter_/g, ""),
            window.location.search.replace("?", "").replace(/filter_/g, ""),
            window.location.href
        );
        if (props.location.search !== "" && Object.keys(filtersearchdata.filters).length > 0) {
            getProduct(
                0,
                categorySlug + "&" + window.location.search.replace("?", "").replace(/filter_/g, "") + "&limit=12"
            );
        }

        if (props.location.search == "") {
            console.log(
                "Coming here as clicked point here without search params",
                categorySlug + "&" + query.replace("filter_", "").replace("filter_", "").replace("-", ",") + "&limit=12"
            );
            getProduct(
                0,
                categorySlug + "&" + query.replace("filter_", "").replace("filter_", "").replace("-", ",") + "&limit=12"
            );
        }

        sidebarClose();
        // getCategoryParents(categorySlug);
        console.log("data coming heren with search data", location);
        console.log("keys data coming here in main href", window.location.href, filtersearchdata.filters);
        if ($_GET().length < 1) {
            console.log("data coming to reset fi", location);
            resetfilter();
        }
        if ($_GET().length > 0) {
            window.history.replaceState(null, "", location);
        }
        if (Object.entries(filtersearchdata.filters).length > 0) {
            window.history.replaceState(null, "", location);
        }
    }, [categorySlug, filtersearchdata.filters, window.location.search]);
    // Load category.
    console.log("Product data loaded here ", products);

    const breadcrumb = [
        { title: "HOME", url: url.home() },
        { title: 
                categorySlug == "bmx" || categorySlug == "tube" || categorySlug == "city-bike" || categorySlug == "e-bike" ||categorySlug == "mtb" ? 'BICYCLE TYRES' : 'AUTOMOTIVE',
        },
        { title: 
                categorySlug.toUpperCase().split('-').join(' '), 
                url: url.catalog() 
        },
    ];
    let pageTitle = categorySlug.toUpperCase().split('-').join(' ');
    let content;

    /***** handle Paginate Change */
    const paginate = (event) => {
        const query = buildQuery(filtersearchdata.options, filtersearchdata.filters);
        const location = `${window.location.pathname}${query ? "?" : ""}${query}`;
        //  window.history.replaceState(null, '', location);
        console.log("Query string data linked here", query);
        getProduct(
            0,
            categorySlug +
                "&" +
                query.replace("filter_", "").replace("filter_", "").replace("-", ",") +
                "&limit=" +
                Limit +
                "&page=" +
                event
        );
    };
    /***** handle Limit Change */
    const handleLimitChange = (event) => {
        const query = buildQuery(filtersearchdata.options, filtersearchdata.filters);
        const location = `${window.location.pathname}${query ? "?" : ""}${query}`;
        window.history.replaceState(null, "", location);

        setLimit(event.target.value);
        getProduct(
            0,
            categorySlug +
                "&" +
                query.replace("filter_", "").replace("filter_", "").replace("-", ",") +
                "&limit=" +
                event.target.value +
                "&page=1"
        );
    };

    const productsView = (
        <ProductsView
            isLoading={products.loading}
            productsList={products}
            dispatch={dispatch}
            layout={viewMode}
            grid={`grid-${columns}-${columns > 3 ? "full" : "sidebar"}`}
            offcanvas={offcanvas}
            paginate={paginate}
            limit={Limit}
            handleLimitChange={handleLimitChange}
        />
    );
    console.log("filter data with non specific ", filters.productspecific.includes("10000"));
    let filterdata = [];
    filters.items.forEach((element) => {
        let type = "";
        let valuedata = "";

        if (element.code == "color") {
            type = "color";
            valuedata = $_GET(`filter_${element.code}`) != null ? $_GET(`filter_${element.code}`).split(",") : [];
        } else if (element.code == "price") {
            type = "range";
            let value = $_GET(`filter_${element.code}`) != null ? $_GET(`filter_${element.code}`).split(",") : [];
            valuedata = [];
        } else {
            type = "check";
            valuedata = $_GET(`filter_${element.code}`) != null ? $_GET(`filter_${element.code}`).split(",") : [];
        }
        console.log("filter data", $_GET(`filter_${element.code}`), element.code);
        filterdata.push({
            type: type,
            name: element.admin_name,
            slug: element.code,
            items: element.options,
            value: valuedata,
            min: 10,
            max: filters.maxprice,
            specific: filters.productspecific,
        });
    });

    console.log("subcategories data coming here", subcat);
    const sidebarComponent = (
        <CategorySidebar offcanvas={offcanvas}>
            <CategorySidebarItem>
                {" "}
                {subcat.items.length > 0 ? <WidgetCategories categories={subcat.items} /> : ""}
            </CategorySidebarItem>
            <CategorySidebarItem>
                <WidgetFilters title="Filters" offcanvas={offcanvas} filters={filterdata} dispatch={dispatch} />
            </CategorySidebarItem>
        </CategorySidebar>
    );

    if (columns > 3) {
        content = (
            <div className="container">
                <div className="block">{productsView}</div>
                {sidebarComponent}
            </div>
        );
    } else {
        const sidebar = <div className="shop-layout__sidebar">{sidebarComponent}</div>;

        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === "start" && sidebar}
                    <div className="shop-layout__content">
                        <div className="block">{productsView}</div>
                    </div>
                    {sidebarPosition === "end" && sidebar}
                </div>
            </div>
        );
    }

    console.log("Shop data coming here", filters.items);

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Shop Category Page — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header={pageTitle} breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageCategory.propTypes = {
    /**
     * Category slug.
     */
    categorySlug: PropTypes.string,
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageCategory.defaultProps = {
    columns: 3,
    viewMode: "grid",
    sidebarPosition: "start",
};

const mapDispatchToProps = {
    sidebarClose,
    getProduct,
    getProductFilters,
    resetfilter,
    getSubCategories,
    setfilter,
};
const mapStateToProps = (state) => {
    return {
        sidebarState: state.sidebar,
        page: state.category,
        products: state.productlist,
        filters: state.productfilter,
        filtersearchdata: state.filtersearchdata,
        subcat: state.subcat,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCategory);
