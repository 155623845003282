// react
import React, { useEffect } from "react";

// third-party
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";

import { getCategories, getSubMenuCategories, getParentSubCategories } from "../../store/category";
import { getNews } from "../../store/news";
// import { getMenu } from "../../store/mobile-menu";
import { connect } from "react-redux";

// application
import NavPanel from "./NavPanel";
import Search from "./Search";
import Topbar from "./Topbar";
import { RalsonLogo } from "../../svg";
import { cartItemListGet } from "../../store/cart/cartActions";
import { getWishList } from "../../store/wishlist";
import { useDispatch } from "react-redux";
import { getBrands } from "../../store/product";
function Header(props) {
    const { layout, location } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    let bannerSection;
    useEffect(() => {
        console.log("Action running here ");
        dispatch(getCategories());
        dispatch(getSubMenuCategories("bicycle"));
        dispatch(getNews());
        // dispatch(getMenu());
        dispatch(cartItemListGet());
        dispatch(getParentSubCategories("bicycle"));

        dispatch(getBrands());
        if (window.location.search == "") {
            dispatch({ type: "RESET_FILTERS" });
        }
        history.listen((location, action) => {
            console.log("location change check ", location);

            dispatch({ type: "RESET_FILTERS" });
            localStorage.removeItem("state");
        });
    }, []);
    if (layout === "default") {
        bannerSection = (
            <div className="site-header__middle container">
                <div className="site-header__logo">
                    <Link to="/">
                        <RalsonLogo />
                    </Link>
                </div>
                {/* <div className="site-header__search">
                    <Search context="header" />
                </div> */}
                {/* <div className="site-header__phone">
                    <div className="site-header__phone-title">
                        <FormattedMessage id="header.phoneLabel" defaultMessage="Customer Service" />
                    </div>
                    <div className="site-header__phone-number">
                        <FormattedMessage id="header.phone" defaultMessage="+91-18001800517" />
                    </div>
                </div> */}
            </div>
        );
    }

    return (
        <div
            className="site-header"
            // style={{
            //     backgroundImage: `url("http://ralcoshop.com/dev/public/front/images/logos/header-banner-2.gif")`,
            // }}
        >
            {/* <Topbar /> */}
            {bannerSection}
            <div className="site-header__nav-panel">
                <NavPanel layout={layout} search="true" />
            </div>
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(["default", "compact"]),
};

Header.defaultProps = {
    layout: "default",
};

const mapDispatchToProps = {
    getCategories,
    getNews,
    // getMenu,
    cartItemListGet,
    getBrands,
    getSubMenuCategories,
    getParentSubCategories
};

const mapStateToProps = (state) => {
    console.log("state banner", state);
    return {
        categorylist: state.categories,
        newlist: state.news,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
