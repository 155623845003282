// react
import React, { useEffect, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";

import { connect } from "react-redux";

import { profileEdit } from "../../store/auth/";

import classNames from "classnames";
// data stubs
import theme from "../../data/theme";

export function AccountPageProfile({ profileEdit, loading }) {
    const authdata = JSON.parse(localStorage.getItem("auth"));

    const [valid, setvalid] = useState({
        first_name_error: false,
        last_name_error: false,
        email_error: false,
        phone_error: false,
        gender_error: false,
    });

    const [data, setdata] = useState({
        first_name: authdata.data.first_name,
        last_name: authdata.data.last_name,
        email: authdata.data.email,
        phone: authdata.data.phone,
        gender: authdata.data.gender,
    });

    /**** submit Update */
    const submitUpdate = () => {
        if (document.getElementById("first_name").value == "") {
            setvalid({ ...valid, ...{ first_name_error: true } });
            return false;
        } else {
            valid.first_name_error = false;
            //setvalid({...valid,...{first_name_error:false}})
        }

        if (document.getElementById("last_name").value == "") {
            valid.last_name_error = true;
            setvalid({ ...valid, ...{ last_name_error: true } });
            return false;
        } else {
            valid.last_name_error = false;
            setvalid({ ...valid, ...{ last_name_error: false } });
        }

        if (document.getElementById("email").value == "") {
            setvalid({ ...valid, ...{ email_error: true } });
            valid.email_error = true;
            return false;
        } else {
            valid.email_error = false;
        }

        if (document.getElementById("phone").value == "") {
            setvalid({ ...valid, ...{ phone_error: true } });
            valid.phone_error = true;
            return false;
        } else {
            valid.phone_error = false;
        }

        if (document.getElementById("phone").value.toString().length != 10) {
            setvalid({ ...valid, ...{ phone_error_1: true } });
            valid.phone_error_1 = true;
            return false;
        } else {
            valid.phone_error_1 = false;
        }

        if (document.getElementById("gender").value == "") {
            setvalid({ ...valid, ...{ gender_error: true } });
            valid.gender_error = true;
            return false;
        } else {
            valid.gender_error = false;
        }

        // data.gender  ='male';
        data.first_name = document.getElementById("first_name").value;
        data.last_name = document.getElementById("last_name").value;
        data.email = document.getElementById("email").value;
        data.phone = document.getElementById("phone").value;
        data.gender = document.getElementById("gender").value;
        data.date_of_birth = "";
        profileEdit(data);
    };

    return (
        <div className="card">
            <Helmet>
                <title>{`Profile — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Edit Profile</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="profile-first-name">First Name</label>
                                    <input
                                        id="first_name"
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        defaultValue={data.first_name}
                                    />
                                    {valid.first_name_error ? (
                                        <p class="  alert-danger">Please Enter First Name </p>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="profile-last-name">Last Name</label>
                                    <input
                                        id="last_name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                        defaultValue={data.last_name}
                                    />
                                    {valid.last_name_error ? <p class="  alert-danger">Please Enter Last Name </p> : ""}
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="profile-email">Email Address</label>
                                    <input
                                        id="email"
                                        type="email"
                                        className="form-control"
                                        placeholder="Email Address"
                                        defaultValue={data.email}
                                    />
                                    {valid.email_error ? <p class="  alert-danger">Please Enter Valid Email</p> : ""}
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="profile-phone">Mobile / Phone</label>
                                    <input
                                        id="phone"
                                        type="text"
                                        className="form-control"
                                        placeholder="Mobile / Phone"
                                        defaultValue={data.phone}
                                    />
                                    {valid.phone_error ? <p class="  alert-danger">Please Enter Mobile</p> : ""}
                                    {valid.phone_error_1 ? (
                                        <p class="  alert-danger">Please Enter 10 Digit Mobile Number</p>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="profile-gender">Gender</label>
                                    <input
                                        id="gender"
                                        type="text"
                                        className="form-control"
                                        placeholder="Gender"
                                        defaultValue={data.gender}
                                    />
                                    {valid.gender_error ? <p class="  alert-danger">Please Enter Gender</p> : ""}
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="form-group mt-3 mb-0">
                                    <button
                                        type="button"
                                        onClick={submitUpdate}
                                        className={classNames("btn btn-primary", {
                                            "btn-loading": loading,
                                        })}
                                        className="btn btn-primary"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    profileEdit,
};
const mapStateToProps = (state) => {
    return {
        loading: state.auth.profile.loading,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountPageProfile);
