// react
import React, { useEffect} from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import StroykaSlick from '../shared/StroykaSlick';
import { connect } from 'react-redux';

import {getBrands} from '../../store/product/productActions'
// data stubs
import brands from '../../data/shopBrands';
import { clientLogoUrl } from '../../common';

const slickSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
    ],
};

export  function BlockBrands({getBrands,brands}) {

      useEffect(() => {
  
     
    }, []);

    console.log("data loaded here ",brands)
    const brandsList = brands.items.map(( brand  , index) => (
        <div key={index} className="block-brands__item">
            <Link to="/"><img src={ clientLogoUrl+ brand.swatch_value} alt="" /></Link>
        </div>
    ));


    console.log("brands data load ",brands)
    return (
        <div className="block block-brands">
            <div className="container">
                <div className="block-brands__slider">
                    <StroykaSlick {...slickSettings}>
                        {brandsList}
                    </StroykaSlick>
                </div>
            </div>
        </div>
    );
}


const mapDispatchToProps =  {
 getBrands
}

const mapStateToProps =(state) => {
    console.log("state banner",state);
    return {
    brands:state.brands,
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(BlockBrands);
