import { TOPDEALS_LIST, TOPDEALS_LIST_SUCCESS, TOPDEALS_LIST_FAILED } from "./topDealsActionTypes";

/*
 * item example:
 * {
 *   id: 1,
 *   product: {...}
 *   options: [
 *     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
 *   ],
 *   price: 250,
 *   quantity: 2,
 *   total: 500
 * }
 * extraLine example:
 * {
 *   type: 'shipping',
 *   title: 'Shipping',
 *   price: 25
 * }
 */
const initialState = {
    loading: 0,
    items: [],
};

export default function topDealsReducer(state = initialState, action) {
    switch (action.type) {
        case TOPDEALS_LIST:
            return { ...state, action };
        case TOPDEALS_LIST_SUCCESS:
            return { ...state, ...{ items: action.items } };
        case TOPDEALS_LIST_FAILED:
            return { ...state, ...{ items: action.items } };
        default:
            return state;
    }
}
