import { toast } from "react-toastify";
import {
    CATEGORY_LIST_SUCCESS,
    CATEGORY_LIST_FAILED,
    SUB_CATEGORY_LIST_SUCCESS,
    SUB_CATEGORY_LIST_FAILED,
    SUB_MENU_CATEGORY_LIST_SUCCESS,
    SUB_MENU_CATEGORY_LIST_FAILED,
    PARENTS_SUB_MENU_CATEGORY_LIST,
    PARENTS_SUB_MENU_CATEGORY_LIST_SUCCESS,
    PARENTS_SUB_MENU_CATEGORY_LIST_FAILED,
} from "./categoryActionTypes";
import { categoryPoint } from "./endpoint";

export function categorySuccess(categories) {
    console.log("categories ", categories);
    return {
        type: CATEGORY_LIST_SUCCESS,
        items: categories.data,
    };
}

export function categoryFailed(error) {
    return {
        type: CATEGORY_LIST_FAILED,
        items: [],
        error: error,
    };
}

export function getCategories() {
    console.log("action get category coming here");
    return (dispatch) =>
        fetch(categoryPoint.list + "?limit=15")
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                dispatch(categorySuccess(data));
            })
            .catch(function (thrown) {
                dispatch(categoryFailed(thrown));
            });
}

export function categorySubSuccess(categories) {
    console.log("categories ", categories);
    return {
        type: SUB_CATEGORY_LIST_SUCCESS,
        items: categories.data,
    };
}

export function categorySubFailed(error) {
    return {
        type: SUB_CATEGORY_LIST_FAILED,
        items: [],
        error: error,
    };
}

export function getSubCategories(slug) {
    console.log("action get category coming here");
    return (dispatch) =>
        fetch(categoryPoint.sublist + "?cat_slug=" + slug)
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                dispatch(categorySubSuccess(data));
            })
            .catch(function (thrown) {
                dispatch(categorySubFailed(thrown));
            });
}



export function categorySubMenuSuccess(categories) {
    console.log("categories ", categories);
    return {
        type: SUB_MENU_CATEGORY_LIST_SUCCESS,
        items: categories.data,
    };
}

export function categorySubMenuFailed(error) {
    return {
        type: SUB_MENU_CATEGORY_LIST_FAILED,
        items: [],
        error: error,
    };
}
export function getSubMenuCategories(slug) {
    console.log("action get category coming here");
    return (dispatch) =>
        fetch(categoryPoint.sublist + "?cat_slug=" + slug)
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                dispatch(categorySubMenuSuccess(data));
            })
            .catch(function (thrown) {
                dispatch(categorySubMenuFailed(thrown));
            });
}


export function categoryParentSubSuccess(categories) {
    console.log("categories ", categories);
    return {
        type: PARENTS_SUB_MENU_CATEGORY_LIST_SUCCESS,
        items: categories.catdata,
    };
}

export function categoryParentSubFailed(error) {
    return {
        type: PARENTS_SUB_MENU_CATEGORY_LIST_FAILED,
        items: [],
        error: error,
    };
}

export function getParentSubCategories(slug) {
    console.log("action get category coming here");
    return (dispatch) =>
        fetch(categoryPoint.menucats )
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                dispatch(categoryParentSubSuccess(data));
            })
            .catch(function (thrown) {
                dispatch(categoryParentSubFailed(thrown));
            });
}

export function setfilter(filterdata) {
    return (dispatch) => dispatch(filterdata);
}
export function resetfilter() {
    return (dispatch) => dispatch({ type: "RESET_FILTERS" });
}
