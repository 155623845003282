// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import InputNumber from "./InputNumber";
import ProductGallery from "./ProductGallery";
import Rating from "./Rating";
import { cartAddItem } from "../../store/cart";
import { compareAddItem } from "../../store/compare";
import { Wishlist16Svg, Compare16Svg } from "../../svg";
import { wishlistAddItem } from "../../store/wishlist";
import BlockLoader from "../blocks/BlockLoader";
import { getProductDetailAdditionals } from "../../store/product/productActions";

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 1,
            selectedVariation: 0,
            sku: "",
            variation_list: [],
            selectedvariant: 0,
            variationloading: false,
            variationProductType: "",
            variationProductList: "",
            selectedindex: {},
        };
        this.changevariation = this.changevariation.bind(this);
        this.checked = this.checked.bind(this);
    }

    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
    };
    /****** Check if id exists in sku */
    checked(event) {
        console.log("Data sku data ", event.target.getAttribute("data-sku"));
        if (event.target.getAttribute("data-sku").indexOf("-" + event.target.value) !== -1) {
            console.log("data sku  value", event.target.getAttribute("data-sku").includes("-" + event.target.value));
            return true;
        } else {
            console.log("data sku  value", event.target.getAttribute("data-sku").includes("-" + event.target.value));
            return false;
        }
    }

    changevariation = (event) => {
        console.log("event data ", event.target);
        let { product, getProductDetailAdditionals, productdetailvariation } = this.props;
        var that = this;
        this.setState({
            selectedindex: {
                ...this.state.selectedindex,
                ...{ [event.target.getAttribute("datacode")]: event.target.value },
            },
        });
        this.setState({
            variationProductType: event.target.getAttribute("data-type"),
            variationProductList: JSON.parse(event.target.getAttribute("data-product")),
        });
        let selectedproductvar = 0;
        let breakloop = false;
        this.setState({ variationloading: true });
        setTimeout(function () {
            console.log("selected indexs", that.state.selectedindex, productdetailvariation.items.index);

            for (const [mainkey, mainvalue] of Object.entries(productdetailvariation.items.index)) {
                for (const [key, value] of Object.entries(that.state.selectedindex)) {
                    console.log("coming here to see", value, mainvalue[key]);
                    if (value == mainvalue[key]) {
                        selectedproductvar = mainkey;
                    } else {
                        selectedproductvar = 0;
                        break;
                    }
                }
                if (selectedproductvar > 0) {
                    break;
                }
            }
            const index = product.variants.findIndex((x) => x.id == selectedproductvar);
            if (index < 0) {
                // alert('item is out of stock');
                that.setState({ variationloading: false });
                return false;
            }
            console.log("index data coming here bro", index, selectedproductvar, product.variants[index]);
            that.setState({ selectedVariation: index, selectedvariant: index, sku: product.variants[index].sku });
            getProductDetailAdditionals(selectedproductvar);
            console.log("selected variations", selectedproductvar);

            console.log("product data  with variation", product, that.state.sku);
            var url = window.location.href.split("?")[0];

            setTimeout(function () {
                that.setState({ variationloading: false });

                if (that.state.sku != "") {
                    window.history.replaceState({}, "", url + "?pro=" + product.variants[index].sku);
                }
            }, 0);
        }, 1);

        return false;

        let variation_list = this.state.variation_list;

        var exists = variation_list.findIndex((x) => x.name === event.target.name);
        if (exists < 0) {
            variation_list.push({
                [event.target.name]: event.target.value,
                name: event.target.name,
                attributeid: event.target.getAttribute("datacode"),
            });
        } else {
            variation_list[exists][event.target.name] = event.target.value;
        }
        console.log("variation selected", variation_list);
        if (product.sku.includes("-variant-")) {
            product.sku = product.sku.split("-variant-")[0];
            this.setState({ variationloading: true });
        }
        let variation_selected = product.sku + "-variant";
        variation_list.forEach((value, key) => {
            variation_selected = variation_selected + "-" + value[value.name];
        });

        let index = product.variants.findIndex((x) => x.sku === variation_selected);
        console.log("product variations", index, variation_selected, product);

        if (index == -1) {
            var splitdata = variation_selected.split("variant-")[1].split("-");
            var newdata = [];
            for (var start = splitdata.length - 1; start >= 0; start--) {
                newdata.push(splitdata[start]);
            }

            variation_selected = product.sku + "-variant";
            newdata.forEach((value, key) => {
                variation_selected = variation_selected + "-" + value;
            });
            index = product.variants.findIndex((x) => x.sku === variation_selected);
            console.log("new data variation data coming here", index);
        }

        if (index > -1) {
            this.setState({
                selectedVariation: event.target.value,
                sku: variation_selected,
                selectedvariant: product.variants[index].id,
            });
            getProductDetailAdditionals(product.variants[index].id);
        } else {
            console.log("selected attribute", variation_selected);
            let secondindex = 0;
            let sku = "";
            product.variants.forEach((element) => {
                console.log("Elements Coming here", element.sku);
                if (element.sku.includes(variation_selected.split("-")[2])) {
                    if (secondindex < 1) {
                        console.log("element sku matching selected data", element.sku.includes(variation_selected));
                        secondindex = element.id;
                        sku = element.sku;
                    }
                    return;
                }
            });
            // product.variants.findIndex(x => x.sku.includes(variation_selected));
            this.setState({ selectedVariation: secondindex, sku: sku, selectedvariant: secondindex });
            getProductDetailAdditionals(secondindex);
            console.log("second index", secondindex);
        }

        console.log("product data  with variation", product, this.state.sku);
        var url = window.location.href.split("?")[0];

        setTimeout(function () {
            that.setState({ variationloading: false });

            if (that.state.sku != "") {
                let indexid = product.variants.findIndex((x) => x.sku === that.state.sku);
                window.history.replaceState({}, "", url + "?pro=" + product.variants[indexid].url_key);
            }
        }, 1000);
    };
    /**** opene Review Tab *****/
    openReviewTab() {
        if (document.getElementsByClassName("product-tabs__item").length > 0) {
            document.getElementsByClassName("product-tabs__item")[1].click();
            var scrollDiv = document.getElementsByClassName("reviews-list")[0].offsetTop;
            window.scrollTo({ top: scrollDiv, behavior: "smooth" });
        }
    }
    /******** Get Url Key  ***********/
    geturlkey() {
        let url = new URL(window.location.href);
        const { product } = this.props;
        var pro = url.searchParams.get("pro");
        console.log("pro data coming here", pro);
        let index = 0;
        if (pro != null) {
            index = product.variants.findIndex((x) => x.sku === pro);
        }
        return index;
    }

    /***** On Component Did mount */
    componentDidMount() {}
    render() {
        const { layout, wishlistAddItem, compareAddItem, cartAddItem, productspec } = this.props;
        let { product } = this.props;
        const {
            quantity,
            selectedVariation,
            sku,
            variation_list,
            selectedvariant,
            shipping_rates,
            cart_items,
            variationloading,
        } = this.state;

        console.log("Product data coming here", product);
        let productdata = product;
        let variant = 0;
        let selectedindex = 0;
        let checkindexes = 0;
        let variations = {};
        if (selectedVariation > 0) {
            let index = productdata.variants.findIndex((x) => x.sku === sku);

            console.log(
                "Product data on variation",
                product.regular_price,
                product.variants[index],
                index,
                sku,
                variation_list
            );
            if (index > -1) {
                productdata.name = product.variants[index].name;
                productdata.id = product.variants[index].id;
                productdata.price = product.variants[index].price;
                productdata.special_price = product.variants[index].special_price;
                productdata.regular_price = product.variants[index].regular_price;
                productdata.sku = product.variants[index].sku;
                productdata.in_stock = product.variants[index].in_stock;
                productdata.images =
                    product.variants[index].images.length > 0 ? product.variants[index].images : product.images;
                productdata.badges = product.variants[index].id;
                productdata.variation_list = variation_list;
                variant = product.variants[index].id;
                selectedindex = index;
            }
            variation_list.forEach((value) => {
                variations[value.attributeid] = value[value.name];
            });

            checkindexes = this.state.selectedindex;
        } else if (product.variants != undefined && product.variants.length > 0 && selectedVariation == 0) {
            const urlindex = this.geturlkey();
            productdata.name = product.variants[urlindex].name;
            productdata.id = product.variants[urlindex].id;
            productdata.price = product.variants[urlindex].price;
            productdata.special_price = product.variants[urlindex].special_price;
            productdata.regular_price = product.variants[urlindex].regular_price;
            productdata.sku = product.variants[urlindex].sku;
            productdata.in_stock = product.variants[urlindex].in_stock;
            productdata.images =
                product.variants[urlindex].images.length > 0 ? product.variants[urlindex].images : product.images;
            productdata.badges = product.variants[urlindex].id;
            productdata.variation_list = variation_list;
            variant = product.variants[urlindex].id;
            variation_list.forEach((value) => {
                variations[value.attributeid] = value[value.name];
            });

            selectedindex = 0;
            if (this.props.productdetailvariation.items.index != undefined) {
                checkindexes = this.props.productdetailvariation.items.index[product.variants[urlindex].id];
            }
            console.log("coming in here to check check indexes", this.props.productdetailvariation.items);
        }

        console.log("Selected index data coming here", checkindexes);

        // if (this.props.productdetailvariation.loading) {
        //     return <p></p>;
        // }
        let prices;
        console.log("prices comes here", productdata.regular_price);

        if (productdata.special_price && productdata.regular_price) {
            prices = (
                <React.Fragment>
                    <span className="product__new-price" style={{ color: "#4a4a4a" }}>
                        <Currency value={productdata.price} />
                    </span>{" "}
                    <span
                        style={{
                            "font-weight": "400",
                            "font-size": "20px",
                            color: "#4a4a4a",
                            "font-weight": "bold",
                        }}
                    >
                        {/* MRP */}
                    </span>
                    <span className="product__old-price">
                        <Currency value={productdata.regular_price} />
                    </span>
                </React.Fragment>
            );
        } else {
            prices = <Currency value={productdata.price} />;
        }
        // console.log("Product spec",productspec);

        var imagesdata = [];
        var largeimagesdata = [];
        productdata.images.forEach((element) => {
            imagesdata.push(element.small_image_url);
            largeimagesdata.push(element.original_image_url);
        });

        console.log("Imagedata coming here", imagesdata);
        let brand = "";
        if (productspec !== undefined) {
            productspec.forEach((element) => {
                if (element.code == "brand") {
                    brand = element.value;
                }
            });
        }
        let that = this;

        console.log("Address data coming here", shipping_rates, cart_items);
        console.log(
            "Cart data ",
            this.state.productvariations,
            this.state.variationProductList,
            this.state.variationProductType
        );
        return (
            <div className={`product product--layout--${layout}`}>
                <ReactTooltip place="bottom" effect="solid" />
                <div className="product__content">
                    <div id="productZoomedImage__container" style={{ height: "0px", width: "0px" }}></div>
                    {!variationloading ? (
                        <ProductGallery
                            layout={layout}
                            largeimagesdata={largeimagesdata}
                            images={imagesdata}
                            magnify={true}
                        />
                    ) : (
                        <div className="product__gallery">
                            <div className="product-gallery">
                                <div className="product-gallery__featured">
                                    <BlockLoader />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="product__info">
                        <div className="product__wishlist-compare">
                            <AsyncAction
                                action={() => wishlistAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-placement="right"
                                        data-tip="Add to Wishlist"
                                        onClick={run}
                                        className={classNames("btn btn-sm btn-light btn-svg-icon", {
                                            "btn-loading": loading,
                                        })}
                                    >
                                        <Wishlist16Svg />
                                    </button>
                                )}
                            />
                            <AsyncAction
                                action={() => compareAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-placement="right"
                                        data-tip="Add to Compare"
                                        onClick={run}
                                        className={classNames("btn btn-sm btn-light btn-svg-icon", {
                                            "btn-loading": loading,
                                        })}
                                    >
                                        <Compare16Svg />
                                    </button>
                                )}
                            />
                        </div>
                        <h1 className="product__name">{productdata.name}</h1>
                        <div className="product__rating">
                            <div className="product__rating-stars">
                                {productdata.reviews != undefined ? (
                                    <Rating value={productdata.reviews.total_rating} />
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="product__rating-legend">
                                <Link to="#review" onClick={this.openReviewTab}>{`${
                                    productdata.reviews != undefined ? productdata.reviews.total : ""
                                } Reviews`}</Link>
                                <span>/</span>
                                <Link to="#review" onClick={this.openReviewTab}>
                                    Write A Review
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="product__sidebar">
                        <div className="product__availability">
                            Availability: <span className="text-success">{productdata.availability}</span>
                        </div>

                        <div className="product__prices">{prices}</div>

                        <form className="product__options">
                            {Array.isArray(this.props.productdetailvariation.items) ? (
                                <p></p>
                            ) : (
                                <div className="form-group product__option">
                                    {this.props.productdetailvariation.items.attributes.map(function (data, idx) {
                                        if (data.swatch_type == "color") {
                                            return (
                                                <div>
                                                    <div className="product__option-label">{data.label}</div>
                                                    <div className="input-radio-color">
                                                        <div className="input-radio-color__list">
                                                            {data.options.map(function (options, index) {
                                                                return (
                                                                    <span>
                                                                        <label
                                                                            className={classNames({
                                                                                "input-radio-color__item": true,
                                                                                "input-radio-color__item--disabled":
                                                                                    that.state.variationProductType !==
                                                                                        data.swatch_type &&
                                                                                    that.state.variationProductType !=
                                                                                        "" &&
                                                                                    !options.products.some((r) =>
                                                                                        that.state.variationProductList.includes(
                                                                                            r
                                                                                        )
                                                                                    ),
                                                                            })}
                                                                            style={{ color: options.swatch_value }}
                                                                            data-tip={options.label}
                                                                            data-attribute-id={data.id}
                                                                        >
                                                                            {" "}
                                                                            <input
                                                                                type="radio"
                                                                                disabled={
                                                                                    that.state.variationProductType !==
                                                                                        data.swatch_type &&
                                                                                    that.state.variationProductType !=
                                                                                        "" &&
                                                                                    !options.products.some((r) =>
                                                                                        that.state.variationProductList.includes(
                                                                                            r
                                                                                        )
                                                                                    )
                                                                                }
                                                                                checked={
                                                                                    checkindexes !== undefined &&
                                                                                    options.id == checkindexes[data.id]
                                                                                }
                                                                                data-sku={productdata.sku}
                                                                                value={options.id}
                                                                                datacode={data.id}
                                                                                data-type={data.swatch_type}
                                                                                data-product={JSON.stringify(
                                                                                    options.products
                                                                                )}
                                                                                name="color"
                                                                                onChange={that.changevariation}
                                                                            />{" "}
                                                                            <span />
                                                                        </label>
                                                                    </span>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>{" "}
                                                </div>
                                            );
                                        } else {
                                            const itemClasses = classNames("filter-list__item", {
                                                "filter-list__item--disabled": 1 === 0,
                                            });

                                            return (
                                                <div className="form-group product__option">
                                                    <div className="product__option-label">{data.label}</div>
                                                    <div className="input-radio-label">
                                                        <div className="input-radio-label__list">
                                                            {data.options.map(function (options, index) {
                                                                return (
                                                                    <label key={data.code} className={itemClasses}>
                                                                        <input
                                                                            type="radio"
                                                                            data-type={data.swatch_type}
                                                                            data-product={JSON.stringify(
                                                                                options.products
                                                                            )}
                                                                            data-sku={productdata.sku}
                                                                            disabled={
                                                                                that.state.variationProductType !==
                                                                                    data.swatch_type &&
                                                                                that.state.variationProductType != "" &&
                                                                                !options.products.some((r) =>
                                                                                    that.state.variationProductList.includes(
                                                                                        r
                                                                                    )
                                                                                )
                                                                            }
                                                                            name={data.code}
                                                                            datacode={data.id}
                                                                            value={options.id}
                                                                            onChange={that.changevariation}
                                                                            checked={
                                                                                checkindexes !== undefined &&
                                                                                options.id == checkindexes[data.id]
                                                                            }
                                                                        />
                                                                        <span>{options.label} </span>
                                                                    </label>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            )}

                            <div className="form-group product__option">
                                {productdata.in_stock ? (
                                    <label htmlFor="product-quantity" className="product__option-label">
                                        Quantity
                                    </label>
                                ) : (
                                    ""
                                )}
                                <div className="product__actions">
                                    {productdata.in_stock ? (
                                        <div className="product__actions-item">
                                            <InputNumber
                                                id="product-quantity"
                                                aria-label="Quantity"
                                                className="product__quantity"
                                                size="lg"
                                                min={1}
                                                value={quantity}
                                                onChange={this.handleChangeQuantity}
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {productdata.in_stock ? (
                                        <div className="product__actions-item product__actions-item--addtocart">
                                            <AsyncAction
                                                action={() =>
                                                    cartAddItem(
                                                        {
                                                            ...productdata,
                                                            ...{ selectedvariations: selectedvariant, variations },
                                                        },
                                                        [],
                                                        quantity
                                                    )
                                                }
                                                render={({ run, loading }) => (
                                                    <button
                                                        type="button"
                                                        onClick={run}
                                                        disabled={!quantity}
                                                        className={classNames("btn btn-primary btn-lg", {
                                                            "btn-loading": loading,
                                                        })}
                                                    >
                                                        Add to cart
                                                    </button>
                                                )}
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <div className="product__actions-item product__actions-item--wishlist">
                                        <AsyncAction
                                            action={() => wishlistAddItem(productdata)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    onClick={run}
                                                    data-tip="Add to Wishlist"
                                                    className={classNames("btn btn-secondary btn-svg-icon btn-lg", {
                                                        "btn-loading": loading,
                                                    })}
                                                >
                                                    <Wishlist16Svg />
                                                </button>
                                            )}
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--compare">
                                        <AsyncAction
                                            action={() => compareAddItem(productdata)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-tip="Add to Compare"
                                                    onClick={run}
                                                    className={classNames("btn btn-secondary btn-svg-icon btn-lg", {
                                                        "btn-loading": loading,
                                                    })}
                                                >
                                                    <Compare16Svg />
                                                </button>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="product__info">
                                <div
                                    className="product__description"
                                    dangerouslySetInnerHTML={{ __html: productdata.short_description }}
                                ></div>

                                <ul className="product__meta">
                                    <li className="product__meta-availability">
                                        Availability:{" "}
                                        {productdata.in_stock ? (
                                            <span className="text-success">{productdata.availability}</span>
                                        ) : (
                                            <span className="text-danger   ">{productdata.availability}</span>
                                        )}
                                    </li>
                                    <li>
                                        Brand:
                                        <Link to="/">{brand}</Link>
                                    </li>
                                    <li>SKU: {productdata.sku}</li>
                                </ul>
                            </div>
                        </form>
                    </div>

                    <div className="product__footer">
                        <div className="product__tags tags"></div>

                        {/* <div className="product__share-links share-links">
                            <ul className="share-links__list">
                                <li className="share-links__item share-links__item--type--like">
                                    <Link to="/">Like</Link>
                                </li>
                                <li className="share-links__item share-links__item--type--tweet">
                                    <Link to="/">Tweet</Link>
                                </li>
                                <li className="share-links__item share-links__item--type--pin">
                                    <Link to="/">Pin It</Link>
                                </li>
                                <li className="share-links__item share-links__item--type--counter">
                                    <Link to="/">4K</Link>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    productspec: PropTypes.any,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
    layout: "standard",
};

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    getProductDetailAdditionals,
};
const mapStateToProps = (state) => {
    return {
        productdetailvariation: state.productvariations,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
