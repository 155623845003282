export const CHECKOUT_ADD_ITEM = 'CHECKOUT_ADD_ITEM';
export const CHECKOUT_REMOVE_ITEM = 'CHECKOUT_REMOVE_ITEM';
export const CHECKOUT_UPDATE_QUANTITIES = 'CHECKOUT_UPDATE_QUANTITIES';
export const CHECKOUT_LIST_REQUEST = 'CHECKOUT_LIST_REQUEST';
export const CHECKOUT_LIST_SUCCESS = 'CHECKOUT_LIST_SUCCESS';
export const CHECKOUT_LIST_FAILED = 'CHECKOUT_LIST_FAILED';

/****** Save Address Actions *********/
export const CHECKOUT_SAVE_ADDRESS = 'CHECKOUT_SAVE_ADDRESS';
export const CHECKOUT_SAVE_ADDRESS_SUCCESS = 'CHECKOUT_SAVE_ADDRESS_SUCCESS';
export const CHECKOUT_SAVE_ADDRESS_FAILED = 'CHECKOUT_SAVE_ADDRESS_FAILED';

/****** Save shipping Method Actions *********/
export const CHECKOUT_SAVE_SHIPPING_METHOD = 'CHECKOUT_SAVE_SHIPPING_METHOD';
export const CHECKOUT_SAVE_SHIPPING_METHOD_SUCCESS = 'CHECKOUT_SAVE_SHIPPING_METHOD_SUCCESS';
export const CHECKOUT_SAVE_SHIPPING_METHOD_FAILED = 'CHECKOUT_SAVE_SHIPPING_METHOD_FAILED';

/****** Save Payment Method Actions *********/
export const CHECKOUT_SAVE_PAYMENT_METHOD = 'CHECKOUT_SAVE_PAYMENT_METHOD';
export const CHECKOUT_SAVE_PAYMENT_METHOD_SUCCESS = 'CHECKOUT_SAVE_PAYMENT_METHOD_SUCCESS';
export const CHECKOUT_SAVE_PAYMENT_METHOD_FAILED = 'CHECKOUT_SAVE_PAYMENT_METHOD_FAILED';

/****** Save Order Method Actions *********/
export const CHECKOUT_SAVE_ORDER_METHOD = 'CHECKOUT_SAVE_ORDER_METHOD';
export const CHECKOUT_SAVE_ORDER_METHOD_SUCCESS = 'CHECKOUT_SAVE_ORDER_METHOD_SUCCESS';
export const CHECKOUT_SAVE_ORDER_METHOD_FAILED = 'CHECKOUT_SAVE_ORDER_METHOD_FAILED';


/****** List all Payment Methods Actions *********/
export const PAYMENT_METHOD_LIST_GET = "PAYMENT_METHOD_LIST_GET";
export const PAYMENT_METHOD_LIST_GET_SUCCESS = "PAYMENT_METHOD_LIST_GET_SUCCESS";
export const PAYMENT_METHOD_LIST_GET_FAILED = "PAYMENT_METHOD_LIST_GET_FAILED";


/****** List all Shipping list Actions *********/
export const SHIPPING_LIST_GET = "SHIPPING_LIST_GET";
export const SHIPPING_LIST_GET_SUCCESS = "SHIPPING_LIST_GET_SUCCESS";
export const SHIPPING_LIST_GET_FAILED = "SHIPPING_LIST_GET_FAILED";