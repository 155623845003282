import {
    LOGIN,
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    REGISTER,
    REGISTER_SUCCESS,
    REGISTER_FAILED,
    PROFILE,
    PROFILE_FAILED,
    PROFILE_SUCCESS,
    RESET,
    RESET_SUCCESS,
    RESET_FAILED,
    STATELIST,
    STATELIST_SUCCESS,
    STATELIST_FAILED,
    OTP_LOGIN,
    OTP_LOGIN_SUCCESS,
    OTP_LOGIN_FAILED,
} from "./authActionTypes";

const initialState = {
    loading: 0,
    login: { loading: false, data: [] },
    register: { loading: false, data: [] },
    reset: { loading: false, data: [] },
    profile: { loading: false, data: [] },
    OTP: { loading: false, data: { status: false } },
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            return { ...state, ...{ login: { data: action.items, loading: true } } };
        case LOGIN_SUCCESS:
            return { ...state, ...{ login: { data: action.items, loading: false } } };
        case LOGIN_FAILED:
            return { ...state, ...{ login: { data: action.items, loading: false } } };
        case OTP_LOGIN:
            return { ...state, ...{ OTP: { data: action.items, loading: true } } };
        case OTP_LOGIN_SUCCESS:
            return { ...state, ...{ OTP: { data: action.items, loading: false } } };
        case OTP_LOGIN_FAILED:
            return { ...state, ...{ OTP: { data: { status: true }, loading: false } } };
        case REGISTER:
            return { ...state, ...{ register: { data: action.items, loading: true } } };
        case REGISTER_SUCCESS:
            return { ...state, ...{ register: { data: action.items, loading: false } } };
        case REGISTER_FAILED:
            return { ...state, ...{ register: { data: action.items, loading: false } } };
        case PROFILE:
            return { ...state, ...{ profile: { data: [], loading: true } } };
        case PROFILE_SUCCESS:
            return { ...state, ...{ profile: { data: action.items, loading: false } } };

        case PROFILE_FAILED:
            return { ...state, ...{ profile: { data: action.items, loading: false } } };
        case RESET:
            return { ...state, ...{ reset: { data: action.items, loading: true } } };
        case RESET_SUCCESS:
            return { ...state, ...{ reset: { data: action.items, loading: false } } };
        case RESET_FAILED:
            return { ...state, ...{ reset: { data: action.items, loading: false } } };
        default:
            return state;
    }
}

const initialStatesState = {
    loading: 0,
    items: [],
};

export function stateReducer(state = initialStatesState, action) {
    switch (action.type) {
        case STATELIST:
            return { ...state, ...{ items: action.items, loading: true } };
        case STATELIST_SUCCESS:
            return { ...state, ...{ items: action.items, loading: false } };
        case STATELIST_FAILED:
            return { ...state, ...{ items: action.items, loading: false } };

        default:
            return state;
    }
}
