// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import AppLink from '../shared/AppLink';
import languages from '../../i18n';
import Megamenu from './Megamenu';
import Menu from './Menu';
import DoubleMegamenu from './DoubleMegamenu';
import { ArrowRoundedDown9x6Svg } from '../../svg';

// data stubs
import navLinks from '../../data/headerNavigation';
import { Link } from 'react-router-dom';

function NavLinks(props) {

    console.log("props data coming here ", props.menusub);
    const handleMouseEnter = (event) => {
        const { locale } = props;
        const { direction } = languages[locale];

        const item = event.currentTarget;
        const megamenu = item.querySelector('.nav-links__megamenu');
        const doubleMegamenuLinks = item.querySelector('.doublemegamenu__body');
        const doubleMegamenu = item.querySelector('.nav-links__doublemegamenu');

        if (megamenu) {
            const container = megamenu.offsetParent;
            const containerWidth = container.getBoundingClientRect().width;
            const megamenuWidth = megamenu.getBoundingClientRect().width;
            const itemOffsetLeft = item.offsetLeft;

            if (direction === 'rtl') {
                // const itemPosition = containerWidth - (
                //     itemOffsetLeft + item.getBoundingClientRect().width
                // );

                // const megamenuPosition = Math.round(
                //     Math.min(itemPosition, containerWidth - megamenuWidth),
                // );

                // megamenu.style.left = '';
                // megamenu.style.right = `${megamenuPosition}px`;
            } else {
                // const megamenuPosition = Math.round(
                //     Math.min(itemOffsetLeft, containerWidth - megamenuWidth),
                // );

                // megamenu.style.right = '';
                // megamenu.style.left = `${megamenuPosition}px`;
            }
        } else if (doubleMegamenu && item.querySelector('.doublemegamenu__megamenu')) {
            const firstMegaMenu = item.querySelector('.doublemegamenu__megamenu');
            firstMegaMenu.classList.add("visible");
            const megamenuElement = firstMegaMenu.querySelector('.megamenu');
            const endHeight = megamenuElement.clientHeight;
            megamenuElement.style.height = '450px';
            doubleMegamenuLinks.style.height = `450px`;
        }
    };
    console.log("header menu data coming here", JSON.stringify(navLinks))

    console.log("Menu list coming here", props.menu);
    const linksList = props.menu.list.map((item, index) => {
        let arrow;
        let submenu;

        if (item.submenu) {
            arrow = <ArrowRoundedDown9x6Svg className="nav-links__arrow" />;
        }

        if (item.submenu && item.submenu.type === 'menu') {
            submenu = (
                <div className="nav-links__menu">
                    <Menu items={item.submenu.menu} />
                </div>
            );
        }

        if (item.submenu && item.submenu.type === 'megamenu') {
            submenu = (
                <div className={`nav-links__megamenu nav-links__megamenu--size--${item.submenu.menu.size}`}>
                    <Megamenu menu={item.submenu.menu} />
                </div>
            );
        }

        // if (item.submenu && item.submenu.type === 'doublemegamenu') {
        //     submenu = (
        //         <div className={`nav-links__doublemegamenu`}>
        //             {item.title == "Battery & Inverters" && props.menu.batteryitem != undefined ? (
        //                 <DoubleMegamenu menu={props.menu.batteryitem} />
        //             ) : (
        //                     <DoubleMegamenu menu={props.menu.elelist} />
        //                 )}
        //         </div>
        //     );
        // }

        const classes = classNames('nav-links__item', {
            'nav-links__item--with-submenu': item.submenu,
        });

        return (

            
            <li key={index} className={classes} onMouseEnter={handleMouseEnter}>
                <AppLink to={item.url} {...item.props}>
                    <span>
                        {item.title}
                       
                    </span>
                </AppLink>
                {submenu}
            </li>
        );
    });
  const classes = classNames("nav-links__item", {
      "nav-links__item--with-submenu": true,
  });
    return (
        <ul className="nav-links__list">
            <li key="100" className="nav-links__item">
                <AppLink to={"/"}>
                    <span>HOME</span>
                </AppLink>
            </li>
            <li key="100" className="nav-links__item">
                <AppLink to={"/page/about-us"}>
                    <span>ABOUT US</span>
                </AppLink>
            </li>
            <li key="101" className="nav-links__item nav-links__item--with-submenu" onMouseEnter={handleMouseEnter}>
                <AppLink to="#">
                    <span>BICYCLE TYRES</span>
                </AppLink>
                <div className="nav-links__menu">
                    {props.menusub.hasOwnProperty(10) ? <ul className="menu menu--layout--classic">
                        {props.menusub[10].data.map((item, index) => {
                            if (item.slug != "root") {
                                return (
                                    <li key={index}>
                                        <Link to={"/shop/catalog/" + item.slug}>{item.name}</Link>
                                    </li>
                                );
                            }
                        })}
                    </ul> :''}
                </div>
            </li>

            <li key="100" className="nav-links__item">
                <AppLink to="#">
                    <span>AUTOMOTIVE</span>
                </AppLink>
                <div className="nav-links__menu">
                    {props.menusub.hasOwnProperty(11) ? <ul className="menu menu--layout--classic">
                        {props.menusub[11].data.map((item, index) => {
                            if (item.slug != "root") {
                                return (
                                    <li key={index}>
                                        <Link to={"/shop/catalog/" + item.slug}>{item.name}</Link>
                                    </li>
                                );
                            }
                        })}
                    </ul> : ''}
                </div>
            </li>

            <li key="100" className="nav-links__item">
                <AppLink to={"/shop/catalog/tube"}>
                    <span>TUBES</span>
                </AppLink>
            </li>

            <li key="100" className="nav-links__item">
                <AppLink to={"/page/innovation"}>
                    <span>INNOVATION</span>
                </AppLink>
            </li>
            {/* <li key="100" className="nav-links__item">
                <AppLink to={"/page/ralson-network"}>
                    <span>NETWORK</span>
                </AppLink>
            </li> */}
            {/* <li key="100" className="nav-links__item">
                <AppLink to={"/page/tyre-care"}>
                    <span>TYRE CARE</span>
                </AppLink>
            </li> */}
            {/* <li key="100" className="nav-links__item">
                <AppLink to={"/page/ralson-sports"}>
                    <span>RALSON SPORTS</span>
                </AppLink>
            </li> */}
            <li key="100" className="nav-links__item">
                <AppLink to={"/contact-us"}>
                    <span>CONTACT US</span>
                </AppLink>
            </li>
        </ul>
    );
}

NavLinks.propTypes = {
    /** current locale */
    locale: PropTypes.string,
    
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    menu: state.menu,
    categorylist: state.submenucat,
    menusub: state.menusubcat.items,
});

export default connect(mapStateToProps)(NavLinks);